import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BasePagingRequest } from 'src/app/core/models/requests/base-paging-request';
import { BasePagingResponse } from 'src/app/core/models/responses/base-paging-response';
import { ManufactureOrderState } from 'src/app/models/enums/manufacture-order-state';
import { ManufactureOrderFilter } from 'src/app/models/filters/manufacture-order-filter';
import { ManufactureOrder } from 'src/app/models/order/manufacture-order';
import { AlertService } from 'src/app/services/alert.service';
import { ManufactureOrderService } from 'src/app/services/manufacture-order.service';
import { BaseListComponent } from 'src/app/shared/components/base-list/base-list.component';

@Component({
  selector: 'app-manufacture-orders',
  templateUrl: './manufacture-orders.component.html',
  styleUrls: ['./manufacture-orders.component.scss']
})
export class ManufactureOrdersComponent extends BaseListComponent implements OnInit {
  @Input() filter: ManufactureOrderFilter 
  model: BasePagingResponse<ManufactureOrder>;
  ManufactureOrderState = ManufactureOrderState;
  request: BasePagingRequest<ManufactureOrderFilter> = {
    pageIndex: this.pageIndex,
    itemPerPage: this.itemPerPage,
    filter: {}
  };
  constructor(
    private service: ManufactureOrderService,
    private alertService: AlertService,
    private modalService: NgbModal) {
    super();
   }
  // ngOnChanges(changes: SimpleChanges): void {
  //   throw new Error('Method not implemented.');
  // }

  ngOnInit(): void {
    this.reloadData();
    this.service.refreshIndex.subscribe(res => this.reloadData());
  }

  reloadData() {
    this.request.filter = JSON.parse(JSON.stringify(this.filter));
    localStorage.setItem("itemPerPage", this.request.itemPerPage.toString());
    this.service.getAll(this.request).toPromise().then((res) => {
      this.Loading = false;
      this.model = JSON.parse(JSON.stringify(res));
    });
  }

  delete(id: number) {
    this.alertService.showDeleteAlert().then(result => {
      if(result.isConfirmed){
        this.service.delete(id).toPromise().then((res) => {
          if(res.isSuccess){
            this.alertService.showSuccess('Sipariş silindi.');
            this.reloadData();
          }
        });
      }
    })
  }

}