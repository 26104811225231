import { Pipe, PipeTransform } from "@angular/core";
import { CurrencySubTypes } from "src/app/models/enums/currency-sub-types";

@Pipe({
  name: "keyToSubType",
})
export class KeyToSubTypePipe implements PipeTransform {
  transform(value: string): CurrencySubTypes {
    switch (value) {
      case "Has":
        return CurrencySubTypes.Has;
      case "Carat22":
        return CurrencySubTypes.Carat22;
      case "Carat21":
        return CurrencySubTypes.Carat21;
      case "Carat20":
        return CurrencySubTypes.Carat20;
      case "Carat18":
        return CurrencySubTypes.Carat18;
      case "Carat14":
        return CurrencySubTypes.Carat14;
      case "Carat8":
        return CurrencySubTypes.Carat8;
      case "IngotYarim":
        return CurrencySubTypes.IngotYarim;
      case "Ingot1":
        return CurrencySubTypes.Ingot1;
      case "IngotIkiBucuk":
        return CurrencySubTypes.IngotIkiBucuk;
      case "Ingot5":
        return CurrencySubTypes.Ingot5;
      case "Ingot10":
        return CurrencySubTypes.Ingot10;
      case "Ingot20":
        return CurrencySubTypes.Ingot20;
      case "Ingot50":
        return CurrencySubTypes.Ingot50;
      case "Ingot100":
        return CurrencySubTypes.Ingot100;
      case "Ingot250":
        return CurrencySubTypes.Ingot250;
      case "Ingot500":
        return CurrencySubTypes.Ingot500;
      case "Ingot1000":
        return CurrencySubTypes.Ingot1000;
      case "Gram24":
        return CurrencySubTypes.Gram24;
      case "Gram22":
        return CurrencySubTypes.Gram22;
      case "YeniCeyrek":
        return CurrencySubTypes.YeniCeyrek;
      case "YeniYarim":
        return CurrencySubTypes.YeniYarim;
      case "YeniTam":
        return CurrencySubTypes.YeniTam;
      case "YeniAta":
        return CurrencySubTypes.YeniAta;
      case "YeniGremse":
        return CurrencySubTypes.YeniGremse;
      case "EskiGremse":
        return CurrencySubTypes.EskiGremse;
      case "YeniAta5":
        return CurrencySubTypes.YeniAta5;
      case "EskiAta5":
        return CurrencySubTypes.EskiAta5;
      case "Aziz":
        return CurrencySubTypes.Aziz;
      case "HamitGremse":
        return CurrencySubTypes.HamitGremse;
      case "Hamit5":
        return CurrencySubTypes.Hamit5;
      case "Adli":
        return CurrencySubTypes.Adli;
      case "Hamit":
        return CurrencySubTypes.Hamit;
      case "EskiPara":
        return CurrencySubTypes.EskiPara;
      case "Bilezik14":
        return CurrencySubTypes.Bilezik14;
      case "Bilezik18":
        return CurrencySubTypes.Bilezik18;
      case "Bilezik22":
        return CurrencySubTypes.Bilezik22;
      case "Armali5li":
        return CurrencySubTypes.Armali5li;
      case "Resat":
        return CurrencySubTypes.Resat;
      case "Resat5":
        return CurrencySubTypes.Resat5;
      case "EskiCeyrek":
        return CurrencySubTypes.EskiCeyrek;
      case "EskiYarim":
        return CurrencySubTypes.EskiYarim;
      case "EskiAta":
        return CurrencySubTypes.EskiAta;
      case "EskiTam":
        return CurrencySubTypes.EskiTam;
      case "AtaGremse":
        return CurrencySubTypes.AtaGremse;
      case "AtaYarim":
        return CurrencySubTypes.AtaYarim;
      case "AtaCeyrek":
        return CurrencySubTypes.AtaCeyrek;
      case "ResatGremse":
        return CurrencySubTypes.ResatGremse;
      case "ResatYarim":
        return CurrencySubTypes.ResatYarim;
      case "ResatCeyrek":
        return CurrencySubTypes.ResatCeyrek;
      case "Ziynet5":
        return CurrencySubTypes.Ziynet5;
      case "Granular":
        return CurrencySubTypes.Granular;
      case "Ingot":
        return CurrencySubTypes.Ingot;
      case "JPY":
        return CurrencySubTypes.JPY;
      case "GBP":
        return CurrencySubTypes.GBP;
      case "DKK":
        return CurrencySubTypes.DKK;
      case "SEK":
        return CurrencySubTypes.SEK;
      case "NOK":
        return CurrencySubTypes.NOK;
      case "CHF":
        return CurrencySubTypes.CHF;
      case "AUD":
        return CurrencySubTypes.AUD;
      case "CAD":
        return CurrencySubTypes.CAD;
      case "SAR":
        return CurrencySubTypes.SAR;
      case "RUB":
        return CurrencySubTypes.RUB;
      case "BGN":
        return CurrencySubTypes.BGN;
      case "AED":
        return CurrencySubTypes.AED;
      case "QAR":
        return CurrencySubTypes.QAR;
      case "CNY":
        return CurrencySubTypes.CNY;
      case "AZN":
        return CurrencySubTypes.AZN;
      case "KWD":
        return CurrencySubTypes.KWD;
      case "ILS":
        return CurrencySubTypes.ILS;
      case "MAD":
        return CurrencySubTypes.MAD;
      case "JOD":
        return CurrencySubTypes.JOD;
      case "OMR":
        return CurrencySubTypes.OMR;
      case "XPT":
        return CurrencySubTypes.XPT;
      case "XPD":
        return CurrencySubTypes.XPD;
      case "Platin":
        return CurrencySubTypes.Platin;
      case "Palladium":
        return CurrencySubTypes.Palladium;
      case "GEL":
        return CurrencySubTypes.GEL;
    }
  }
}
