<app-page-title title="Alım/Satım Detay" [breadcrumbItems]="breadCrumbItems"></app-page-title>

<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body" *ngIf="model">
        <div class="invoice-title">
          <h4 class="float-end" innerHtml="{{ model.data | exchangeItemState  }}"> </h4>
          <div class="mb-4">
            <img src="assets/images/ars-logo.png" alt="logo" height="25" />
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-6">
            <address>
              <strong> Arş Kuyumculuk </strong><br>
              Şube: {{currentBranch.name}} <br>
              İşlemi Yapan: {{model.data.createrName}} <br>
              <ng-container *ngIf="model.data.agreedDate && model.data.agreedDate != model.data.paymentDate">
                Anlaşma Tarihi:  {{model.data.agreedDate | date:'dd.MM.y HH:m' }} <br> </ng-container>
              <ng-container *ngIf="model.data.paymentDate">
                İşlem Tarihi:  {{model.data.paymentDate | date:'dd.MM.y HH:m' }} <br> </ng-container>
            </address>
          </div>
          <div class="col-6 text-sm-end">
            <address>
              <strong>Müşteri</strong><br>
              {{model.data.customerName | titlecase}} <br>
              {{model.data.exchangeCurrency | customCurrency }} Hesabı <br>
              <span class="fs-5">{{model.data.description}}</span>
            </address>
          </div>
        </div>

        <div class="py-2 mt-3">
          <h3 class="font-size-15 fw-bold">Alım/Satım Özeti</h3>
        </div>
        <div class="table-responsive">
          <table class="table table-borderless">
            <thead>
              <tr>
                <th class="border-bottom border-danger">Satış</th>
                <th class="border-bottom border-danger">Satış Birim</th>
                <th *ngIf="model.data.exchangeMilyem != null" class="border-bottom border-danger" style="width: 120px;">Satış Milyem</th>
                <th class="border-bottom border-danger">Satış Kasa</th>

                <th class="text-center border-bottom border-secondary " >Kur</th>

                <th class="border-bottom border-success">Alış</th>
                <th class="border-bottom border-success">Alış Birim</th>
                <th *ngIf="model.data.paymentMilyem != null" class="border-bottom border-success" style="width: 120px;" >Alış Milyem</th>
                <th class="border-bottom border-success">Alış Kasa</th>

              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-end table-danger"> {{model.data.exchangeAmount | currencyAmount: model.data.exchangeCurrency }} <span *ngIf="model.data.exchangeCurrency == currencyEnum.Gold"> {{model.data.exchangeCurrency | currencyWeight }} </span>  </td>
                <td class="text-start table-danger" *ngIf="model.data.exchangeCurrency == currencyEnum.Gold"> {{model.data.exchangeSubType | subTypeDisplay}} ({{model.data.exchangeCurrency | customCurrency}}) </td>
                <td class="text-start table-danger" *ngIf="model.data.exchangeCurrency == currencyEnum.Other"> {{model.data.exchangeSubType | subTypeDisplay}}</td>
                <td class="text-start table-danger" *ngIf="model.data.exchangeCurrency == currencyEnum.Accessories || model.data.exchangeCurrency == currencyEnum.OtherAccessories || model.data.exchangeCurrency == currencyEnum.Silver"> {{model.data.exchangeCurrency | customCurrency}} ({{model.data.exchangeSubType | subTypeDisplay}})</td>
                <td class="text-start table-danger" *ngIf="model.data.exchangeCurrency == currencyEnum.Tl || model.data.exchangeCurrency == currencyEnum.Usd || model.data.exchangeCurrency == currencyEnum.Euro">{{model.data.exchangeCurrency | customCurrency}}</td>
                <td class="text-center table-danger" *ngIf="model.data.exchangeMilyem != null">  {{model.data.exchangeMilyem}} </td>
                <td class="table-danger"> {{model.data.exchangeCaseName}} </td>

                <td class="text-center table-secondary">{{ model.data.parity | currencyAmount: null: null: true}}</td>
                <td class="text-end table-success"> {{ model.data.paymentAmount | currencyAmount: model.data.paymentCurrency}} {{model.data.paymentCurrency | currencyWeight }} </td>
                <td class="text-start table-success" *ngIf="model.data.paymentCurrency == currencyEnum.Gold"> {{model.data.paymentSubType | subTypeDisplay}} ({{model.data.paymentCurrency | customCurrency}}) </td>
                <td class="text-start table-success" *ngIf="model.data.paymentCurrency == currencyEnum.Other"> {{model.data.paymentSubType | subTypeDisplay}}</td>
                <td class="text-start table-success" *ngIf="model.data.paymentCurrency == currencyEnum.Accessories || model.data.paymentCurrency == currencyEnum.OtherAccessories || model.data.paymentCurrency == currencyEnum.Silver"> {{model.data.paymentCurrency | customCurrency}} ({{model.data.paymentSubType | subTypeDisplay}})</td>
                <td class="text-start table-success" *ngIf="model.data.paymentCurrency == currencyEnum.Tl || model.data.paymentCurrency == currencyEnum.Usd || model.data.paymentCurrency == currencyEnum.Euro">{{model.data.paymentCurrency | customCurrency}}</td>
                <td class="text-center table-success" *ngIf="model.data.paymentMilyem != null" > {{model.data.paymentMilyem}} </td>
                <td class="table-success"> {{model.data.paymentCaseName}} </td>

              </tr>
            </tbody>
          </table>
        </div>
        <div class="d-print-none">
          <div class="btn-group float-end">
            <a class="btn  btn-light" routerLink="/receipts/exchange-summary/{{groupId}}" ><i class="fa fa-print me-1"></i> Fiş</a>
            <a class="btn  btn-light ms-1" (click)="delete(model.data.id)" *hasRole="[Role.Admin, Role.SuperAdmin]"><i class="fa fa-trash me-1"> </i> Sil </a>
            <!-- <a class="btn  btn-info ms-1" (click)="doneWithDeposit(model.data)" *ngIf="model.data.state == exchangeStateEnum.Agreed"> <i class="fa fa-user-clock"></i> Alacaklı </a> -->
            <!-- <a class="btn  btn-success ms-1" (click)="donePayment(model.data)" *ngIf="model.data.state == exchangeStateEnum.Agreed"> <i class="fa fa-check-double"></i>Ödendi</a> -->
            <!-- <a class="btn btn-primary btn-label w-md ms-1" routerLink="/exchange/list"> <i class="fa fa-arrow-left label-icon"></i> Geri Dön</a> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
