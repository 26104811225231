import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadComponent } from './upload.component';
import { NgbToastModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ImageCropperModule } from 'ngx-image-cropper';
import { WebcamModule } from 'ngx-webcam';

@NgModule({
  declarations: [
    UploadComponent
  ],
  imports: [
    CommonModule,
    NgbTooltipModule,
    ImageCropperModule,
    WebcamModule
  ],
  exports: [UploadComponent]
})
export class UploadModule { }
