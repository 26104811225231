import { AfterContentInit, AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { EChartsOption } from 'echarts';
import { Role } from 'src/app/core/models/role.enum';
import { Branch } from 'src/app/models/branch/branch';
import { AccountTransactionService } from 'src/app/services/account-transaction.service';
import { ChartService } from 'src/app/services/custom-chart.service';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { CurrencyAmountPipe } from 'src/app/shared/pipes/currency-amount.pipe';
import { CustomCurrencyPipe } from 'src/app/shared/pipes/custom-currency.pipe';

@Component({
  selector: 'app-negative-bar-chart',
  templateUrl: './negative-bar-chart.component.html',
  styleUrls: ['./negative-bar-chart.component.scss'],
  providers: [CurrencyAmountPipe]
})

export class NegativeBarChartComponent extends BaseComponent {
 breadCrumbItems = [{ label: 'Charts' }, { label: 'E - Chart', active: true }];
 currentBranch: Branch = JSON.parse(localStorage.getItem('currentBranch'));
 showChart = false;
 negativeBarChart: EChartsOption = {};
  constructor(private service: ChartService, private accountTransactionService: AccountTransactionService, private customCurrencyPipe: CustomCurrencyPipe, private currencyAmountPipe: CurrencyAmountPipe) {
    super();
    this.negativeBarChart = {
      xAxis: {
        type: 'value',
        axisLine: {onZero: false},
        boundaryGap: [0, 0.01]
      },
      yAxis: {
        type: 'category',
        data: ['TL', 'Euro', 'Dolar', 'Altın', 'Sarrafiye']
      },
      color: ["#5470C6"],
      series: [
        {
          name: 'Positive',
          type: 'bar',
          stack: 'total',
          label: {
            show: true,
            position: 'insideRight'
          },
          data: [320, null, 301, 334, null]
        },
        {
          name: 'Negative',
          type: 'bar',
          stack: 'total',
          label: {
            show: true,
            position: 'insideLeft'
          },
          data: [null, -132, -101, null, -190]
        }
      ],
    };
  }

  ngOnInit(): void {
    this._fetchData();
    this.accountTransactionService.refreshIndex.subscribe(p => this._fetchData())
  }

  _fetchData() {
    this.negativeBarChart.series[0].data = [];
    this.negativeBarChart.series[1].data = [];
    this.negativeBarChart.yAxis = {};
    this.negativeBarChart = JSON.parse(JSON.stringify(this.negativeBarChart));
    let data = [];
    this.service.branchFinalBalanceOfHas(this.currentBranch.id).toPromise().then(res => {
      res.data.items.map(p => {
        if (p.amount > 0) {
          data.push(this.customCurrencyPipe.transform(p.currency));
          this.negativeBarChart.series[0].data.push(p.amount);
          this.negativeBarChart.series[1].data.push(null);
        }
        else if (p.amount < 0) {
          data.push(this.customCurrencyPipe.transform(p.currency));
          this.negativeBarChart.series[0].data.push(null);
          this.negativeBarChart.series[1].data.push(p.amount);
        }
        // else if (p.amount == 0){
        //   data.push(this.customCurrencyPipe.transform(p.currency));
        //   this.negativeBarChart.series[0].data.push(null);
        //   this.negativeBarChart.series[1].data.push(null);
        // }
      })
      this.negativeBarChart.yAxis = {type: 'category', data: data};
      this.showChart = true;
    })
  }
}
