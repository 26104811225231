<ng-container *ngIf="(summary$ | async) as model">
  <h5 class="header text-center"> Alım Satım Özeti </h5>
  <h6 class="header text-center">{{cart[0].customerName}}</h6>
  <div class="d-flex justify-content-between header-info">
    <span *ngIf="cart && cart.length > 0"><strong>İşlem: </strong>{{ cart[0].state | exchangeState }}</span>
    <span><strong>Tarih: </strong>{{ operationDate | date:'dd/MM/yyyy HH:mm:ss'}}</span>
  </div>
  <table class="table mb-0 mt-3">
    <thead>
      <tr>
        <th>Satış</th>
        <th>Kur</th>
        <th>Alış</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of cart">
        <td><span class="amount"> {{item.exchangeAmount | currencyAmount: item.exchangeCurrency }}
            {{item.exchangeCurrency | currencyWeight: item.exchangeSubType}}</span> {{ item.exchangeSubType |
          subTypeDisplay }} <span *ngIf="item.exchangeMilyem">({{item.exchangeMilyem}})</span></td>
        <td>{{ item.parity | currencyAmount: null: null: true }}</td>
        <td><span class="amount"> {{item.paymentAmount | currencyAmount: item.paymentCurrency }}
            {{item.paymentCurrency | currencyWeight: item.paymentSubType}}</span> {{ item.paymentSubType |
          subTypeDisplay }} <span *ngIf="item.paymentMilyem != null">({{item.paymentMilyem}})</span></td>

      </tr>
    </tbody>
  </table>

  <div class="row mt-1" *ngIf="cart && cart.length > 1">
    <div class="exchange-balances col">
      <div class="balance-header"> Satış Toplam </div>
      <div class="balance-values" *ngFor="let item of balances.exchanges">
        {{item.balance | currencyAmount: item.currency}} <span> {{item.currency | customCurrency}} <span
            *ngIf="item.subType != undefined"> ({{item.subType | subTypeDisplay}}) </span> <span
            *ngIf="item.milyem != null">({{item.milyem}})</span> </span>
      </div>
    </div>

    <div class="exchange-balances col">
      <div class="balance-header"> Alış Toplam </div>
      <div class="balance-values" *ngFor="let item of balances.payments">
        {{item.balance | currencyAmount: item.currency}} <span> {{item.currency | customCurrency}} <span
            *ngIf="item.subType != undefined"> ({{item.subType | subTypeDisplay}}) </span> <span
            *ngIf="item.milyem != null">({{item.milyem}})</span> </span>
      </div>
    </div>
  </div>

  <ng-container *ngIf="summaryItems?.length > 0">
    <div class="customer-balance-summary mt-5 mb-0"> Bakiye Özeti </div>
    <table class="table mb-0 mt-3">
      <thead>
        <tr>
          <th>Birim</th>
          <th>Önceki Bakiye</th>
          <th>Son Bakiye</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of summaryItems">
          <ng-container *ngIf="item.beforeBalance != 0 || item.afterBalance !=0">
            <td class="text-start">
              <span *ngIf="!item.subType && item.currency != Currency.Gold">{{item.currency | customCurrency}}</span>
              <span  *ngIf="item.subType != undefined && item.currency != Currency.Gold">{{item.subType | subTypeDisplay }}</span>
              <span *ngIf="item.currency == Currency.Gold">HAS</span>
            </td>
            <td> {{item.beforeBalance | currencyAmount: item.currency }} </td>
            <td> {{item.afterBalance | currencyAmount: item.currency }} </td>
          </ng-container>
        </tr>
      </tbody>
    </table>
  </ng-container>
  <p class="sarraf">sarraf.io</p>
  <div class="groupId text-center">Bilgilendirme amaçlıdır.<br> Resmi belge olarak kullanılamaz. Mali değeri yoktur.
  </div>
  <div class="row mt-4"></div>
</ng-container>
