import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LaserPrintComponent } from './laser-print.component';
import { Router, RouterModule } from '@angular/router';



@NgModule({
  declarations: [LaserPrintComponent],
  imports: [
    CommonModule,
    RouterModule
  ]
})
export class LaserPrintModule { }
