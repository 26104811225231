import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CollectionsModule } from './collections/collections.module';
import { BalancesModule } from './balances/balances.module';
import { AccountTransactionsModule } from './account-transactions/account-transactions.module';
import { CasesModule } from './cases/cases.module';
import { BranchesModule } from './branches/branches.module';
import { CustomerAccountsModule } from './customer-accounts/customer-accounts.module';
import { CustomersModule } from './customers/customers.module';
import { FormsModule } from '@angular/forms';
import { UploadsModule } from './uploads/uploads.module';
import { ProductGroupModule } from './product-group/product-group.module';
import { DictionariesModule } from './dictionaries/dictionaries.module';
import { ApplicationUsersModule } from './application-users/application-users.module';
import { WorkshopsModule } from './workshops/workshops.module';
import { PreProductTransactionsModule } from './pre-product-transactions/pre-product-transactions.module';
import { AccessRightsModule } from './access-rights/access-rights.module';
import { ParitiesModule } from './parities/parities.module';
import { StatesModule } from './states/states.module';
import { ProductsModule } from './products/products.module';
import { RawMaterialsModule } from './raw-materials/raw-materials.module';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    CollectionsModule,
    CustomersModule,
    BalancesModule,
    AccountTransactionsModule,
    CasesModule,
    BranchesModule,
    CustomerAccountsModule,
    UploadsModule,
    DictionariesModule,
    ProductGroupModule,
    FormsModule,
    ApplicationUsersModule,
    WorkshopsModule,
    PreProductTransactionsModule,
    AccessRightsModule,
    ParitiesModule,
    StatesModule,
    ProductsModule,
    RawMaterialsModule
  ]
})
export class ComponentsModule { }
