import { NgModule } from '@angular/core';
import { CommonModule} from '@angular/common';
import { ExchangeSummaryComponent } from './exchange-summary/exchange-summary.component';
import { ReceiptsRoutingModule } from './receipts-routing-module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { RouterModule } from '@angular/router';
import { CustomerBalanceComponent } from './customer-balance/customer-balance.component';
import { CustomerTransactionsSummaryComponent } from './customer-transactions-summary/customer-transactions-summary.component';
import { VirmanSummaryComponent } from './virman-summary/virman-summary.component';

@NgModule({
  declarations: [ExchangeSummaryComponent, CustomerBalanceComponent, CustomerTransactionsSummaryComponent, VirmanSummaryComponent],
  imports: [
    CommonModule,
    ReceiptsRoutingModule,
    RouterModule,
    PipesModule
  ],
  exports: [],
  providers: []
})
export class ReceiptsModule { }
