import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'list-spinner',
  templateUrl: './list-spinner.component.html',
  styleUrls: ['./list-spinner.component.scss']
})
export class ListSpinnerComponent implements OnInit {
  @Input() showSpinner: boolean;
  @Input() length: number
  @Input() showNotFound?: boolean = true;
  
  constructor() { }

  ngOnInit(): void {
  }

}