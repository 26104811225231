import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseResponse } from 'src/app/core/models/responses/base-response';
import { environment } from 'src/environments/environment';
import { ApplicationUser } from '../../models/application-user/application-user';
import { ApplicationUserFilter } from '../../models/filters/application-user-filter';
import { BaseService } from './base.service';
import { Router } from '@angular/router';
import { Branch } from 'src/app/models/branch/branch';
import { LatestUsageModel } from 'src/app/models/application-user/latest-usage-model';
import { CustomJwtTokenResponse } from '../models/responses/custom-jwt-token-response';
import { WidgetService } from 'src/app/services/widget.service';
@Injectable({
  providedIn: 'root'
})
export class ApplicationUserService extends BaseService<ApplicationUser, ApplicationUserFilter, string> {

  apiUrl = environment.urlAddress;
  public currentUserSubject: BehaviorSubject<ApplicationUser>;
  public latestUsageSubject: BehaviorSubject<LatestUsageModel>;
  public currentBranchSubject: BehaviorSubject<Branch>;
  public currentUser: Observable<ApplicationUser>;
  public currentBranch: Observable<Branch>;


  constructor(private http: HttpClient, private router: Router, private widgetService: WidgetService) {
    super(http, environment.urlAddress + '/applicationUser');
    this.currentUserSubject = new BehaviorSubject<ApplicationUser>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentBranchSubject = new BehaviorSubject<Branch>(JSON.parse(localStorage.getItem('currentBranch')));
    this.currentUser = this.currentUserSubject.asObservable();
    this.currentBranch = this.currentBranchSubject.asObservable();
  }


  login(username: string, password: string): Observable<BaseResponse<ApplicationUser>>{

    return this.http.post<BaseResponse<ApplicationUser>>(this.apiUrl + '/applicationUser/login', {username, password})
      .pipe(map(response => {
          if(response && response.data && response.data.token && response.data.branchList.length > 0){
            let parities = localStorage.getItem('parities');
            if (parities == undefined)
              localStorage.setItem('parities', JSON.stringify([]));
            localStorage.setItem('subSystemName', response.data.subSystemName);
            localStorage.setItem('currentUser', JSON.stringify(response.data));
            this.widgetService.widgetsSubject.next(response.data.widgetList);
            this.currentUserSubject.next(response.data);
            if(response.data.branchList.length > 0){
              localStorage.setItem('currentBranch', JSON.stringify(response.data.branchList[0]));
              this.currentBranchSubject.next(response.data.branchList[0]);
            }
          }
        return response;
      }))
  }

  logout() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('currentBranch');
    this.currentUserSubject.next(null);
  }

  public get currentUserValue(): ApplicationUser {
    return this.currentUserSubject.value;
}

public get currentBranchValue(): Branch {
  return this.currentBranchSubject.value;
}


  async addBranch(branch: Branch){
    // var currentUser: ApplicationUser = JSON.parse(localStorage.getItem("currentUser"));
    // currentUser.branchList.push(branch);
    // this.currentUserSubject.next(currentUser);
    // localStorage.setItem("currentUser", JSON.stringify(currentUser));
  }

  async deleteBranch(id: number){
    var currentUser: ApplicationUser = JSON.parse(localStorage.getItem("currentUser"));
    currentUser.branchList = currentUser.branchList.filter(p=> p.id !== id);
    this.currentUserSubject.next(currentUser);
    localStorage.setItem("currentUser", JSON.stringify(currentUser));
  }

  async branchListChanged(newList: Branch[]){
    var currentUser: ApplicationUser = JSON.parse(localStorage.getItem("currentUser"));
    currentUser.branchList = newList;
    this.currentUserSubject.next(currentUser);
    localStorage.setItem("currentUser", JSON.stringify(currentUser));
  }

  changeBranch(branch: Branch){
    this.currentBranchSubject.next(branch);
    localStorage.setItem("currentBranch", JSON.stringify(branch))
  }

  update(user: ApplicationUser): Observable<BaseResponse<ApplicationUser>>{
    return this.http.put<BaseResponse<ApplicationUser>>(this.apiUrl + '/applicationUser', user)
  }

  updateLatestUsage(values: any) {
    this.latestUsageSubject.next(values);
  }

  refreshToken(token: string): Observable<BaseResponse<CustomJwtTokenResponse>>{
    return this.http.post<BaseResponse<CustomJwtTokenResponse>>(this.apiUrl + '/applicationUser/refresh-token', {token: token});
  }

}

