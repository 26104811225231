<app-page-title title="Alım/Satım" [breadcrumbItems]="breadCrumbItems"></app-page-title>
<div class="mb-2 text-end">
  <!-- Son İşlemler Dropdown -->
  <div ngbDropdown container="body" display="dynamic" placement="bottom-right" class="d-inline">
    <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>
      Son İşlemler
    </button>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="p-0 mb-2">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th style="width: 50px">Durum</th>
            <th>Müşteri</th>
            <th>Satış</th>
            <th>Alış</th>
            <th>İşlem</th>
          </tr>
        </thead>
        <tr *ngFor="let item of lastOperationList; index as i">
          <td class="text-dark">
            <i class="fa fa-check" triggers="hover" ngbTooltip="Borçlu"
              *ngIf="item.state == ExchangeState.DoneWithDebt"></i>
            <i class="fa fa-check-double" triggers="hover" ngbTooltip="Tamamlandı"
              *ngIf="item.state == ExchangeState.Done"></i>
            <i class="fas fa-handshake" triggers="hover" ngbTooltip="Bağlantı"
              *ngIf="item.state == ExchangeState.Agreed"></i>
            <i class="fas fas fa-user-clock" triggers="hover" ngbTooltip="Alacaklı"
              *ngIf="item.state == ExchangeState.DoneWithDeposit"></i>
          </td>
          <td>
            <a *ngIf="item.customerName" title="Müşteri Detayına Git" data-toggle-bs
              routerLink="/customer/detail/{{ item.customerId }}">
              {{ item.customerName }}
            </a>
          </td>
          <td *ngIf="item.exchangeCurrency == Currency.Gold">
            {{item.exchangeAmount | currencyAmount: item.exchangeCurrency }} gr {{ item.exchangeSubType | subTypeDisplay
            }} {{item.exchangeCurrency | customCurrency}}({{item.exchangeMilyem}})
          </td>
          <td
            *ngIf="item.exchangeCurrency == Currency.Accessories ||  item.exchangeCurrency == Currency.OtherAccessories || item.exchangeCurrency ==  Currency.Silver ">
            {{item.exchangeAmount | currencyAmount: item.exchangeCurrency }} {{item.exchangeCurrency | customCurrency}}
            ({{item.exchangeSubType | subTypeDisplay }})
          </td>
          <td *ngIf="item.exchangeCurrency == Currency.Other">
            {{item.exchangeAmount | currencyAmount: item.exchangeCurrency }} {{item.exchangeSubType | subTypeDisplay }}
          </td>
          <td
            *ngIf="item.exchangeCurrency == Currency.Tl || item.exchangeCurrency == Currency.Euro || item.exchangeCurrency == Currency.Usd ">
            {{item.exchangeAmount | currencyAmount: item.exchangeCurrency }} {{item.exchangeCurrency | customCurrency}}
          </td>

          <td *ngIf="item.paymentCurrency == Currency.Gold">
            {{item.paymentAmount | currencyAmount: item.paymentCurrency}} gr {{ item.paymentSubType | subTypeDisplay }}
            {{item.paymentCurrency | customCurrency}}({{item.paymentMilyem}})
          </td>
          <td
            *ngIf="item.paymentCurrency == Currency.Accessories ||  item.paymentCurrency == Currency.OtherAccessories || item.paymentCurrency ==  Currency.Silver">
            {{item.paymentAmount | currencyAmount: item.paymentCurrency }} {{item.paymentCurrency | customCurrency}}
            ({{item.paymentSubType | subTypeDisplay }})
          </td>
          <td *ngIf="item.paymentCurrency == Currency.Other">
            {{item.paymentAmount | currencyAmount: item.paymentCurrency}} {{item.paymentSubType | subTypeDisplay}}
          </td>
          <td
            *ngIf="item.paymentCurrency == Currency.Tl || item.paymentCurrency == Currency.Euro || item.paymentCurrency == Currency.Usd ">
            {{item.paymentAmount | currencyAmount: item.paymentCurrency}} {{ item.paymentCurrency | customCurrency}}
          </td>
          <td class="overflow-hidden text-end" ngbDropdown container="body">
            <div class="btn-group">
              <button class="btn btn-primary btn-sm" routerLink="/exchange/detail/{{item.id}}" title="Detay"> <i
                  class="mdi mdi-eye"></i></button>
              <button class="btn btn-outline-primary btn-sm" ngbDropdownToggle> <i class="mdi mdi-tune-variant"></i>
              </button>
              <div ngbDropdownMenu *hasRole="[Role.Admin, Role.SuperAdmin]">
                <!-- <a href="javascript:void(0)" ngbDropdownItem (click)="undoState(item)" *ngIf="item.previousState != null && isUndo == false;">Geri Al</a> -->
                <!-- <a href="javascript:void(0)" ngbDropdownItem (click)="doneWithDebt(item)" *ngIf="item.state == ExchangeState.Agreed">Borçlu </a> -->
                <!-- <a href="javascript:void(0)" ngbDropdownItem (click)="doneWithDeposit(item)" *ngIf="item.state == ExchangeState.Agreed">Alacaklı</a> -->
                <!-- <a href="javascript:void(0)" ngbDropdownItem (click)="donePayment(item)" *ngIf="item.state == ExchangeState.Agreed">Ödendi</a> -->
                <a href="javascript:void(0)" ngbDropdownItem (click)="delete(item.id)">Sil</a>
              </div>
            </div>
          </td>
        </tr>
      </table>
      <button ngbDropdownItem class="text-center" routerLink="/exchange/list">
        Tümünü Gör
      </button>
    </div>
  </div>

</div>
<div class="card mb-1" style="border-radius: 10px !important;">
  <div class="card-body">
    <div class="row">
      <div class="col-md-6 mb-3">
        <label for="customer">Müşteri</label>
        <div class="input-group">
          <!-- Customer Select -->
          <ng-container *ngIf="!customerIdFromRoute">
            <ng-select disabled="{{summaryCart.length > 0}}" id="customer" style="width:89%" [items]="customer$ | async" [typeahead]="customerInput$"
              [loading]="customersLoading" bindLabel="text" bindValue="value" [(ngModel)]="request.customerId"
              name="customerId" placeholder="Müşteri Seçiniz..." notFoundText="Kayıt bulunamadı!"
              (ngModelChange)="loadCustomerBalance($event)">
            </ng-select>
          </ng-container>
          <!-- Customer From Route -->
          <ng-container *ngIf="customerIdFromRoute">
            <select disabled="{{summaryCart.length > 0}}" style="width:89%" [(ngModel)]="request.customerId" class="form-control" name="customerId"
              placeholder="Müşteri Seçiniz...">
              <option *ngFor="let item of customerList" [value]="item.value">{{item.text}}</option>
            </select>
            <button class="btn btn-outline-secondary" triggers="hover" ngbTooltip="Seçimi Temizle"
              (click)="clearSelectedCustomer()"> <i class="fas fa-times"></i> </button>
          </ng-container>
          <button (click)="openCreateCustomer()" class="btn btn-outline-secondary" triggers="hover"
            ngbTooltip="Yeni Müşteri"><i class="fa fa-plus"></i></button>
        </div>
      </div>
      <div class="col-md-6 mb-3">
        <label for="case">Kasa</label>
        <div class="input-group mb-3">
          <select #case class="form-select" name="case" [(ngModel)]="request.exchangeCaseId"
            (ngModelChange)="request.paymentCaseId = request.exchangeCaseId" [disabled]="!useSingleCase">
            <option class="custom-option" *ngFor="let item of caseList" [value]="item.value"> {{item.text}} </option>
          </select>
          <label class="input-group-text">
            <span>Tek Kasa</span>
            <input class="form-check-input ms-2" type="checkbox" triggers="hover" ngbTooltip="Tek Kasa"
              [(ngModel)]="useSingleCase" (ngModelChange)="setMultipleCase()" value="1" id="singleCase" checked>
          </label>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="row">
  <div class="col">
    <div class="card mb-1 shadow-sm" style="border-radius: 10px;">
      <div class="card-body">
        <div triggers="hover" ngbTooltip="Şube Bakiyesi" *ngIf="balanceOfBranch && !exchangeSubTypes">
          <span *ngIf="balanceOfBranch.balance >= 0" class="text-success"> {{balanceOfBranch.balance | currencyAmount:
            balanceOfBranch.currency}} </span>
          <span *ngIf="balanceOfBranch.balance < 0" class="text-danger"> {{balanceOfBranch.balance | currencyAmount:
            balanceOfBranch.currency}} </span>
        </div>
        <div class="mt-2 d-flex justify-content-between">
          <h5 class="text-danger">SATIŞ</h5>
          <div *ngIf="costForSellingCurrency">
            <span class="text-primary fw-bold me-3" triggers="hover"
              ngbTooltip="Satış Biriminin Maliyetleri">Maliyetler: </span>
            <span *ngIf="costForSellingCurrency.costTL && costForSellingCurrency.costTL != 1" triggers="hover"
              ngbTooltip="TL Maliyet" class="me-2"> <i container="body" class="fas fa-lira-sign text-danger"></i>
              <basic-spinner class="ms-2" [showSpinner]="sellingCurrencyCostLoading"></basic-spinner>
              <ng-container *ngIf="!sellingCurrencyCostLoading"> {{costForSellingCurrency.costTL | costAmount}} </ng-container></span>
            <span *ngIf="costForSellingCurrency.costUSD && costForSellingCurrency.costUSD != 1" class="me-2"
              triggers="hover" ngbTooltip="USD Maliyet"> <i container="body"
                class="fas fa-dollar-sign text-success"></i>
              <basic-spinner class="ms-2" [showSpinner]="sellingCurrencyCostLoading"></basic-spinner>
              <ng-container *ngIf="!sellingCurrencyCostLoading"> {{costForSellingCurrency.costUSD | costAmount}} </ng-container></span>
            <span *ngIf="costForSellingCurrency.costHAS && costForSellingCurrency.costHAS != 1" triggers="hover"
              ngbTooltip="HAS Maliyet" class="me-2"> <i container="body" class="fas fa-coins text-warning"></i>
              <basic-spinner class="ms-2" [showSpinner]="sellingCurrencyCostLoading"></basic-spinner>
              <ng-container *ngIf="!sellingCurrencyCostLoading"> {{costForSellingCurrency.costHAS | costAmount}}</ng-container>
            </span>
          </div>
        </div>

        <hr style="border-top: 2px solid red; color: red;">
        <div class="row mt-2 exchange-input">

          <div class="col-sm-6 col-md-3 col-lg mb-3" *ngIf="!useSingleCase">
            <div class="input-group">
              <select class="form-control" name="case" id="case" [(ngModel)]="request.exchangeCaseId">
                <option class="custom-option" *ngFor="let item of caseList" [value]="item.value"> {{item.text}}
                </option>
              </select>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 col-lg mb-3">
            <div class="input-group">
              <select id="exchangeCurrency" [(ngModel)]="request.exchangeCurrency" name="exchangeCurrency"
                (change)="exchangeCurrencyChanged()" class="form-control">
                <option *ngFor="let item of currencyList;" [value]="item"> {{ item | customCurrency }} </option>
              </select>
              <div class="input-group-append">
                <span class="input-group-text">Birim</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 col-lg mb-3" *ngIf="exchangeSubTypes">
            <div class="input-group">
              <select id="exchangeSubType" [(ngModel)]="request.exchangeSubType" name="exchangeSubType"
                (change)="exchangeSubTypeChanged()" class="form-control">
                <option *ngFor="let item of exchangeSubTypes;" [value]="item"> {{ item | subTypeDisplay }} </option>
              </select>
              <div class="input-group-append">
                <span class="input-group-text">Alt Birim</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 col-lg mb-3" *ngIf="request.exchangeCurrency == Currency.Gold">
            <div class="input-group">
              <input id="exchangeMilyem" [(ngModel)]="request.exchangeMilyem" name="exchangeMilyem"
                (keyup.enter)="calculatePaymentAmount()" type="number" class="form-control" placeholder="Milyem"
                autocomplete="off" />
              <div class="input-group-append">
                <span class="input-group-text">Milyem</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 col-lg mb-3">
            <div class="input-group mb-2">
              <input id="exchangeAmount" type="number" class="form-control text-right"
                [(ngModel)]="request.exchangeAmount" (keyup.enter)="calculatePaymentAmount()" autocomplete="off">
              <div class="input-group-append" title="{{ request.exchangeCurrency | customCurrency }}">
                <span class="input-group-text">Miktar</span>
              </div>
            </div>
            <label for="exchangeAmount" class="d-flex" style="justify-content: space-between;">
              <span class="float-end text-muted">{{request.exchangeAmount | currencyAmount:
                request.exchangeCurrency}}</span>
            </label>
          </div>
        </div>
        <!-- Reverse Exchange Start -->
        <div class="row mt-2 text-center">
          <div class="d-flex justify-content-center">
            <button class="btn-transpose" triggers="hover" ngbTooltip="Çevir" (click)="transposeToOperation()"><i
                class='bx bx-transfer-alt bx-sm'></i></button>
          </div>
        </div>
        <!-- Reverse Exchange End -->
        <div class="mt-2 d-flex justify-content-between">
          <h5 class="text-success d-inline-block">ALIŞ</h5>
          <div *ngIf="costForBuyingCurrency">
            <span class="text-primary fw-bold me-3" triggers="hover" ngbTooltip="Alış Biriminin Maliyetleri">Maliyetler:
            </span>
            <span *ngIf="costForBuyingCurrency.costTL && costForBuyingCurrency.costTL != 1" triggers="hover"
              ngbTooltip="TL Maliyet" class="me-2"> <i container="body" class="fas fa-lira-sign text-danger"></i>
              <basic-spinner class="ms-2" [showSpinner]="buyingCurrencyCostLoading"></basic-spinner>
              <ng-container *ngIf="!buyingCurrencyCostLoading"> {{costForBuyingCurrency.costTL | currencyAmount: null:
                null: true }} </ng-container></span>
            <span *ngIf="costForBuyingCurrency.costUSD && costForBuyingCurrency.costUSD != 1" class="me-2"
              triggers="hover" ngbTooltip="USD Maliyet"> <i container="body"
                class="fas fa-dollar-sign text-success"></i>
              <basic-spinner class="ms-2" [showSpinner]="buyingCurrencyCostLoading"></basic-spinner>
              <ng-container *ngIf="!buyingCurrencyCostLoading"> {{costForBuyingCurrency.costUSD | currencyAmount: null:
                null: true }} </ng-container></span>
            <span *ngIf="costForBuyingCurrency.costHAS && costForBuyingCurrency.costHAS != 1" triggers="hover"
              ngbTooltip="HAS Maliyet" class="me-2"> <i container="body" class="fas fa-coins text-warning"></i>
              <basic-spinner class="ms-2" [showSpinner]="buyingCurrencyCostLoading"></basic-spinner>
              <ng-container *ngIf="!buyingCurrencyCostLoading"> {{costForBuyingCurrency.costHAS | currencyAmount: null:
                null: true }}</ng-container>
            </span>
          </div>
        </div>

        <hr style="border-top: 2px solid green; color: green;">
        <div class="row mt-2 payment-input">
          <div class="col" *ngIf="!useSingleCase">
            <div class="input-group">
              <select class="form-control" name="case" id="case" [(ngModel)]="request.paymentCaseId">
                <option class="custom-option" *ngFor="let item of caseList" [value]="item.value"> {{item.text}}
                </option>
              </select>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 col-lg mb-3">
            <div class="input-group">
              <select id="paymentCurrency" [(ngModel)]="request.paymentCurrency" name="paymentCurrency"
                (change)="paymentCurrencyChanged()" class="form-control">
                <option *ngFor="let item of currencyList;" [value]="item"> {{ item | customCurrency }} </option>
              </select>
              <div class="input-group-append">
                <span class="input-group-text">Birim</span>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-md-3 col-lg mb-3" *ngIf="paymentSubTypes">
            <div class="input-group">
              <select id="paymentSubType" [(ngModel)]="request.paymentSubType" name="paymentSubType"
                (change)="paymentSubTypeChanged()" class="form-control">
                <option *ngFor="let item of paymentSubTypes;" [value]="item"> {{ item | subTypeDisplay }} </option>
              </select>
              <div class="input-group-append">
                <span class="input-group-text">Alt Birim</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 col-lg mb-3" *ngIf="request.paymentCurrency == Currency.Gold">
            <div>
              <div class="input-group">
                <input id="paymentMilyem" [(ngModel)]="request.paymentMilyem" name="paymentMilyem"
                  (keyup.enter)="calculateExchangeAmount()" type="number" class="form-control" placeholder="Milyem"
                  autocomplete="off" />
                <div class="input-group-append" title="{{ request.paymentCurrency | customCurrency }}">
                  <span class="input-group-text">Milyem</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 col-lg mb-3">
            <div class="input-group">
              <!--input type="text" class="form-control text-right" [(ngModel)]="request.paymentAmount" autocomplete="off" currencyInput maxDigits="9" (keyup.enter)="calculateExchangeAmount(false)">-->
              <input id="paymentAmount" autocomplete="off" class="form-control text-sm-right" type="number"
                [(ngModel)]="request.paymentAmount" name="paymentAmount" (keyup.enter)="calculateExchangeAmount()"
                autocomplete="off" />
              <div class="input-group-append" title="{{ request.paymentCurrency | customCurrency }}">
                <span class="input-group-text">Miktar</span>
              </div>
            </div>
            <label for="paymentAmount" class="d-flex" style="justify-content: space-between;">
              <span class="float-end text-muted">{{request.paymentAmount | currencyAmount: request.paymentCurrency
                }}</span>
            </label>
          </div>
        </div>
        <!-- <p><strong>Source    </strong> Currency: <strong>{{request.paymentCurrency}}   </strong>  Subtype: <strong>{{request.paymentSubType}}   </strong> | <strong>Destination    </strong> Currency: <strong>{{request.exchangeCurrency}}   </strong>  Subtype: <strong>{{request.exchangeSubType}}   </strong></p> -->
        <div class="d-flex justify-content-between">
          <div>
            <h5 class="d-inline-block text-primary">KUR</h5>
            <span *ngIf="selectedParityObject" triggers="hover" [ngbTooltip]="parityTooltipText" class="fs-6 mx-3"><i
                [ngClass]="parityIconClass"></i>{{parityHelper | currencyAmount:null : null : true}}</span>
            <span class="fs-6 ms-2 badge bg-danger" *ngIf="baseMilyem != 0"> {{baseMilyem}} Milyem</span>
          </div>
        </div>
        <hr style="border-top: 2px solid blue; color: blue">
        <div class="row parity-input">
          <div class="col-md-6">
            <div class="input-group">
              <input autocomplete="off" type="number" class="form-control text-right" [(ngModel)]="request.parity"
                (keyup.enter)="calculateAmount()" placeholder="KUR" autocomplete="off">
              <div class="input-group-append">
                <button triggers="manual" #t1="ngbTooltip" (click)="changeCalculateType()" type="button"
                  placement="auto" ngbTooltip="Hesaplama türü seç" class="btn btn-dark"
                  *ngIf="!selectedParityObject || (selectedParityObject && selectedParityObject.calculationType == null)"><i
                    class="fa fa-question"></i></button>
                <button #parityTooltip triggers="hover" ngbTooltip="Hesaplama seçebilirsiniz"
                  (click)="changeCalculateType()" [disabled]="parityFounded" type="button" placement="auto"
                  triggers="hover" ngbTooltip="Hesaplama türü değiştir" class="btn btn-primary"
                  *ngIf="selectedParityObject && selectedParityObject.calculationType == 0"><i
                    class="fa fa-times"></i></button>
                <button #parityTooltip triggers="hover" ngbTooltip="Hesaplama seçebilirsiniz"
                  (click)="changeCalculateType()" [disabled]="parityFounded" type="button" placement="auto"
                  triggers="hover" ngbTooltip="Hesaplama türü değiştir" class="btn btn-danger"
                  *ngIf="selectedParityObject && selectedParityObject.calculationType == 1"><i
                    class="fa fa-divide"></i></button>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="input-group d-flex justify-content-end">
              <input *ngIf="useDescription" autocomplete="off" type="text" class="form-control text-start" [(ngModel)]="request.description" placeholder="Açıklama">
              <div class="input-group-append">
                <label class="input-group-text">
                  <span>Açıklama Ekle</span>
                  <input class="form-check-input ms-2" type="checkbox" triggers="hover" ngbTooltip="Açıklama Ekle" [(ngModel)]="useDescription" value="1" id="useDescription" checked>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="ms-1 mt-4" *ngIf="request.customerId">
          <div class="form-check">
            <input class="form-check-input" [(ngModel)]="print" type="checkbox" value="1" id="flexCheckDefault" checked>
            <label class="form-check-label" for="flexCheckDefault">
              Fiş Yazdır
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="row p-2 justify-content-md-center" *ngIf="request.customerId">
      <div class="col-sm-4 col-md2 col-lg mb-3">
        <button class="btn btn-primary btn-label text-nowrap w-100" type="submit" [disabled]="!this.request.customerId"
          (click)="addToCart(); $event.stopPropagation();" title="SEPETE EKLE"> <i class="fa fa-plus  label-icon"></i>
          EKLE</button>
      </div>
      <div class="col-sm-6 col-md-2 col-lg mb-3" *ngIf="summaryCart.length == 0">
        <button type="button" [disabled]="isDisabled" (click)="submit(ExchangeState.Agreed, true)"
          class="btn btn-warning btn-label text-nowrap w-100"> <i class="fa fa-handshake label-icon"></i>
          BAĞLANTI</button>
      </div>
      <div class="col-sm-6 col-md-2 col-lg mb-3" *ngIf="summaryCart.length == 0 ">
        <button type="button" [disabled]="isDisabled" (click)="submit(ExchangeState.DoneWithDebt, true)"
          class="btn btn-danger btn-label text-nowrap w-100"> <i class="fa fa-check label-icon"></i> BORÇLU </button>
      </div>
      <div class="col-sm-6 col-md-2 col-lg mb-3" *ngIf="summaryCart.length == 0">
        <button type="button" [disabled]="isDisabled" (click)="submit(ExchangeState.DoneWithDeposit, true)"
          class="btn btn-info btn-label text-nowrap w-100"> <i class="fas fas fa-user-clock label-icon"></i> ALACAKLI
        </button>
      </div>
      <div class="col-sm-6 col-md-2 col-lg mb-3" *ngIf="summaryCart.length == 0">
        <button type="button" [disabled]="isDisabled" (click)="submit(ExchangeState.Done, true)"
          class="btn btn-success btn-label text-nowrap w-100"> <i class="fa fa-check-double label-icon"></i>
          ÖDENDİ</button>
      </div>
    </div>
  </div>

  <div *ngIf="customer || summaryCart.length > 0" class="col-xl-4">
    <app-customer-balance showCustomerName="true" *ngIf="customer" [_customer]="customer"></app-customer-balance>
    <!--Exchange Summary-->

    <div class="card table-responsive mt-3" *ngIf="summaryCart.length > 0">
      <div class="card-header bg-transparent">
        <h4 class="mt-1">İşlem Sepeti</h4>
      </div>
      <table class="table table-striped mb-0">
        <thead>
          <tr>
            <th>Müşteri</th>
            <th>Satış</th>
            <th>K.</th>
            <th>Kur</th>
            <th>Alış</th>
            <th>K.</th>
            <th> </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of summaryCart">
            <td> {{item.customerName}} </td>
            <td *ngIf="item.exchangeCurrency == Currency.Gold">{{item.exchangeAmount | currencyAmount:
              item.exchangeCurrency}} gr {{ item.exchangeSubType | subTypeDisplay }} {{item.exchangeCurrency |
              customCurrency}}({{item.exchangeMilyem}})</td>
            <td *ngIf="item.exchangeCurrency == Currency.Other">{{item.exchangeAmount | currency :'':''}}
              {{item.exchangeSubType | subTypeDisplay}}</td>
            <td
              *ngIf="item.exchangeCurrency == Currency.Accessories ||  item.exchangeCurrency == Currency.OtherAccessories || item.exchangeCurrency == Currency.Silver">
              {{item.exchangeAmount | currencyAmount: item.exchangeCurrency}} {{ item.exchangeCurrency | customCurrency
              }} ({{item.exchangeSubType | subTypeDisplay}})</td>
            <td
              *ngIf="item.exchangeCurrency == Currency.Tl || item.exchangeCurrency == Currency.Euro || item.exchangeCurrency == Currency.Usd">
              {{item.exchangeAmount | currency :'':''}} {{ item.exchangeCurrency | customCurrency }}</td>
            <td>{{ item.exchangeCaseName}}</td>
            <td class="text-center">{{ item.parity | currencyAmount: null: null: true }}</td>
            <td *ngIf="item.paymentCurrency == Currency.Gold">{{item.paymentAmount | currencyAmount :
              item.paymentCurrency}} gr {{ item.paymentSubType | subTypeDisplay }} {{item.paymentCurrency |
              customCurrency}}({{item.paymentMilyem}}) </td>
            <td *ngIf="item.paymentCurrency == Currency.Other">{{item.paymentAmount | currencyAmount :
              item.paymentCurrency}} {{item.paymentSubType | subTypeDisplay}}</td>
            <td
              *ngIf="item.paymentCurrency == Currency.Accessories ||  item.paymentCurrency == Currency.OtherAccessories || item.paymentCurrency == Currency.Silver">
              {{item.paymentAmount | currencyAmount: item.paymentCurrency}} {{ item.paymentCurrency | customCurrency}}
              ({{item.paymentSubType | subTypeDisplay}})</td>
            <td
              *ngIf="item.paymentCurrency == Currency.Tl || item.paymentCurrency == Currency.Euro || item.paymentCurrency == Currency.Usd">
              {{item.paymentAmount | currencyAmount: item.paymentCurrency}} {{ item.paymentCurrency | customCurrency}}
            </td>
            <td> {{item.paymentCaseName}} </td>
            <td class="text-end">
              <button title="Sil" class="btn btn-outline-danger btn-sm" (click)="removeFromCart(item)"> <i
                  class="fa fa-times"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="row mt-1">
        <div class="exchange-balances col">
          <div class="balance-header"> Satış Toplam </div>
          <div class="balance-values bt-red bg-light-red" *ngFor="let item of summaryBalances.exchanges">
            <div class="balance-currency">
              <span> {{item.currency | customCurrency}}
                <span *ngIf="item.subType != undefined"> ({{item.subType | subTypeDisplay}}) </span>
                <span *ngIf="item.milyem != null"> ({{item.milyem}}) </span>
              </span>
            </div>
            <div class="balance-amount">
              {{item.balance | currencyAmount: item.currency}}
            </div>
          </div>
        </div>

        <div class="exchange-balances col">
          <div class="balance-header"> Alış Toplam </div>
          <div class="balance-values bt-green bg-light-green" *ngFor="let item of summaryBalances.payments">
            <div class="balance-currency">
              <span> {{item.currency | customCurrency}}
                <span *ngIf="item.subType != undefined"> ({{item.subType | subTypeDisplay}}) </span>
                <span *ngIf="item.milyem != null"> ({{item.milyem}}) </span>
              </span>
            </div>
            <div class="balance-amount">
              {{item.balance | currencyAmount: item.currency}}
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-1">
        <div class="cart-buttons">
          <button type="button" triggers="hover" ngbTooltip="Bağlantı" [disabled]="isDisabled"
            (click)="submit(ExchangeState.Agreed)" class="btn btn-warning me-2 "> <i
              class="fa fa-handshake f-2"></i></button>
          <button type="button" triggers="hover" ngbTooltip="Borçlu" [disabled]="isDisabled"
            (click)="submit(ExchangeState.DoneWithDebt)" class="btn btn-danger me-2 "> <i class="fa fa-check f-2"></i>
          </button>
          <button type="button" triggers="hover" ngbTooltip="Alacaklı" [disabled]="isDisabled"
            (click)="submit(ExchangeState.DoneWithDeposit)" class="btn btn-info me-2 "> <i
              class="fas fas fa-user-clock f-2"></i></button>
          <button type="button" triggers="hover" ngbTooltip="Ödendi" [disabled]="isDisabled"
            (click)="submit(ExchangeState.Done)" class="btn btn-success me-4"> <i
              class="fa fa-check-double f-2"></i></button>
        </div>
      </div>
    </div>

    <!-- Information Bill -->
    <ng-template #infoModal let-modal>
      <div class="modal-header">
        <h5 class="modal-title" id="modal-basic-title"> Bilgilendirme </h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
      </div>
      <div class="modal-body">
        <p class="text-end"> <strong> Tarih: </strong> {{operationDate | date:'dd.MM.y HH:mm'}} </p>

        <table class="table table-striped mb-0">
          <thead>
            <tr>
              <th>Müşteri</th>
              <th class="border-bottom border-danger text-end">Satış</th>
              <th>Kasa</th>
              <th class="text-center border-bottom border-secondary">Kur</th>
              <th class="border-bottom border-success text-end">Alış</th>
              <th>Kasa</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of infoCart">
              <td>{{item.customerName}}</td>
              <td class="text-end" *ngIf="item.exchangeCurrency == Currency.Gold">{{item.exchangeAmount |
                currencyAmount: item.exchangeCurrency}} gr {{ item.exchangeSubType | subTypeDisplay }}
                {{item.exchangeCurrency | customCurrency}} <span
                  *ngIf="item.exchangeMilyem != null">({{item.exchangeMilyem}})</span> </td>
              <td class="text-end" *ngIf="item.exchangeCurrency == Currency.Other">{{item.exchangeAmount |
                currencyAmount: item.exchangeCurrency}} {{item.exchangeSubType | subTypeDisplay}}</td>
              <td class="text-end"
                *ngIf="item.exchangeCurrency == Currency.Accessories ||  item.exchangeCurrency == Currency.OtherAccessories || item.exchangeCurrency == Currency.Silver">
                {{item.exchangeAmount | currencyAmount: item.exchangeCurrency}} {{ item.exchangeCurrency |
                customCurrency }} ({{item.exchangeSubType | subTypeDisplay}})</td>
              <td class="text-end"
                *ngIf="item.exchangeCurrency == Currency.Tl || item.exchangeCurrency == Currency.Euro || item.exchangeCurrency == Currency.Usd">
                {{item.exchangeAmount | currencyAmount: item.exchangeCurrency}} {{ item.exchangeCurrency |
                customCurrency }}</td>
              <td> {{item.exchangeCaseName}} </td>
              <td class="text-center">{{ item.parity | currencyAmount: null: null: true }}</td>
              <td class="text-end" *ngIf="item.paymentCurrency == Currency.Gold">{{item.paymentAmount | currencyAmount:
                item.paymentCurrency}} gr {{ item.paymentSubType | subTypeDisplay }} {{item.paymentCurrency |
                customCurrency}} <span *ngIf="item.paymentMilyem != null">({{item.paymentMilyem}})</span> </td>
              <td class="text-end" *ngIf="item.paymentCurrency == Currency.Other">{{item.paymentAmount | currencyAmount:
                item.paymentCurrency}} {{item.paymentSubType | subTypeDisplay}}</td>
              <td class="text-end"
                *ngIf="item.paymentCurrency == Currency.Accessories ||  item.paymentCurrency == Currency.OtherAccessories || item.paymentCurrency == Currency.Silver">
                {{item.paymentAmount | currencyAmount: item.paymentCurrency}} {{ item.paymentCurrency | customCurrency}}
                ({{item.paymentSubType | subTypeDisplay}})</td>
              <td class="text-end"
                *ngIf="item.paymentCurrency == Currency.Tl || item.paymentCurrency == Currency.Euro || item.paymentCurrency == Currency.Usd">
                {{item.paymentAmount | currencyAmount: item.paymentCurrency}} {{ item.paymentCurrency | customCurrency}}
              </td>
              <td> {{item.paymentCaseName}}</td>
            </tr>
          </tbody>
        </table>
        <div class="row mt-1" *ngIf="infoCart.length > 1">
          <div class="exchange-balances col">
            <div class="balance-header"> Satış Toplam </div>
            <div class="balance-values bt-red bg-light-red" *ngFor="let item of infoBalances.exchanges">
              <div class="balance-currency">
                <span> {{item.currency | customCurrency}} <span *ngIf="item.subType != undefined"> ({{item.subType |
                    subTypeDisplay}}) </span>
                  <span *ngIf="item.milyem != null"> ({{item.milyem}}) </span>

                </span>
              </div>
              <div class="balance-amount">
                {{item.balance | currencyAmount: item.currency}}
              </div>
            </div>
          </div>

          <div class="exchange-balances col">
            <div class="balance-header"> Alış Toplam </div>
            <div class="balance-values bt-green bg-light-green" *ngFor="let item of infoBalances.payments">
              <div class="balance-currency">
                <span> {{item.currency | customCurrency}} <span *ngIf="item.subType != undefined"> ({{item.subType |
                    subTypeDisplay}}) </span>
                  <span *ngIf="item.milyem != null"> ({{item.milyem}}) </span>
                </span>
              </div>
              <div class="balance-amount">
                {{item.balance | currencyAmount: item.currency}}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer text-end">
        <a routerLink="/receipts/exchange-summary/{{groupId}}" (click)="closeAllModals()" class="btn btn-light">Fiş
          Oluştur</a>
      </div>
      <!-- <div class="modal-footer">
        <a class="btn btn-light" (click)="printSummaryBill()" ><i class="fa fa-print me-1"></i> Yazdır</a>
      </div> -->
    </ng-template>

    <div class="d-none">
      <div class="card shadow-sm border border-primary" style="border-radius: 10px;">
        <div class="card-header mt-1 mb-0 pb-0 bg-transparent text-center">
          <h4 class="card-title text-primary">SON İŞLEMLER</h4>
        </div>
        <div class="card-body" *ngIf="lastOperationList && lastOperationList.length > 0">
          <table class="table table-borderless">
            <thead class="text-center">
              <tr class="border-bottom">
                <th></th>
                <th>Müşteri</th>
                <th>Alış</th>
                <th>Satış</th>
                <th>İşlem</th>
              </tr>
            </thead>
            <tr *ngFor="let item of lastOperationList; index as i">
              <td class="text-dark">
                <i class="fa fa-check" triggers="hover" ngbTooltip="Borçlu"
                  *ngIf="item.state == ExchangeState.DoneWithDebt"></i>
                <i class="fa fa-check-double" triggers="hover" ngbTooltip="Tamamlandı"
                  *ngIf="item.state == ExchangeState.Done"></i>
                <i class="fas fa-handshake" triggers="hover" ngbTooltip="Bağlantı"
                  *ngIf="item.state == ExchangeState.Agreed"></i>
                <i class="fas fas fa-user-clock" triggers="hover" ngbTooltip="Alacaklı"
                  *ngIf="item.state == ExchangeState.DoneWithDeposit"></i>
              </td>
              <td>
                <a *ngIf="item.customerName" title="Müşteri Detayına Git" data-toggle-bs
                  routerLink="/customer/detail/{{ item.customerId }}">
                  {{ item.customerName }}
                </a>
              </td>
              <td *ngIf="item.paymentCurrency == Currency.Gold">{{item.paymentAmount | currencyAmount:
                item.paymentCurrency}} gr {{ item.paymentSubType | subTypeDisplay }} {{item.paymentCurrency |
                customCurrency}}({{item.paymentMilyem}})</td>
              <td *ngIf="item.paymentCurrency == Currency.Other">{{item.paymentAmount | currencyAmount:
                item.paymentCurrency}} {{item.paymentSubType | subTypeDisplay}}</td>
              <td
                *ngIf="item.paymentCurrency == Currency.Accessories ||  item.paymentCurrency == Currency.OtherAccessories || item.paymentCurrency == Currency.Silver">
                {{item.paymentAmount | currencyAmount: item.paymentCurrency}} {{ item.paymentCurrency | customCurrency}}
                ({{item.paymentSubType | subTypeDisplay}})</td>
              <td
                *ngIf="item.paymentCurrency == Currency.Tl || item.paymentCurrency == Currency.Euro || item.paymentCurrency == Currency.Usd">
                {{item.paymentAmount | currencyAmount: item.paymentCurrency}} {{ item.paymentCurrency | customCurrency}}
              </td>

              <td *ngIf="item.exchangeCurrency == Currency.Gold">{{item.exchangeAmount | currencyAmount:
                item.exchangeCurrency}} gr {{ item.exchangeSubType | subTypeDisplay }} {{item.exchangeCurrency |
                customCurrency}}({{item.exchangeMilyem}})</td>
              <td *ngIf="item.exchangeCurrency == Currency.Other">{{item.exchangeAmount | currencyAmount:
                item.exchangeCurrency}} {{item.exchangeSubType | subTypeDisplay}}</td>
              <td
                *ngIf="item.exchangeCurrency == Currency.Accessories ||  item.exchangeCurrency == Currency.OtherAccessories || item.exchangeCurrency == Currency.Silver">
                {{item.exchangeAmount | currencyAmount: item.exchangeCurrency}} {{ item.exchangeCurrency |
                customCurrency }} ({{item.exchangeSubType | subTypeDisplay}})</td>
              <td
                *ngIf="item.exchangeCurrency == Currency.Tl || item.exchangeCurrency == Currency.Euro || item.exchangeCurrency == Currency.Usd ">
                {{item.exchangeAmount | currencyAmount: item.exchangeCurrency}} {{ item.exchangeCurrency |
                customCurrency }}</td>
              <td class="overflow-hidden text-end" ngbDropdown container="body">
                <div class="btn-group">
                  <button class="btn btn-primary btn-sm" routerLink="/exchange/detail/{{item.id}}" title="Detay"> <i
                      class="mdi mdi-eye"></i></button>
                  <button class="btn btn-outline-primary btn-sm" ngbDropdownToggle> <i class="mdi mdi-tune-variant"></i>
                  </button>
                  <div ngbDropdownMenu>
                    <!-- <a href="javascript:void(0)" ngbDropdownItem (click)="undoState(item)" *ngIf="item.previousState != null && isUndo == false;">Geri Al</a> -->
                    <!-- <a href="javascript:void(0)" ngbDropdownItem (click)="doneWithDebt(item)" *ngIf="item.state == ExchangeState.Agreed">Borçlu </a>
                      <a href="javascript:void(0)" ngbDropdownItem (click)="donePayment(item)" *ngIf="item.state == ExchangeState.Agreed">Ödendi</a>
                      <a href="javascript:void(0)" ngbDropdownItem (click)="doneWithDeposit(item)" *ngIf="item.state == ExchangeState.Agreed">Alacaklı</a> -->
                    <a href="javascript:void(0)" *hasRole="[Role.Admin, Role.SuperAdmin]" ngbDropdownItem
                      (click)="delete(item.id)">Sil</a>
                  </div>
                </div>
              </td>
            </tr>
          </table>
          <button ngbDropdownItem class="text-center" routerLink="/exchange/list">
            Tümünü Gör
          </button>
        </div>
      </div>
      <div *ngIf="lastOperationList && lastOperationList.length < 1" class="alert alert-primary">
        Henüz alım satım işlemi gerçekleştirilmedi!
      </div>
    </div>
  </div>
</div>
