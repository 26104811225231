import { Component, OnDestroy, OnInit } from '@angular/core';
import { Currency } from 'src/app/models/enums/currency';
import { ExchangeFilter } from 'src/app/models/filters/exchange-filter';
import { ApplicationUser } from 'src/app/models/application-user/application-user';
import { Branch } from 'src/app/models/branch/branch';
import { ExchangeState } from 'src/app/models/enums/exchange-state';
import { CustomCurrencyPipe } from 'src/app/shared/pipes/custom-currency.pipe';
import { SubTypeDisplayPipe } from 'src/app/shared/pipes/sub-type-display.pipe';
import { CurrencyPipe } from '@angular/common';
import { ApplicationUserService } from 'src/app/core/services/application-user.service';
import { Role } from 'src/app/core/models/role.enum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WidgetCustomizerModalComponent } from 'src/app/ui/components/dashboard-components/widget-customizer-modal/widget-customizer-modal.component';
import { WidgetItem } from 'src/app/models/chart/widget-item';
import { WidgetType } from 'src/app/models/chart/widget-type';
import { WidgetService } from 'src/app/services/widget.service';
import { Subscription } from 'rxjs';
import { SelectItem } from 'src/app/core/models/responses/select-item';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [CustomCurrencyPipe, SubTypeDisplayPipe, CurrencyPipe ]
})
export class DashboardComponent implements OnInit, OnDestroy {
  branch: Branch = JSON.parse(localStorage.getItem('currentBranch'));
  Currency = Currency;
  widgets: SelectItem[];

  incomeBarChart: SelectItem = {selected: false}
  manufactureOrders: SelectItem = {selected: false}
  expenseBarChart: SelectItem = {selected: false}
  hasAssetsPieChart: SelectItem = {selected: false}
  hasAssetsNegativeBarChart: SelectItem = {selected: false}
  debtList: SelectItem = {selected: false}
  receivableList: SelectItem = {selected: false}
  agreedList: SelectItem = {selected: false}

  agreedFilter: ExchangeFilter = {
    branchId: this.branch.id,
    state: ExchangeState.Agreed,
  }
  isAdmin: boolean = false;
  user: ApplicationUser;
  Role = Role;
  widgets$: Subscription

  constructor(
    private applicationUserService: ApplicationUserService,
    private modalService: NgbModal,
    private widgetService: WidgetService,
    )
    {
    this.user = applicationUserService.currentUserValue;
    this.isAdmin = this.user.roles.includes(Role.Admin);
    }

  ngOnDestroy(): void {
    this.widgets$.unsubscribe();
  }

  ngOnInit(): void {
    this.widgetService.getUserWidgets().toPromise().then(res => {
      this.widgetService.widgetsSubject.next(res.data);
    })
    this.widgets$ = this.widgetService.widgetsSubject.subscribe(widgets => {
      this.incomeBarChart = widgets.find(p => p.group.name == WidgetType[WidgetType.IncomeBarChart]);
      this.manufactureOrders = widgets.find(p => p.group.name == WidgetType[WidgetType.ManufactureOrders]);
      this.expenseBarChart = widgets.find(p => p.group.name == WidgetType[WidgetType.ExpenseBarChart]);
      this.hasAssetsPieChart = widgets.find(p => p.group.name == WidgetType[WidgetType.HasAssetsPieChart]);
      this.hasAssetsNegativeBarChart = widgets.find(p => p.group.name == WidgetType[WidgetType.HasAssetsNegativeBarChart]);
      this.debtList = widgets.find(p => p.group.name == WidgetType[WidgetType.DebtList]);
      this.receivableList = widgets.find(p => p.group.name == WidgetType[WidgetType.ReceivableList]);
      this.agreedList = widgets.find(p => p.group.name == WidgetType[WidgetType.AgreedList]);
    })

  }



  openWidgetCustomizerModal(){
    const modalRef = this.modalService.open(WidgetCustomizerModalComponent);
  }





}
