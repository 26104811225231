import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountTransactionListComponent } from './account-transaction-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDatepickerModule, NgbDropdownModule, NgbNavModule, NgbPopoverModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { SimplebarAngularModule } from 'simplebar-angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { ComponentsModule as SharedComponentsModule } from 'src/app/shared/components/components.module';
import { RouterModule } from '@angular/router';
import { HistoryModule } from '../../application-users/history/history.module';
import { CoreModule } from 'src/app/core/core.module';
import { ListSpinnerComponent } from 'src/app/shared/components/list-spinner/list-spinner.component';


@NgModule({
  declarations: [
    AccountTransactionListComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    CoreModule,
    NgbDropdownModule,
    PipesModule,
    SharedComponentsModule,
    NgbNavModule,
    SimplebarAngularModule,
    NgSelectModule,
    FormsModule,
    NgbTypeaheadModule,
    NgbDatepickerModule,
    NgbPopoverModule,
    HistoryModule,
    SharedComponentsModule,
  ],
  exports: [
    AccountTransactionListComponent
  ]
})
export class AccountTransactionListModule { }
