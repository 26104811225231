import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountTransactionListModule } from './account-transaction-list/account-transaction-list.module';
import { AccountTransactionCreateModalModule } from './account-transaction-create-modal/account-transaction-create-modal.module';
import { AccountTransactionListMiniModule } from './account-transaction-list-mini/account-transaction-list-mini.module';
import { BranchExpensesExportModule } from './branch-expenses-export/branch-expenses-export.module';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AccountTransactionListModule,
    AccountTransactionCreateModalModule,
    AccountTransactionListMiniModule,
    BranchExpensesExportModule
  ]
})
export class AccountTransactionsModule { }
