import { Pipe, PipeTransform } from '@angular/core';
import { AccountTransactionReason } from 'src/app/models/enums/account-transaction-reason';

@Pipe({
  name: 'reasonType'
})
export class ReasonTypePipe implements PipeTransform {

  transform(value: AccountTransactionReason, ...args: number[]): string {
    
    switch (value) {
      case AccountTransactionReason.Deposit:
        return 'Gelen'
      case AccountTransactionReason.Withdraw:
        return 'Çıkan'
      case AccountTransactionReason.Exchange:
        return 'Alım/Satım'
      case AccountTransactionReason.ExchangeCancel:
        return 'Alım/Satım İptal'
      case AccountTransactionReason.BranchTransfer:
        return 'Şubeye Transfer'
      case AccountTransactionReason.BranchMoneyEntry:
        return 'Para Girişi'
      case AccountTransactionReason.BranchMoneyExit:
        return 'Para Çıkışı'
      case AccountTransactionReason.Collection:
        return 'Tahsilat'
      case AccountTransactionReason.Virman:
        return 'Virman'
      case AccountTransactionReason.Sales:
        return 'Perakende Satış'
      case AccountTransactionReason.ProductionCost:
        return 'Üretim'
      case AccountTransactionReason.RawMaterialPurchase:
        return 'Hammadde Satış'
      case AccountTransactionReason.ProductPurchase:
        return 'Ürün Tedariği'
        default:
        break;
    }
  }

}
