export enum CurrencySubTypes {
  Has,
  Carat22,
  Carat21,
  Carat20,
  Carat18,
  Carat14,
  Carat8,
  IngotYarim,
  Ingot1,
  IngotIkiBucuk,
  Ingot5,
  Ingot10,
  Ingot20,
  Ingot50,
  Ingot100,
  Ingot250,
  Ingot500,
  Ingot1000,
  Gram24 = 100,
  Gram22,
  YeniCeyrek,
  YeniYarim,
  YeniTam,
  YeniAta,
  YeniGremse,
  Resat,
  Resat5,
  EskiCeyrek,
  EskiYarim,
  EskiAta,
  EskiTam,
  EskiGremse,
  Ceyrek,
  Yarim,
  Tam,
  Gremse,
  Ata,
  Ata5,
  Ingot = 200,
  Granular,
  YeniAta5 = 300,
  AtaGremse,
  AtaYarim,
  AtaCeyrek,
  ResatGremse,
  ResatYarim,
  ResatCeyrek,
  Ziynet5,
  EskiAta5,
  Aziz,
  HamitGremse,
  Hamit5,
  Adli,
  Hamit,
  EskiPara,
  Bilezik14,
  Bilezik18,
  Bilezik22,
  Armali5li,
  JPY = 400,
  GBP,
  DKK,
  SEK,
  NOK,
  CHF,
  AUD,
  CAD,
  SAR,
  RUB,
  BGN,
  AED,
  QAR,
  CNY,
  AZN,
  KWD,
  ILS,
  MAD,
  JOD,
  OMR,
  XPT,
  XPD,
  Platin,
  Palladium,
  GEL,
}

export const GoldSubTypes: CurrencySubTypes[] = [
  CurrencySubTypes.Has,
  CurrencySubTypes.Carat22,
  CurrencySubTypes.Carat21,
  CurrencySubTypes.Carat20,
  CurrencySubTypes.Carat18,
  CurrencySubTypes.Carat14,
  CurrencySubTypes.Carat8,
];


export const AccessoriesSubTypes: CurrencySubTypes[] = [
  CurrencySubTypes.Gram24,
  CurrencySubTypes.Gram22,
  CurrencySubTypes.YeniCeyrek,
  CurrencySubTypes.YeniYarim,
  CurrencySubTypes.YeniTam,
  CurrencySubTypes.YeniAta,
  CurrencySubTypes.YeniGremse,
  CurrencySubTypes.Resat,
  CurrencySubTypes.Resat5,
  CurrencySubTypes.EskiCeyrek,
  CurrencySubTypes.EskiYarim,
  CurrencySubTypes.EskiAta,
  CurrencySubTypes.EskiTam,
  CurrencySubTypes.EskiGremse,
];
export const OtherAccessoriesSubTypes: CurrencySubTypes[] = [
  CurrencySubTypes.YeniAta5,
  CurrencySubTypes.AtaGremse,
  CurrencySubTypes.AtaYarim,
  CurrencySubTypes.AtaCeyrek,
  CurrencySubTypes.ResatGremse,
  CurrencySubTypes.ResatYarim,
  CurrencySubTypes.ResatCeyrek,
  CurrencySubTypes.Ziynet5,
  CurrencySubTypes.EskiAta5,
  CurrencySubTypes.Aziz,
  CurrencySubTypes.HamitGremse,
  CurrencySubTypes.Hamit5,
  CurrencySubTypes.Adli,
  CurrencySubTypes.Hamit,
  CurrencySubTypes.EskiPara,
  CurrencySubTypes.Bilezik14,
  CurrencySubTypes.Bilezik18,
  CurrencySubTypes.Bilezik22,
  CurrencySubTypes.Armali5li,
];
export const SilverSubTypes: CurrencySubTypes[] = [
  CurrencySubTypes.Granular,
  CurrencySubTypes.Ingot,
];
export const OtherCurrencyList: CurrencySubTypes[] = [
  CurrencySubTypes.JPY,
  CurrencySubTypes.GBP,
  CurrencySubTypes.DKK,
  CurrencySubTypes.SEK,
  CurrencySubTypes.NOK,
  CurrencySubTypes.CHF,
  CurrencySubTypes.AUD,
  CurrencySubTypes.CAD,
  CurrencySubTypes.SAR,
  CurrencySubTypes.RUB,
  CurrencySubTypes.BGN,
  CurrencySubTypes.AED,
  CurrencySubTypes.QAR,
  CurrencySubTypes.CNY,
  CurrencySubTypes.AZN,
  CurrencySubTypes.KWD,
  CurrencySubTypes.ILS,
  CurrencySubTypes.MAD,
  CurrencySubTypes.JOD,
  CurrencySubTypes.OMR,
  CurrencySubTypes.GEL,
];


