import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerBalanceComponent } from './customer-balance.component';
import { ComponentsModule as SharedComponentsModule } from 'src/app/shared/components/components.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { CollectionCreateModule } from '../../collections/collection-create/collection-create.module';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [
    CustomerBalanceComponent
  ],
  imports: [
    CommonModule,
    PipesModule,
    SharedComponentsModule,
    RouterModule,
    FormsModule,
    NgbTypeaheadModule,
    ReactiveFormsModule,
    CollectionCreateModule
  ],
  exports: [CustomerBalanceComponent]
})
export class CustomerBalanceModule { }
