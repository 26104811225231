import { style } from "@angular/animations";
import { AfterContentInit, AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { ApexDataLabels, ChartComponent } from "ng-apexcharts";

import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart
} from "ng-apexcharts";
import { Role } from "src/app/core/models/role.enum";
import { Branch } from "src/app/models/branch/branch";
import { ChartService } from "src/app/services/custom-chart.service";
import { BaseComponent } from "src/app/shared/components/base/base.component";
import { CurrencyColorCodePipe } from "src/app/shared/pipes/currency-color-code.pipe";
import { CustomCurrencyPipe } from "src/app/shared/pipes/custom-currency.pipe";

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  colors: any;
};
@Component({
  selector: 'app-branch-balance-pie-chart',
  templateUrl: './branch-balance-pie-chart.component.html',
  styleUrls: ['./branch-balance-pie-chart.component.scss'],
  providers: [CustomCurrencyPipe, CurrencyColorCodePipe]
})
export class BranchBalancePieChartComponent extends BaseComponent {
 @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  options;
  currentBranch: Branch = JSON.parse(localStorage.getItem("currentBranch"));
  initState: boolean;
  constructor(private service: ChartService, private currencyPipe: CustomCurrencyPipe, private colorCode: CurrencyColorCodePipe) {
    super();
    this.options = {
      series: [],
      chart: {
        redrawOnParentResize: true,
        redrawOnWindowResize: true,
        width: 380,
        type: "pie"
      },
      colors: [],
      labels: [],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ]
    };
  }

   loadData(){
    this.options = JSON.parse(JSON.stringify(this.options));
    this.options.series = [];
    this.options.colors = [];
    this.options.labels = [];
    this.service.branchBalanceOfHas(this.currentBranch.id).toPromise().then(res => {
      res.data.labels.map(p => {
        let currency = this.currencyPipe.transform(p);
        let color = this.colorCode.transform(p);
        this.options.labels.push(currency)
        this.options.colors.push(color)
      } );
      res.data.series.map(p => this.options.series.push(p));
      this.chartOptions = this.options;
    });
  }
}
