import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, EMPTY, of, timer, merge } from 'rxjs';
import { catchError, map, mergeMap, retryWhen, switchMap, tap } from 'rxjs/operators';
import { ApplicationUserService } from '../services/application-user.service';
import { AlertService } from 'src/app/services/alert.service';
import { HttpClient } from '@microsoft/signalr';
import { ApplicationUser } from 'src/app/models/application-user/application-user';
import { Router } from '@angular/router';
import { isNullOrUndefined } from './common-functions';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private alert: AlertService , private service: ApplicationUserService, private router: Router) { }
    intercept(request: HttpRequest<any>, next: HttpHandler){
        return next.handle(request).pipe(catchError((err: HttpErrorResponse) => {
            switch(err.status){
                case 403:
                    this.alert.showError('Bu işlemi yapmak için yetkiniz yok.')
                    break;
                case 400:
                    if (err.error.isNeedConfirm) 
                        return throwError(err);
                    else{
                        if (this.sessionNeedEnd(err.error.errorMessage)){
                            this.service.logout();
                            this.router.navigate(['/login']);
                        }
                        else{
                            this.alert.showValidation(err.error.errorMessage)
                        }
                    }
                    break;
                case 404:
                    this.alert.showError(err.error)
                    break;
                case 406:
                    return this.service.refreshToken(this.service.currentUserValue?.refreshToken)
                        .pipe(
                        map(
                        res => {
                            if (res.data) {
                                let user: ApplicationUser = JSON.parse(localStorage.getItem('currentUser'));
                                user.token = res.data.accessToken;
                                user.refreshToken = res.data.refreshToken;
                                localStorage.setItem('currentUser', JSON.stringify(user));
                                this.service.currentUserSubject.next(user);
                                const clone = request.clone({
                                    headers: request.headers.set('authToken', res.data.accessToken)
                                });
                                return next.handle(clone)    
                            }
                        }
                        ),
                        mergeMap(res => res)
                    );
                case 500:
                    this.alert.showError(err.error.message);
                    break;
            }

            if (err.error.data != null)
                return throwError(err.error);

            const error = err.error.message || err.error.errorMessage || err.message;
            return throwError(error);
        }));
    }

    

    sessionNeedEnd(message: any): boolean{
        for (var k in message){
            if (message.hasOwnProperty(k)) {
                if(k && k == 'sessionNeedEnd')
                    return true;
                else
                    return false;
            }
        }
    }

}
