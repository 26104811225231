<div #cardDebt class="card">
    <div [ngClass]="cardHeaderClass">
        <h5 class="text-white text-center mt-2" *ngIf="isDebtList">Borçlu Listesi</h5>
        <h5 class="text-white text-center mt-2" *ngIf="!isDebtList">Alacaklı Listesi</h5>
    </div>
    <div class="p-3 d-flex">
        <div class="input-group me-2">
            <input type="text" placeholder="Müşteri Adı" class="form-control form-control-sm" (keyup)="filterToList()"
                [(ngModel)]="filter.customerName">
        </div>
        <div class="input-group me-2">
            <select class="form-control form-control-sm" [(ngModel)]="filter.currency" (change)="setSubTypes()">
                <option [ngValue]="defaultValue">Birim</option>
                <option *ngFor="let currency of currencyList" [ngValue]="currency"> {{currency | customCurrency}}
                </option>
            </select>
        </div>
        <div class="input-group me-2">
            <select class="form-control form-control-sm" [(ngModel)]="filter.subType" (change)="filterToList()">
                <option [ngValue]="defaultValue">Alt Birim</option>
                <option *ngFor="let subType of subTypeList" [ngValue]="subType"> {{subType | subTypeDisplay}} </option>
            </select>
        </div>
    </div>

    <div class="vertical-container" *ngIf="filterableList.length != 0">
        <div class="scrollbar" id="scrollbar1">
            <div class="list-group" *ngIf="showedList.length > 0">
                <div *ngFor="let listItem of showedList; let headerIndex = index">
                    <div class="list-group-item bg-background" *ngIf="headerIndex % 2 == 0">
                        <a routerLink="/customer/detail/{{listItem.customerId}}" href="javascript: void(0);"
                            class="text-link" triggers="hover" ngbTooltip="Müşteriye git"><i
                                class="fas fa-user-tie me-2"></i> {{listItem.customerName}}</a>
                    </div>
                    <div class="list-group-item" *ngIf="headerIndex % 2 == 1">
                        <a routerLink="/customer/detail/{{listItem.customerId}}" href="javascript: void(0);"
                            class="text-link" triggers="hover" ngbTooltip="Müşteriye git"><i
                                class="fas fa-user-tie me-2"></i> {{listItem.customerName}}</a>
                    </div>
                    <ng-container *ngFor="let item of listItem.customerBalances">
                        <div class="list-group-item d-flex justify-content-between bg-background mb-0"
                            *ngIf="headerIndex % 2 == 0">
                            <div class="d-flex gap-2 align-items-center bg-background">
                                <span style="min-width:100px"
                                    class="fs-6 badge badge rounded-pill d-block p-2 badge-soft-danger"
                                    *ngIf="isDebtList">{{item.balance * -1 | currencyAmount: item.currency }}</span>
                                <span style="min-width:100px"
                                    class="fs-6 badge badge rounded-pill d-block p-2 badge-soft-success"
                                    *ngIf="!isDebtList">{{item.balance | currencyAmount: item.currency }}</span>
                                <span *ngIf="item.subType != undefined">{{item.subType | subTypeDisplay}} - ({{item.currency |
                                    customCurrency }}) </span> <span *ngIf="item.subType == undefined">{{item.currency |
                                    customCurrency}}</span>
                            </div>
                            <div class="text-end d-flex bg-background">
                                <button type="button" (click)="openFastCollectionModal(item)" *ngIf="isDebtList"
                                    class="ms-2 btn btn-sm btn-outline-danger"><i class="fas fa-receipt me-1"></i>
                                    Tahsilat </button>
                                <button type="button" (click)="openFastCollectionModal(item)" *ngIf="!isDebtList"
                                    class="ms-2 btn btn-sm btn-outline-success"><i class="fas fa-receipt me-1"></i>
                                    Ödeme </button>
                            </div>
                        </div>
                        <div class="list-group-item d-flex justify-content-between mb-0" *ngIf="headerIndex % 2 == 1">
                            <div class="d-flex gap-2 align-items-center">
                                <span style="min-width:100px"
                                    class="fs-6 badge badge rounded-pill d-block p-2 badge-soft-danger"
                                    *ngIf="isDebtList">{{item.balance * -1 | currencyAmount: item.currency }}</span>
                                <span style="min-width:100px"
                                    class="fs-6 badge badge rounded-pill d-block p-2 badge-soft-success"
                                    *ngIf="!isDebtList">{{item.balance | currencyAmount: item.currency }}</span>
                                <span *ngIf="item.subType != undefined">{{item.subType | subTypeDisplay}} - ({{item.currency |
                                    customCurrency }}) </span> <span *ngIf="item.subType == undefined">{{item.currency |
                                    customCurrency}}</span>
                            </div>
                            <div class="text-end d-flex" *ngIf="headerIndex % 2 == 1">
                                <button type="button" (click)="openFastCollectionModal(item)" *ngIf="isDebtList"
                                    class="ms-2 btn btn-sm btn-outline-danger"><i class="fas fa-receipt me-1"></i>
                                    Tahsilat </button>
                                <button type="button" (click)="openFastCollectionModal(item)" *ngIf="!isDebtList"
                                    class="ms-2 btn btn-sm btn-outline-success"><i class="fas fa-receipt me-1"></i>
                                    Ödeme </button>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <div class="card-body" *ngIf="filterableList.length == 0">
        <div class="text-muted text-center"><i class="fa fa-exclamation-circle me-2"></i>
            <span *ngIf="isDebtList">Borçlu kaydı bulunamadı.</span>
            <span *ngIf="!isDebtList">Alacaklı kaydı bulunamadı.</span>
        </div>
    </div>
</div>
