import { KeyValuePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CurrencyCastEnumPipe } from 'src/app/shared/pipes/currency-cast-enum.pipe';
import { Branch } from 'src/app/models/branch/branch';
import { Customer } from 'src/app/models/customer/customer';
import { Currency } from 'src/app/models/enums/currency';
import { ExchangeBalace } from 'src/app/models/exchange/exchange-balance';
import { ExchangeItem } from 'src/app/models/exchange/exchange-item-request';
import { ExchangeItemFilter } from 'src/app/models/filters/exchange-item-filter';
import { SummaryResponse } from 'src/app/models/receipts/exchange-summary';
import { SummaryInfoItem } from 'src/app/models/receipts/summary-info-item';
import { ExchangeService } from 'src/app/services/exchange.service';
import { BaseResponse } from 'src/app/core/models/responses/base-response';
import { finalize, map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-exchange-summary',
  templateUrl: './exchange-summary.component.html',
  styleUrls: ['./exchange-summary.component.scss'],
  providers: [KeyValuePipe, CurrencyCastEnumPipe, ]
})
export class ExchangeSummaryComponent implements OnInit {
  currentBranch: Branch = JSON.parse(localStorage.getItem('currentBranch'))
  summaryItems: SummaryInfoItem[] = [];
  cart: ExchangeItem[] = [];
  balances: ExchangeBalace = {exchanges: [], payments: []};
  filter: ExchangeItemFilter = {}
  Currency = Currency
  operationDate;
  summary: SummaryResponse = {}
  groupId:string;
  customer: Customer = {};
  firstBalanceCustomer: Customer = {};
  lastBalanceCustomer: Customer = {};
  isLoaded: boolean = false;
  summary$: Observable<BaseResponse<SummaryResponse>>;
  constructor(
    private route: ActivatedRoute,
    private exchangeService: ExchangeService,
    )
    {
      this.groupId = this.route.snapshot.params["id"];
      this.filter.groupId = this.groupId;
    }

  ngOnInit() {
    this.summary$ = this.getExchangeSummaryData();
}

getExchangeSummaryData(): Observable<BaseResponse<SummaryResponse>> {
  return this.exchangeService.getSummary(this.filter.groupId).pipe(
    tap((res) => {
      this.cart = res.data.exchangeItems;
      this.summaryItems = res.data.summaryInfoItemList;
      this.operationDate = res.data.exchangeItems[0].agreedDate;
      this.cart.forEach(p => {
        this.calculateExchangeBalances(p)
      });
      this.isLoaded = true;
    }),
    finalize(() => {
      this.print();
    })
  );
}

  print(){
    setTimeout(() => {
      window.print();
      window.onafterprint = window.close;
    }, 500);
  }


  calculateExchangeBalances(item: ExchangeItem){
    if (this.balances.exchanges.length > 0) {

      let foundedItem = this.balances.exchanges.find(p => p.currency == item.exchangeCurrency && p.subType == item.exchangeSubType)
      if (foundedItem) {
        foundedItem.balance += item.exchangeAmount
      }
      else{
        this.balances.exchanges.push({currency: item.exchangeCurrency, subType: item.exchangeSubType, milyem: item.exchangeMilyem, balance: item.exchangeAmount})
      }
    }

      if (this.balances.exchanges.length == 0){
        this.balances.exchanges.push({currency: item.exchangeCurrency, subType: item.exchangeSubType, milyem: item.exchangeMilyem, balance: item.exchangeAmount})
      }

      if (this.balances.payments.length > 0) {

      let foundedItem = this.balances.payments.find(p => p.currency == item.paymentCurrency && p.subType == item.paymentSubType)
      if (foundedItem) {
        foundedItem.balance += item.paymentAmount
      }
      else {
        this.balances.payments.push({currency: item.paymentCurrency, subType: item.paymentSubType, milyem: item.paymentMilyem, balance: item.paymentAmount})
      }
      }

      if (this.balances.payments.length == 0) {
        this.balances.payments.push({currency: item.paymentCurrency, subType: item.paymentSubType, milyem: item.paymentMilyem, balance: item.paymentAmount})
      }
  }

  transformDate(date: Date): any {
    return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' ' + date.getHours() + ':' + date.getMinutes() + ':'  + date.getSeconds()
  }

}
