import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SelectItem } from "src/app/core/models/responses/select-item";
import { AlertService } from "src/app/services/alert.service";
import { DataService } from "src/app/services/data.service";
import { CurrencyAmountPipe } from "src/app/shared/pipes/currency-amount.pipe";
import { CustomCurrencyPipe } from "src/app/shared/pipes/custom-currency.pipe";
import { SubTypeDisplayPipe } from "src/app/shared/pipes/sub-type-display.pipe";
import { CurrencyAmount } from "src/app/models/account-transaction/currency-amount";
import { Branch } from "src/app/models/branch/branch";
import { Collection } from "src/app/models/collection/collection";
import { CustomerBalanceDTO } from "src/app/models/customer/customerBalanceDTO";
import { AccountTransactionType } from "src/app/models/enums/account-transaction-type";
import { Currency } from "src/app/models/enums/currency";
import {
  CurrencySubTypes,
  SilverSubTypes,
} from "src/app/models/enums/currency-sub-types";
import { CustomerService } from "src/app/services/customer.service";
import { AccountTransactionService } from "src/app/services/account-transaction.service";
import { WidgetService } from "src/app/services/widget.service";
import { CollectionItem } from "src/app/models/collection/collection-item";

@Component({
  selector: "app-fast-collection-modal",
  templateUrl: "./fast-collection-modal.component.html",
  styleUrls: ["./fast-collection-modal.component.scss"],
  providers: [CustomCurrencyPipe, SubTypeDisplayPipe, CurrencyAmountPipe],
})
export class FastCollectionModalComponent implements OnInit {
  @Input() modalContent: CustomerBalanceDTO;
  @Input() isDebtCollection: boolean;

  branch: Branch = JSON.parse(localStorage.getItem("currentBranch"));
  Currency = Currency;
  Subtype = CurrencySubTypes;
  silverSubTypes = SilverSubTypes;
  subTypes = null;
  collection: Collection = { itemList: [] };
  caseList: SelectItem[] = [];
  modalTitleClass;
  buttonClass;
  modalBodyText;

  constructor(
    private alertService: AlertService,
    private customerService: CustomerService,
    private accountTransactonService: AccountTransactionService,
    private dataService: DataService,
    private modalService: NgbModal,
    private widgetService: WidgetService,
    private currencyPipe: CustomCurrencyPipe,
    private subtypePipe: SubTypeDisplayPipe,
    private amountPipe: CurrencyAmountPipe,
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit(): void {
    this.prepareCollection();
    let currencySubTypeText: string;
    let currency = this.currencyPipe.transform(this.modalContent.currency);
    let subType = this.subtypePipe.transform(this.modalContent.subType);
    if (this.modalContent.subType != undefined)
      currencySubTypeText = subType + " - " + "(" + currency + ")";
    else currencySubTypeText = currency;

    if (this.isDebtCollection) {
      this.modalContent.balance *= -1;
      this.modalTitleClass = "modal-title text-danger";
      this.buttonClass = "btn btn-outline-danger";
      this.modalBodyText = `${
        this.modalContent.name
      } Müşterisinden ${this.amountPipe.transform(
        this.modalContent.balance,
        this.modalContent.currency
      )}  ${currencySubTypeText} tahsilat yapılacak.`;
    } else {
      this.modalTitleClass = "modal-title text-success";
      this.buttonClass = "btn btn-outline-success";
      this.modalBodyText = `${this.modalContent.name} Müşterisine
      ${this.amountPipe.transform(
        this.modalContent.balance,
        this.modalContent.currency
      )} ${currencySubTypeText} ödeme yapılacak.`;
    }
    this.dataService
      .getCase({ branchId: this.branch.id })
      .toPromise()
      .then((res) => {
        this.caseList = res.data;
        this.collection.itemList[0].caseId = this.caseList[0].value;
      });
  }

  prepareCollection() {
    this.collection.customerId = this.modalContent.id;
    let _temp: CollectionItem = {};
    _temp.type = this.isDebtCollection
      ? AccountTransactionType.Receivable
      : AccountTransactionType.Debt;
    _temp.amount =
      this.modalContent.balance < 0
        ? this.modalContent.balance * -1
        : this.modalContent.balance;
    _temp.currency = this.modalContent.currency;
    _temp.subType = this.modalContent.subType;
    _temp.description = this.modalContent.description;
    if (this.modalContent.subType != undefined) {
      _temp.subType = parseInt(this.modalContent.subType.toString());
    }

    if (_temp.currency == Currency.Gold) {
      _temp.milyem = 1000;
      _temp.subType = this.Subtype.Has;
    } else {
      _temp.milyem = null;
    }

    this.collection.itemList = [];
    this.collection.itemList.push(_temp);
  }

  submitCollection() {
    if (this.isValid) {
      this.customerService
        .createCollection(this.collection)
        .toPromise()
        .then((res) => {
          if (res.isSuccess) {
            this.modalService.dismissAll();
            this.alertService.showSuccess();
            this.accountTransactonService.refreshIndex.next();
            this.widgetService.refreshCustomerDebtReceivableWidget.next();
          }
        });
    }
  }

  isValid() {
    let messages = [];
    if (this.collection.itemList && !this.collection.itemList[0].caseId)
      messages.push("Lütfen kasa seçiniz.");
    if (messages.length > 0) {
      this.alertService.showErrorsToast(messages);
      return false;
    }
    return true;
  }
}
