import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { AccountTransaction } from 'src/app/models/account-transaction/account-transaction';
import { Customer } from 'src/app/models/customer/customer';
import { CustomerBalanceRequest } from 'src/app/models/customer/customer-balance-request';
import { Currency } from 'src/app/models/enums/currency';
import { AccountTransactionCreateModalComponent } from '../../account-transactions/account-transaction-create-modal/account-transaction-create-modal.component';
import { CollectionCreateComponent } from '../../collections/collection-create/collection-create.component';
import { Output, EventEmitter } from '@angular/core';
import { CustomerService } from 'src/app/services/customer.service';

@Component({
  selector: 'app-customer-balance',
  templateUrl: './customer-balance.component.html',
  styleUrls: ['./customer-balance.component.scss']
})
export class CustomerBalanceComponent implements OnInit {
  @Input() customerId?: number;
  @Input() _customer?: Customer;
  @Input() transaction?: AccountTransaction;
  @Output() customerTransfer = new EventEmitter<Customer>();
  @Input() showCustomerName?: false;

  currentBranch = JSON.parse(localStorage.getItem('currentBranch'));
  balanceRequest: CustomerBalanceRequest = {branchId: this.currentBranch.id};
  customer: Customer
  currencyEnum = Currency
  routeSubscription: Subscription
  constructor(
    private service: CustomerService,
    private modalService: NgbModal
    ) { }

  ngOnInit(): void {
    if(this._customer){
      this.customer = this._customer;
    }
    if(this.customerId){
      this.findCustomer()
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes._customer && !changes._customer.firstChange){
      this.customer = changes._customer.currentValue;
    }
    if(changes.transaction && !changes.transaction.firstChange){
      this.findCustomer(changes.transaction.currentValue)
    }
  }

  findCustomer(transaction?: AccountTransaction){
    if (transaction) {
      this.balanceRequest.id = this.customer.id;
      this.balanceRequest.endDate = transaction.date;
      this.service.getBalanceByDate(this.balanceRequest).toPromise().then(res => {
        if (res.isSuccess) {
          this.customer = JSON.parse(JSON.stringify(res.data));
          this.customerTransfer.emit(this.customer);
        }
      })
    }
    else{
      this.service.findByBranch(this.customer.id, this.currentBranch.id).toPromise().then(res => {
        // const _balanceList = res.data.mergedBalanceList.filter(p => p.value != 0)
        this.customer = JSON.parse(JSON.stringify(res.data));
        // this.customer.mergedBalanceList = [];
        // this.customer.mergedBalanceList = _balanceList;
      })
    }

  }

  openCollectionCreateModal(){
    const modalRef = this.modalService.open(CollectionCreateComponent);
    modalRef.componentInstance.customerPreloaded = this.customer;

  }

  goToLink(url: string){
    window.open(url, "_blank");
}

openCustomerTransactionCreateModal(){
  const modalRef = this.modalService.open(AccountTransactionCreateModalComponent);
  modalRef.componentInstance.customerPreloaded =  this.customer;
  modalRef.componentInstance.isCustomerTransaction = true;
  }


}
