import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParityMultipleCreateEditModalComponent } from './parity-multiple-create-edit-modal.component';
import { NgbModalModule, NgbTooltipModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';



@NgModule({
  declarations: [
    ParityMultipleCreateEditModalComponent
  ],
  imports: [
    CommonModule,
    NgbModalModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    NgbTypeaheadModule,
    NgbTooltipModule
  ],
  exports: [ParityMultipleCreateEditModalComponent]
})
export class ParityMultipleCreateEditModalModule { }
