<app-role-based [roles]="[Role.User]" [content]="content" (initState)="loadData()"></app-role-based>
<ng-template #content>
  <div class="card">
    <div class="card-body">
        <div class="card-title">
            Varlıkların Dağılımı
        </div>
        <p class="text-secondary">Müşteri Bakiyeleri Dahil Değil ( Has Cinsinden)</p>
        <div class="mb-4">
          <div id="chart" *ngIf="chartOptions">
            <apx-chart
              [series]="chartOptions.series"
              [chart]="chartOptions.chart"
              [labels]="chartOptions.labels"
              [responsive]="chartOptions.responsive"
              [colors]="chartOptions.colors"
            ></apx-chart>
          </div>
        </div>
    </div>
  </div>
</ng-template>

