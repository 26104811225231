import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OperationsRoutingModule } from './operations-routing.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    OperationsRoutingModule,
  ]
})
export class OperationsModule { }
