import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable } from "rxjs";
import { debounceTime, distinctUntilChanged, switchMap } from "rxjs/operators";
import { SelectItem } from "src/app/core/models/responses/select-item";
import { Branch } from "src/app/models/branch/branch";
import { Customer } from "src/app/models/customer/customer";
import { DataService } from "src/app/services/data.service";
import { Collection } from "src/app/models/collection/collection";
import { Currency, AllCurrencyList } from "src/app/models/enums/currency";
import { CurrencySubTypes } from "src/app/models/enums/currency-sub-types";
import { CaseFilter } from "src/app/models/filters/case-filter";
import { AccountTransactionType } from "src/app/models/enums/account-transaction-type";
import { AlertService } from "src/app/services/alert.service";
import { AccountTransactionService } from "src/app/services/account-transaction.service";
import { CustomerService } from "src/app/services/customer.service";
import { ApplicationUser } from "src/app/models/application-user/application-user";
import { CollectionItem } from "src/app/models/collection/collection-item";
import { CaseSelectItem } from "src/app/models/case/case-select-list-item";
import {
  getCurrencySubTypeItem,
  getMilyem,
} from "src/app/core/helpers/common-functions";

@Component({
  selector: "app-collection-create",
  templateUrl: "./collection-create.component.html",
  styleUrls: ["./collection-create.component.scss"],
})
export class CollectionCreateComponent implements OnInit {
  @Input() currencyPreloaded?: Currency = null;
  @Input() subTypePreloaded?: CurrencySubTypes = null;
  @Input() customerPreloaded?: Customer = null;
  @Input() branchPreloaded?: Branch = null;

  currentBranch = JSON.parse(localStorage.getItem("currentBranch"));
  user: ApplicationUser = JSON.parse(localStorage.getItem("currentUser"));
  closeResult = "";
  print = true;
  formModel = {
    caseId: null,
    type: null,
    customerId: null,
    currency: Currency.Tl,
    subType: null,
    milyem: null,
    amount: null,
    description: null,
  };
  currencyList = AllCurrencyList;
  subTypes: CurrencySubTypes[] = [];
  selectItemModel: SelectItem[];
  branch: Branch = JSON.parse(localStorage.getItem("currentBranch"));
  request: Collection = {
    itemList: [],
  };

  cart: CollectionItem[] = [];
  Currency = Currency;
  caseFilter: CaseFilter = {
    branchId: this.currentBranch.id,
    applicationUserId: this.user.id,
  };
  caseList: SelectItem[];
  selectedCurrency: Currency;
  currencyEnum = Currency;
  isCollection: boolean = false;
  type: AccountTransactionType;
  isDisabled: boolean = false;
  defaultCase: CaseSelectItem;
  constructor(
    private modalService: NgbModal,
    public service: CustomerService,
    private transactionService: AccountTransactionService,
    private alertService: AlertService,
    private dataService: DataService,
    public activeModal: NgbActiveModal,
  ) {}

  ngOnInit(): void {
    if (this.currencyPreloaded != null) {
      if (this.subTypePreloaded == null) {
        this.formModel.currency = this.currencyPreloaded;
        this.currencyChanged();
      }
    }
    this.loadCases();
  }
  loadCases() {
    this.dataService
      .getCase(this.caseFilter)
      .toPromise()
      .then((res) => {
        this.caseList = res.data;
        this.defaultCase = res.data.find((p) => p.isDefault == true);
        this.formModel.caseId = this.defaultCase
          ? this.defaultCase.value
          : res.data[0].value;
      });
  }
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term) =>
        term
          ? this.dataService
              .getCustomer({
                branchId: this.branch.id,
                name: term,
                isActive: true,
              })
              .toPromise()
              .then((res) => res.data)
          : []
      )
    );

  selectedCustomer(customer) {
    this.selectItemModel = null;
    this.request.customerId = customer.item.value;
  }

  resultFormatBandListValue(value: SelectItem) {
    return value.text;
  }
  inputFormatBandListValue(value: SelectItem) {
    if (value.text) return value.text;
    return value;
  }

  // get f(){
  //   return this.form.controls;
  // }

  currencyChanged() {
    let item = getCurrencySubTypeItem(this.formModel.currency);
    this.subTypes = item.subtypes;
    this.formModel.subType = item.default;
    this.formModel.milyem = item.milyem;
  }

  subTypeChanged() {
    this.formModel.milyem = getMilyem(this.formModel.subType);
  }

  submit() {
    if (this.customerPreloaded)
      this.request.customerId = this.customerPreloaded.id;

    if (this.cart.length === 0) this.addToCart();

    if (this.cart.length > 0) {
      this.request.itemList = JSON.parse(JSON.stringify(this.cart));

      this.isDisabled = true;
      this.service
        .createCollection(this.request)
        .toPromise()
        .then((res) => {
          this.isDisabled = false;
          if (res.isSuccess) {
            this.modalService.dismissAll();
            this.alertService.showSuccess();
            this.transactionService.refreshIndex.next();
            this.service.refreshIndex.next();
            if (this.print)
              this.goToReceipt(this.request.customerId, res.data.groupId);
          }
          this.formModel = {
            caseId: null,
            type: null,
            customerId: null,
            currency: Currency.Tl,
            subType: null,
            milyem: null,
            amount: null,
            description: null,
          };
          this.subTypes = [];
        })
        .catch((err) => (this.isDisabled = false));
    }
  }

  delete(item) {
    this.cart = this.cart.filter((p) => p != item);
  }

  addToCart() {
    if (this.isValid()) {
      let cartItem: CollectionItem = {
        amount: this.formModel.amount,
        milyem: this.formModel.milyem,
        type: this.type,
        description: this.formModel.description,
        caseId: parseInt(this.formModel.caseId.toString()),
        caseName: this.caseList.find((p) => p.value == this.formModel.caseId)
          .text,
      };

      if (this.currencyPreloaded == null) {
        cartItem.currency = parseInt(String(this.formModel.currency));
        cartItem.subType =
          this.formModel.subType != undefined
            ? parseInt(String(this.formModel.subType))
            : null;
      } else {
        cartItem.currency = this.currencyPreloaded;
        if (this.subTypePreloaded == null)
          cartItem.subType = parseInt(String(this.formModel.subType));
      }
      this.cart.push(cartItem);
      this.formModel.currency = Currency.Tl;
      this.formModel.amount = null;
      this.formModel.subType = null;
      this.formModel.milyem = null;
      this.formModel.description = null;
      this.formModel.caseId = null;
      this.formModel.caseId = this.defaultCase
        ? this.defaultCase.value
        : this.caseList[0].value;

      this.subTypes = [];
    }
  }

  goToReceipt(customerId, groupId) {
    window.open(
      "/receipts/customer-balance?id=" + customerId + "&groupId=" + groupId,
      "_blank"
    );
  }

  isValid(): boolean {
    let messages: string[] = [];
    if (this.type != 0 && this.type != 1)
      messages.push("Lütfen işlem tipini seçiniz.");
    if (!this.formModel.amount) messages.push("Lütfen miktar giriniz.");

    if (messages.length > 0) {
      this.alertService.showErrorsToast(messages);
      return false;
    }
    return true;
  }
}
