import { DashboardsModule } from './dashboards/dashboards.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import interactionPlugin from '@fullcalendar/interaction'; // a plugin
import bootstrapPlugin from "@fullcalendar/bootstrap";
import { PagesRoutingModule } from './pages-routing.module';
import { CaseModule } from './case/case.module';
import { ExchangeModule } from './exchange/exchange.module';
import { OperationsModule } from './operations/operations.module';
import { RetailModule } from './retail/retail.module';
import { ManufactureModule } from './manufacture/manufacture.module';
import { SpecificationsModule } from './specifications/specifications.module';

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin,
  bootstrapPlugin
]);

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    PagesRoutingModule,
    DashboardsModule,
    CaseModule,
    ExchangeModule,
    OperationsModule,
    RetailModule,
    ManufactureModule,
    SpecificationsModule
  ],
  providers: []
})
export class PagesModule { }
