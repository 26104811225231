import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: 'product', loadChildren: () => import('./product/product.module').then(m => m.ProductModule) },
  { path: 'fast-selling', loadChildren: () => import('./fast-selling/fast-selling.module').then(m=>m.FastSellingModule)},
  { path: 'consignment', loadChildren: () => import('./consignment/consignment.module').then(m=>m.ConsignmentModule)},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RetailRoutingModule { }
