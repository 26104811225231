import { ApplicationUser } from "src/app/models/application-user/application-user";
import { Role } from "../models/role.enum";
import { Roles } from "../models/roles";
import { Currency } from "src/app/models/enums/currency";
import {
  AccessoriesSubTypes,
  CurrencySubTypes,
  GoldSubTypes,
  OtherAccessoriesSubTypes,
  OtherCurrencyList,
  SilverSubTypes,
} from "src/app/models/enums/currency-sub-types";
import { CurrencySubTypeItem } from "src/app/models/currency/currency-subtype-item";

export function isNullOrUndefined(value: any): boolean {
  if (value == null || value == undefined) return true;
  else return false;
}

export function isEmpty(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export function enumToNumber(value: any): number {
  value = !isNullOrUndefined(value) ? parseInt(value.toString()) : null;
  return value;
}

export function getUserRoleList(user: ApplicationUser) {
  const list = new Array();
  if (user.role & Roles.User) this.list.push(Number(Role.User));

  if (user.role & Roles.Retail) this.list.push(Role.Retail);

  if (user.role & Roles.Wholesale) this.list.push(Role.Wholesale);

  if (user.role & Roles.Manufacturing) this.list.push(Role.Manufacturing);

  if (user.role & Roles.Admin) this.list.push(Role.Admin);

  if (user.role & Roles.SuperAdmin) this.list.push(Role.SuperAdmin);

  return this.list;
}

export function hasSubType(currency: Currency): boolean {
  currency = parseInt(currency.toString());
  let result = false;
  if (
    currency != Currency.Tl &&
    currency != Currency.Euro &&
    currency != Currency.Usd
  )
    result = true;
  return result;
}

export function getMisMatched(firstArray, secondArray) {
  const spreaded = [...firstArray, ...secondArray];
  return spreaded.filter((el) => {
    return !(firstArray.includes(el) && secondArray.includes(el));
  });
}

export function getCurrencySubTypeItem(
  currency: Currency
): CurrencySubTypeItem {
  let item: CurrencySubTypeItem = {};
  if (currency == null || currency == undefined) {
    item.currency = null,
    item.subtypes = null,
    item.default = null,
    item.milyem = null;
  }
  else{
    item.currency = parseInt(currency.toString());
  switch (item.currency) {
    case Currency.Gold:
    case Currency.GoldNew:
      item.subtypes = GoldSubTypes;
      item.default = CurrencySubTypes.Has;
      item.milyem = 1000;
      break;
    case Currency.Accessories:
      item.subtypes = AccessoriesSubTypes;
      item.default = CurrencySubTypes.YeniCeyrek;
      break;
    case Currency.Silver:
      item.subtypes = SilverSubTypes;
      item.default = CurrencySubTypes.Ingot;
      break;
    case Currency.OtherAccessories:
      item.subtypes = OtherAccessoriesSubTypes;
      item.default = CurrencySubTypes.YeniAta5;
      break;
    case Currency.Other:
      item.subtypes = OtherCurrencyList;
      item.default = CurrencySubTypes.GBP;
      break;
    default:
      item.subtypes = null;
      item.default = null;
      item.milyem = null;
      break;
  }
  }

  return item;
}

export function getMilyem(subType: CurrencySubTypes): number | null {
  subType = parseInt(subType.toString());
  switch (subType) {
    case CurrencySubTypes.Has:
      return 995;
    case CurrencySubTypes.Carat22:
      return 912;
    case CurrencySubTypes.Carat21:
      return 875;
    case CurrencySubTypes.Carat20:
      return 833;
    case CurrencySubTypes.Carat18:
      return 750;
    case CurrencySubTypes.Carat14:
      return 570;
    case CurrencySubTypes.Carat8:
      return 333;
    default:
      return null;
  }
}
