import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountTransactionListMiniComponent } from './account-transaction-list-mini.component';
import { NgbNavModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { SimplebarAngularModule } from 'simplebar-angular';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';



@NgModule({
  declarations: [
    AccountTransactionListMiniComponent
  ],
  imports: [
    CommonModule,
    NgbNavModule,
    NgbTooltipModule,
    RouterModule,
    SimplebarAngularModule,
    PipesModule
    
  ],
  exports: [
    AccountTransactionListMiniComponent
  ]
})
export class AccountTransactionListMiniModule { }
