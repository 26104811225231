<ng-container *ngIf="!showAsPartial">
  <app-page-title title="Alım/Satım Listesi"></app-page-title>
</ng-container>
<div *ngIf="model">
  <ng-container *ngIf="!showAsPartial">
    <div class="card">
      <div class="card-body">
        <div class="card-title d-flex justify-content-between">
          <h5> Filtrele <i class="fas fa-search"></i> </h5>
          <button class="btn btn-sm btn-outline-info" triggers="hover" ngbTooltip="Filtreyi Temizle" placement="auto"
            container="body" (click)="clearFilter()"><i class="fas fa-trash-alt"></i></button>
        </div>
        <div class="row pt-2">
          <div class="col-md-6 col-lg-2 mb-3">
            <select id="customer" [(ngModel)]="request.filter.customerId" (change)="customerSelected()"
              class="form-control">
              <option [ngValue]="defaultValue">Müşteri</option>
              <option *ngFor="let item of customerList" [value]="item.value">{{item.text}}</option>
            </select>
          </div>
          <div class="col-md-6 col-lg-2 mb-3">
            <select aria-placeholder="Durum" id="state" [(ngModel)]="request.filter.state" (change)="stateChanged()"
              class="form-control">
              <option [ngValue]="defaultValue">Durum</option>
              <option *ngFor="let item of exchangeStateList" [ngValue]="item">{{item | exchangeState }} </option>
            </select>
          </div>
          <div class="col-md-6 col-lg-2 mb-3">
            <select aria-placeholder="Satış Birim" id="exchangeCurrency" [(ngModel)]="request.filter.exchangeCurrency"
              (change)="exchangeCurrencyChanged()" class="form-control">
              <option [ngValue]="defaultValue">Satış Birim</option>
              <option *ngFor="let item of currencyList" [ngValue]="item">{{item | customCurrency }} </option>
            </select>
          </div>
          <div class="col-md-6 col-lg-2 mb-3" *ngIf="exchangeSubTypes && exchangeSubTypes.length > 0">
            <div class="input-group">
              <select aria-placeholder="Satış Alt Birim" [(ngModel)]="request.filter.exchangeSubType"
                (change)="exchangeSubTypeChanged()" id="exchangeSubType" class="form-control">
                <option [ngValue]="defaultValue">Satış Alt Birim</option>
                <option *ngFor="let item of exchangeSubTypes" [ngValue]="item"> {{item | subTypeDisplay}} </option>
              </select>
            </div>
          </div>
          <div class="col-md-6 col-lg-2 mb-3">
            <select aria-placeholder="Alış Birim" id="exchangeCurrency" [(ngModel)]="request.filter.paymentCurrency"
              (change)="paymentCurrencyChanged()" class="form-control">
              <option [ngValue]="defaultValue">Alış Birim</option>
              <option *ngFor="let item of currencyList" [ngValue]="item">{{item | customCurrency }} </option>
            </select>
          </div>
          <div class="col-md-6 col-lg-2 mb-3" *ngIf="paymentSubTypes && paymentSubTypes.length > 0">
            <div class="input-group">
              <select aria-placeholder="Alış Alt Birim" [(ngModel)]="request.filter.paymentSubType"
                (change)="paymentSubTypeChanged()" id="paymentSubType" class="form-control">
                <option [ngValue]="defaultValue">Alış Alt Birim</option>
                <option *ngFor="let item of paymentSubTypes" [ngValue]="item"> {{item | subTypeDisplay}} </option>
              </select>
            </div>
          </div>
          <div class="col-md-6 col-lg-2 mb-3">
            <div class="input-group">
              <input placeholder="Başlangıç Tarihi" (click)="beginDate.toggle()" class="form-control"
                name="beginDatePicker" [(ngModel)]="beginDateModel" (ngModelChange)="reloadData(true)" ngbDatepicker
                #beginDate="ngbDatepicker" autocomplete="off">
              <button class="btn border-start-0" style="border-color: #ced4da;" (click)="beginDate.toggle()"
                type="button"> <i class="far fa-calendar"></i> </button>
            </div>
          </div>
          <div class="col-md-6 col-lg-2 mb-3">
            <div class="input-group">
              <input placeholder="Bitiş Tarihi" (click)="endDate.toggle()" class="form-control" name="endDatePicker"
                [(ngModel)]="endDateModel" ngbDatepicker #endDate="ngbDatepicker" (ngModelChange)="reloadData(true)"
                autocomplete="off">
              <span class="input-group-append">
                <button class="btn border-start-0" style="border-color: #ced4da;" (click)="endDate.toggle()"><i
                    class="far fa-calendar-alt"></i></button>
              </span>
            </div>
          </div>
        </div>
        <div class="row pt-2">
          <div class="col-md-6 col-lg-2 mb-3">
            <select id="customer" [(ngModel)]="request.filter.createrId" (change)="reloadData(true)"
              class="form-control">
              <option [ngValue]="defaultValue">İşlemi Yapan</option>
              <option *ngFor="let item of applicationUsers" [value]="item.value">{{item.text}}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <div class="card" [ngClass]="{'overflow-hidden': showAsPartial}">
    <div class="card-body" [ngClass]="{'p-0': showAsPartial}">
      <div class="row">
        <list-spinner class="center-body"  [ngClass]="{'position-absolute': model.data.Items.length > 0}"
          [showSpinner]="Loading" [length]="model.data.Items.length"></list-spinner>
            <div class="table-responsive" id="scrollbar1" [ngClass]="{'blur-content': Loading }"
              *ngIf="model.data && model.data.Items.length > 0">
              <table class="table table-hover mb-0">
                <thead>
                  <tr>
                    <th class="text-center">#</th>
                    <th *ngIf="showBalanceField">Bakiye</th>
                    <th *ngIf="!showAsPartial">Müşteri</th>
                    <th class="table-light" colspan="2">Satış</th>
                    <th >Kasa</th>
                    <th  class="table-light" colspan="2">Alış</th>
                    <th >Kasa</th>
                    <th>Kur</th>
                    <th class="text-center" ngbTooltip="TL Maliyeti" container="body">TL</th>
                    <th class="text-center" ngbTooltip="HAS Maliyeti" container="body">HAS</th>
                    <th class="text-center" ngbTooltip="USD Maliyeti" container="body">USD</th>
                    <th>İşlem Tarihi</th>
                    <th>Durumu</th>
                    <th></th>
                    <th>İşlemler</th>
                  </tr>
                  <!-- -->
                </thead>
                <tbody>
                  <tr *ngFor="let item of model.data.Items; let index = index">
                    <td class="text-center">{{index + 1}}.</td>
                    <td *ngIf="showBalanceField" class="text-center">
                      <!-- <input class="form-check-input" type="checkbox" [(ngModel)]="item.dateSelectedForBalance" (ngModelChange)="getItemForBalance(item)"  value="1" name="checkbox" checked> -->
                      <input class="form-check-input" type="checkbox" [ngModel]="selectedIndex === index"
                        (change)="changeSelection($event, index, item)">
                    </td>
                    <td *ngIf="!showAsPartial"> <a routerLink="/customer/detail/{{item.customerId}}" [ngbTooltip]="item.customerDescription"
                        href="javascript: void(0);" class="text-dark">
                        {{ item.customerName }} </a></td>

                    <td class="  table-light text-end">{{ item.exchangeAmount | currencyAmount: item.exchangeCurrency}}
                      <span *ngIf="item.exchangeCurrency == currencyEnum.Gold"> gr</span> </td>
                    <td *ngIf="item.exchangeCurrency == currencyEnum.Gold" class="  table-light text-end">{{
                      item.exchangeSubType | subTypeDisplay }} {{item.exchangeCurrency |
                      customCurrency}}({{item.exchangeMilyem}}) </td>
                    <td *ngIf="item.exchangeCurrency == currencyEnum.Other" class="  table-light text-end">
                      {{item.exchangeSubType | subTypeDisplay}}</td>
                    <td
                      *ngIf="item.exchangeCurrency == currencyEnum.Accessories ||  item.exchangeCurrency == currencyEnum.OtherAccessories || item.exchangeCurrency == currencyEnum.Silver"
                      class="  table-light text-end">{{ item.exchangeCurrency | customCurrency }} ({{item.exchangeSubType
                      |
                      subTypeDisplay}})</td>
                    <td
                      *ngIf="item.exchangeCurrency == currencyEnum.Tl || item.exchangeCurrency == currencyEnum.Euro || item.exchangeCurrency == currencyEnum.Usd "
                      class="  table-light text-end"> {{ item.exchangeCurrency | customCurrency }}</td>
                    <td class=" "> {{item.exchangeCaseName}} </td>
                    <td class=" table-light text-end">{{ item.paymentAmount | currencyAmount:
                      item.paymentCurrency}}<span *ngIf="item.paymentCurrency == currencyEnum.Gold"> gr</span></td>
                    <td *ngIf="item.paymentCurrency == currencyEnum.Gold" class=" table-light text-end">{{
                      item.paymentSubType | subTypeDisplay }} {{item.paymentCurrency |
                      customCurrency}}({{item.paymentMilyem}}) </td>
                    <td *ngIf="item.paymentCurrency == currencyEnum.Other" class=" table-light text-end">
                      {{item.paymentSubType | subTypeDisplay}}</td>
                    <td
                      *ngIf="item.paymentCurrency == currencyEnum.Accessories ||  item.paymentCurrency == currencyEnum.OtherAccessories || item.paymentCurrency == currencyEnum.Silver"
                      class=" table-light text-end">{{ item.paymentCurrency | customCurrency}} ({{item.paymentSubType |
                      subTypeDisplay}})</td>
                    <td
                      *ngIf="item.paymentCurrency == currencyEnum.Tl || item.paymentCurrency == currencyEnum.Euro || item.paymentCurrency == currencyEnum.Usd"
                      class=" table-light text-end">{{ item.paymentCurrency | customCurrency}}</td>
                    <td class=" "> {{item.paymentCaseName}} </td>
                    <td class="text-end">{{ item.parity | currencyAmount: null: null: true }}</td>
                    <td class="text-start" innerHTML="{{item.costTLState | costStateIcon}}  {{item.differenceCostTL | costAmount}}"> <span></span> </td>
                    <td class="text-start" innerHTML="{{item.costHASState | costStateIcon}} {{item.differenceCostHAS | costAmount}}"> </td>
                    <td class="text-start" innerHTML="{{item.costUSDState | costStateIcon}} {{item.differenceCostUSD | costAmount}}"> </td>
                    <td><span> {{ item.agreedDate | date:'dd.MM.y HH:mm'}} </span> </td>
                    <!-- <td><span> {{ item.paymentDate | date:'dd.MM.y HH:mm'}} </span> </td> -->
                    <td innerHtml="{{ item | exchangeItemState }}"> </td>
                    <td><app-history [trackable]="item"></app-history></td>
                    <td class="overflow-hidden text-end" ngbDropdown container="body">
                      <div class="btn-group">
                        <button class="btn btn-primary btn-sm" routerLink="/exchange/detail/{{item.id}}" title="Detay">
                          <i class="mdi mdi-eye"></i></button>
                        <button class="btn btn-outline-primary btn-sm" ngbDropdownToggle> <i
                            class="mdi mdi-tune-variant"></i>
                        </button>
                        <div ngbDropdownMenu *ngIf="item.state == exchangeStateEnum.Agreed">
                          <a href="javascript:void(0)" ngbDropdownItem
                            (click)="changeState(item.id, exchangeStateEnum.Done)">Tamamlandı</a>
                          <a href="javascript:void(0)" *hasRole="[Role.Manufacturing]" ngbDropdownItem
                            (click)="delete(item.id)">Sil</a>
                        </div>
                        <ng-container *hasRole="[Role.Manufacturing]">
                          <div ngbDropdownMenu *ngIf="item.state != exchangeStateEnum.Agreed">
                            <a href="javascript:void(0)" ngbDropdownItem (click)="delete(item.id)">Sil</a>
                          </div>
                        </ng-container>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
        <div *ngIf="model.data && model.data.TotalItemCount > 0">
          <app-pagination [blurOn]="Loading" [pageInfo]="model.data" [pageRequest]="request"
            (requestEvent)="reloadData()">
          </app-pagination>
        </div>
      </div>

    </div>
  </div>
