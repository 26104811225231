import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from './components/components.module';
import { PagesModule } from './pages/pages.module';
import { LayoutsModule } from './layouts/layouts.module';
import { ReceiptsModule } from './pages/receipts/receipts.module';



@NgModule({
  declarations: [],
  imports: [
    CommonModule, 
    ComponentsModule, 
    LayoutsModule,
    ReceiptsModule,
    PagesModule
  ]
})
export class UiModule { }
