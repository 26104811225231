<app-page-title title="Kasa Sayımı" [breadcrumbItems]="breadCrumbItems"></app-page-title>
<app-loader></app-loader>
<div class="d-block text-end mb-2 btn-group" role="group">
  <button type="button" class="btn btn-outline-secondary btn-sm me-1 " (click)="previousDate()"> <i
      class="fas fa-angle-double-left"></i> Önceki Gün </button>
  <button type="button" class="btn btn-light btn-sm"> {{showedDate | date:'dd.MM.y'}} </button>
  <button [disabled]="latestDay" type="button" class="btn btn-outline-secondary btn-sm ms-1" (click)="nextDate()"> Sonraki Gün <i
      class="fas fa-angle-double-right"></i> </button>
</div>

<ng-container *ngIf="caseList.length > 0">
  <ul ngbNav #nav1="ngbNav" [(activeId)]="activeCase" class="nav-pills" (navChange)="caseChanged($event)">
    <li [ngbNavItem]="case.id" *ngFor="let case of caseList">
      <a ngbNavLink>{{case.name}}</a>
      <ng-template ngbNavContent>
        <div>
          <ul ngbNav #nav2="ngbNav" [(activeId)]="activeAccount" class="nav-pill nav-fill">
            <li [ngbNavItem]="balance.currency" *ngFor="let balance of branchBalanceList">
              <a class="fs-5 custom-align p-1" ngbNavLink>
                <div class="card mini-stats-wid mb-0 mt-3">
                  <div class="card-body" (click)="loadTransactionsByCurrency(balance.currency)">
                    <div class="media">
                      <div class="align-self-center" [innerHtml]="balance.currency | currencyIcon"></div>
                      <div class="media-body">
                        <p class="text-muted mb-2">
                          {{ balance.currency | customCurrency }}
                        </p>
                        <h5 class="mb-0"> {{ balance.balance | currencyAmount: balance.currency }} {{balance.currency |
                          currencyWeight}}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
              <ng-template ngbNavContent>
                <div class="row mt-2">
                  <div class="col">
                    <app-account-transaction-list [showFilterAreas]="true" [filterAreas]="filterAreas" [filter]="filter"
                      [showHeader]="true"></app-account-transaction-list>
                  </div>
                  <div class="col-md-4"
                    *ngIf="balance.subTypeBalances && (balance.subTypeBalances | keyvalue).length > 0">
                    <ng-container *ngIf="balance.oldNewSubTypeBalances.length > 0 ">
                      <div class="card card-subtypes mt-0 mb-2">
                        <div class="table-responsive">
                          <table class="table mb-0">
                            <thead>
                              <tr class="bg-light text-primary">
                                <th style="width: 30%;">Eski - Yeni Toplamı</th>
                                <th class="text-end"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let mergedBalance of balance.oldNewSubTypeBalances">
                                <td class="fs-6 px-3">{{ mergedBalance.subType | subTypeDisplay}}</td>
                                <td class="fs-6 text-end">{{ mergedBalance.balance }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </ng-container>
                    <div class="card card-subtypes mt-0 mb-0">
                      <div class="table-responsive ">
                        <table class="table table-striped mb-0 caption-top">
                          <thead>
                            <tr>
                              <th style="width: 30%;">Alt Birim</th>
                              <th class="text-end">Miktar</th>
                              <th class="text-end" *ngIf="balance.currency == currencyEnum.Gold">Terazi Miktar</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let subTypeBalance of balance.subTypeBalances">
                              <td class="fs-6 px-3">{{subTypeBalance.subType | subTypeDisplay }}</td>
                              <td class="fs-6 text-end">{{subTypeBalance.balance | currencyAmount: balance.currency}}
                                {{balance.currency | currencyWeight: subTypeBalance.subType }} </td>
                              <td class="fs-6 text-end" *ngIf="balance.currency == currencyEnum.Gold">
                                {{subTypeBalance.balanceOfScales | currencyAmount: balance.currency}} {{balance.currency
                                | currencyWeight: subTypeBalance.subType }} </td>
                            </tr>
                          </tbody>
                        </table>

                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </li>
            <div class="card-body mt-4" *ngIf="branchBalanceList == null">
              <div class="text-muted text-center card-body"><i class="fa fa-exclamation-circle"></i> Bu tarihe ait işlem
                bulunamadı!</div>
            </div>
          </ul>
          <div [ngbNavOutlet]="nav2"></div>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="-1" *hasRole="[Role.Admin, Role.SuperAdmin]">
      <a class="fs-5 custom-align p-1" ngbNavLink>
        <app-case-create-modal></app-case-create-modal>
      </a>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav1"></div>
</ng-container>
