import { BasicParity } from './../models/parity/basic-parity';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { ExchangeItemFilter } from 'src/app/models/filters/exchange-item-filter';
import { Observable } from 'rxjs';
import { BaseResponse } from 'src/app/core/models/responses/base-response';
import { BaseService } from 'src/app/core/services/base.service';
import { ExchangeDTO, ExchangeItem } from 'src/app/models/exchange/exchange-item-request';
import { ChangeStateModel } from 'src/app/models/exchange/change-state-model';
import { ExchangeCreateResponse } from 'src/app/models/exchange/exchange-create-response';
import { SummaryResponse } from 'src/app/models/receipts/exchange-summary';
import { ExchangeFilter } from 'src/app/models/filters/exchange-filter';
import { BasePagingRequest } from 'src/app/core/models/requests/base-paging-request';
import { BasePagingResponse } from 'src/app/core/models/responses/base-paging-response';
import { UnknownCostResponseItem } from '../models/exchange/unknown-cost-response-item';
import { BaseListResponse } from '../core/models/responses/base-list-response';
import { SetUnknownCostRequest } from '../models/exchange/set-unknown-cost-request';
import { ExchangeCost } from '../models/exchange/exchange-cost';

@Injectable({
  providedIn: 'root'
})
export class ExchangeService extends BaseService<ExchangeItem, ExchangeFilter, number> {
  apiUrl = environment.urlAddress + '/Exchange';
  constructor(private http: HttpClient) {
    super(http, environment.urlAddress + '/Exchange')
   }

   getSummary(id: string): Observable<BaseResponse<SummaryResponse>> {
    return this._http.get<BaseResponse<SummaryResponse>>(this._base + "/summary/" + id)
  }

  createMultiple(dto: ExchangeDTO) : Observable<BaseResponse<ExchangeCreateResponse>>{
    return this._http.post<BaseResponse<ExchangeCreateResponse>>(this._base + "/create-multiple", dto);
    //.pipe(catchError(this.errorHandler));
  }

  getByMultipleId(ids:number[]): Observable<BaseResponse<ExchangeItem[]>>{
    return this._http.post<BaseResponse<ExchangeItem[]>>(this._base + "/get-by-multipleId", ids);
  }

   changeState(model: ChangeStateModel) : Observable<BaseResponse<null>>{
    return this._http.put<BaseResponse<null>>(this._base + '/change-state/', model)
  }

   paymentsDone(id: number) : Observable<BaseResponse<null>>{
    return this._http.get<BaseResponse<null>>(this._base + '/payments-done/' + id)
  }

  doneWithDebt(id: number) : Observable<BaseResponse<null>>{
    return this._http.get<BaseResponse<null>>(this._base + '/done-with-debt/' + id)
  }

  doneWithDeposit(id: number) : Observable<BaseResponse<null>>{
    return this._http.get<BaseResponse<null>>(this._base + '/done-with-deposit/' + id)
  }

  doneWithAgreed(id: number) : Observable<BaseResponse<null>>{
    return this._http.get<BaseResponse<null>>(this._base + '/done-with-agreed/' + id )
  }

  getWithAccountTransactions(id: number): Observable<BaseResponse<ExchangeItem>>{
    return this._http.get<BaseResponse<ExchangeItem>>(this._base + '/get-with-account-transactions/' + id)
  }

  getUnknownCosts(): Observable<BaseListResponse<UnknownCostResponseItem>>{
    return this._http.get<BaseListResponse<UnknownCostResponseItem>>(this._base + '/get-unknown-costs/')
  }

  editCosts(request: ExchangeItem): Observable<BaseResponse<ExchangeItem>>{
    return this._http.post<BaseResponse<ExchangeItem>>(this._base + '/edit-costs', request);
  }

  getCosts(request: BasicParity): Observable<BaseResponse<ExchangeItem>>{
    return this._http.post<BaseResponse<ExchangeItem>>(this._base + '/get-cost', request);
  }

  setUnknownCosts(request: SetUnknownCostRequest): Observable<BaseResponse<SetUnknownCostRequest>>{
    return this._http.post<BaseResponse<SetUnknownCostRequest>>(this._base + '/set-unknown-cost', request);
  }

  getAllCosts(basePagingRequest: BasePagingRequest<ExchangeFilter>): Observable<BasePagingResponse<ExchangeCost>> {
    let itemPerPage = localStorage.getItem("itemPerPage");
    basePagingRequest.itemPerPage = itemPerPage ? parseInt(itemPerPage) : 10;
    return this._http.post<BasePagingResponse<ExchangeCost>>(this._base + '/get-all-costs', basePagingRequest)
  }

  deleteCosts(request: BasicParity): Observable<BaseResponse<null>>{
    return this._http.post<BaseResponse<null>>(this._base + '/delete-costs', request);
  }

  updateCosts(request: SetUnknownCostRequest): Observable<BaseResponse<SetUnknownCostRequest>>{
    return this._http.post<BaseResponse<SetUnknownCostRequest>>(this._base + '/update-costs', request);
  }
}
