import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerAccountCreateEditModalComponent } from './customer-account-create-edit-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { NgSelectModule } from '@ng-select/ng-select';



@NgModule({
  declarations: [
    CustomerAccountCreateEditModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModalModule,
    PipesModule,
    NgSelectModule
  ],
  exports: [
    CustomerAccountCreateEditModalComponent
  ]
})
export class CustomerAccountCreateEditModalModule { }
