import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreProductTransactionModalComponent } from './pre-product-transaction-modal.component';
import { NgbDatepickerModule, NgbModalModule, NgbPopoverModule,  } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';



@NgModule({
  declarations: [
    PreProductTransactionModalComponent
  ],
  imports: [
    CommonModule,
    NgbModalModule,
    PipesModule,
    FormsModule,
    NgbDatepickerModule,
    NgbPopoverModule,

  ],
  exports: [
    PreProductTransactionModalComponent
  ]
})
export class PreProductTransactionModalModule { }
