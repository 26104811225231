<app-page-title title="Alım/Satım Maliyetleri"></app-page-title>
  <div class="row">
    <div [ngClass]="{'col-md-4 col-sm-12 col-xs-12 text-center': 'item'}" *ngIf="model && item">
      <div class="card">
        <div class="card-body">
          <div>
            <h5 class="text-primary text-start mb-4">Birim Maliyeti Ekle</h5>
            <select [(ngModel)]="selectedIndex" (change)="getCostItem(selectedIndex)" class="form-control mb-3">
              <option *ngFor="let item of request; let i = index" [value]="i">{{item.paymentCurrency | priceCurrency:
                item.paymentSubType }}</option>
            </select>
            <h6 class="text-center mb-3">Toplam Alış Miktarı: {{item.totalPaymentAmount | currencyAmount: null: null:
              true}}</h6>
            <div *ngIf="item.paymentCurrency != Currency.Tl" class="mb-2"> <input class="form-control" type="number"
                [placeholder]="costTLPlaceHolder" [disabled]="clicked && item.costTL" [(ngModel)]="item.costTL"></div>
            <div *ngIf="item.paymentCurrency != Currency.Usd" class="mb-2"> <input class="form-control" type="number"
                [placeholder]="costUSDPlaceHolder" [disabled]="clicked && item.costUSD" [(ngModel)]="item.costUSD">
            </div>
            <div *ngIf="item.paymentSubType != CurrencySubTypes.Has" class="mb-2">
              <input class="form-control" type="number" [placeholder]="costHASPlaceHolder"
                [disabled]="clicked && item.costHAS" [(ngModel)]="item.costHAS">
            </div>
            <button class="btn btn-primary mt-2" (click)="save(item)">Kaydet</button>
            <div class="text-end mt-2">Beklenen Birim Maliyet: {{request.length - 1}}</div>
          </div>

          <div *ngIf="request && request.length == 0">
            <div class="text-muted text-center card-body"><i class="fa fa-exclamation-circle"></i> Maliyetlerin tümü
              eklendi.</div>
          </div>
        </div>
      </div>
    </div>
    <div [ngClass]="{'col-md-8 col-sm-12 col-xs-12': '!item'}" *ngIf="listResponse">
      <div class="card" [ngClass]="{'blur-content': Loading }">
        <div class="card-body">
          <h5 class="text-primary text-start mb-4">İşlem Maliyetleri</h5>
          <div class="row">
            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 mb-3">
              <select aria-placeholder="Alış Birim" id="paymentCurrency" [(ngModel)]="listRequest.filter.paymentCurrency"
                (change)="paymentCurrencyChanged()" class="form-control">
                <option [ngValue]="defaultValue">Birim</option>
                <option *ngFor="let item of currencyList" [ngValue]="item">{{item | customCurrency }} </option>
              </select>
            </div>
            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 mb-3" *ngIf="paymentSubTypes && paymentSubTypes.length > 0">
              <div class="input-group">
                <select aria-placeholder="Alış Alt Birim" [(ngModel)]="listRequest.filter.paymentSubType"
                  (change)="reloadCosts()" id="paymentSubType" class="form-control">
                  <option [ngValue]="defaultValue">Alt Birim</option>
                  <option *ngFor="let item of paymentSubTypes" [ngValue]="item"> {{item | subTypeDisplay}} </option>
                </select>
              </div>
            </div>
            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 mb-3">
              <div class="input-group">
                <input placeholder="Başlangıç Tarihi" (click)="beginDate.toggle()" class="form-control"
                  name="beginDatePicker" [(ngModel)]="beginDateModel" (ngModelChange)="reloadCosts()" ngbDatepicker
                  #beginDate="ngbDatepicker" autocomplete="off">
                <button class="btn border-start-0" style="border-color: #ced4da;" (click)="beginDate.toggle()"
                  type="button"> <i class="far fa-calendar"></i> </button>
              </div>
            </div>
            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 mb-3">
              <div class="input-group">
                <input placeholder="Bitiş Tarihi" (click)="endDate.toggle()" class="form-control" name="endDatePicker"
                  [(ngModel)]="endDateModel" ngbDatepicker #endDate="ngbDatepicker" (ngModelChange)="reloadCosts()"
                  autocomplete="off">
                <span class="input-group-append">
                  <button class="btn border-start-0" style="border-color: #ced4da;" (click)="endDate.toggle()"><i
                      class="far fa-calendar-alt"></i></button>
                </span>
              </div>
            </div>
          </div>
          <list-spinner class="center-body" [ngClass]="{'position-absolute': listResponse.data.Items.length > 0}"
            [showSpinner]="Loading" [length]="listResponse.data.Items.length"></list-spinner>
            <div>  </div>
          <table class="table table-borderless table-hover table-sm" *ngIf="listResponse.data.Items.length > 0">
            <thead>
              <tr>
                <th class="text-center">#</th>
                <th class="text-end">Birim</th>
                <th class="table-danger text-end">TL Maliyet</th>
                <th class="table-success text-end">USD Maliyet</th>
                <th class="table-warning text-end">HAS Maliyet</th>
                <th class="text-end">İşlem Toplamı</th>
                <th class="text-end">Son İşlem Tarihi</th>
                <th class="text-center">İşlem</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let cost of listResponse.data.Items; let i = index">
                <td class="text-center">{{i+1}}.</td>
                <td class="text-end">{{cost.currency | priceCurrency: cost.subType}} </td>
                <td class="table-danger text-end">{{cost.costTL | costAmount}} </td>
                <td class="table-success text-end">{{cost.costUSD | costAmount}} </td>
                <td class="table-warning text-end">{{cost.costHAS | costAmount}} </td>
                <td class="text-end">{{cost.totalAmount | currencyAmount: null: null:true}}</td>
                <td class="text-end">{{cost.lastDate | date:'dd.MM.y HH:mm'}}</td>
                <td class="overflow-hidden text-center" ngbDropdown container="body">
                  <div class="btn-group">
                    <button class="btn btn-outline-primary btn-sm" ngbDropdownToggle> <i
                        class="mdi mdi-tune-variant"></i></button>
                    <div ngbDropdownMenu>
                      <a href="javascript:void(0)" ngbDropdownItem (click)="delete(cost)">Sil</a>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div *ngIf="listResponse.data && listResponse.data.TotalItemCount > 0">
            <app-pagination [blurOn]="Loading" [pageInfo]="listResponse.data" [pageRequest]="listRequest"
              (requestEvent)="reloadCosts()">
            </app-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
