import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductionStateComponent } from './production-state/production-state.component';
import { ManufactureOrderStateComponent } from './manufacture-order-state/manufacture-order-state.component';



@NgModule({
  declarations: [
    ProductionStateComponent,
    ManufactureOrderStateComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ProductionStateComponent,
    ManufactureOrderStateComponent
  ]
})
export class StatesModule { }
