<div class="receipt-container">
    <div class="page">
        <div class="subpage">
            <div class="text-center d-flex justify-content-center mb-3">
                <p class="company-name mb-0 me-1">{{tenantName | uppercase}}</p>
                <p class="branch-name mb-0 align-self-end"> ({{branchName | titlecase}})</p>
            </div>
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
