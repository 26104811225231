<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Yeni Tahsilat</h4>
  <div ngbDropdown placement="auto" container="body" class="d-inline-block">
      <button type="button" class="btn btn-info position-relative" id="operations" ngbDropdownToggle>
        İşlem
        <span *ngIf="cart.length != 0" class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
          {{cart.length}}
          <span class="visually-hidden">operations</span>
        </span>
    </button>
    <div ngbDropdownMenu aria-labelledby="operations" >
      <div class="table-responsive px-3 py-2" *ngIf="cart.length > 0"  >
        <table class="table table-striped caption-top">
          <caption *ngIf="isCollection">Tahsilat Listesi</caption>
          <caption *ngIf="!isCollection">Ödeme Listesi</caption>
          <thead>
            <tr>
                <th>#</th>
                <th>Kasa</th>
                <th>İşlem</th>
                <th>Miktar</th>
                <th>Birim</th>
                <th></th>
            </tr>
          </thead>
          <tbody>
              <tr *ngFor="let item of cart; let i = index">
                <td class="fw-bold">{{i+1}}.</td>
                <td>{{item.caseName}}</td>
                <td>{{item.type | collectionType}}</td>
                <td>{{item.amount | currencyAmount}}</td>
                <td>{{item.currency | customCurrency}}<span *ngIf="item.subType != undefined">({{item.subType | subTypeDisplay }})</span> <span triggers="hover" ngbTooltip="Milyem" *ngIf="item.currency === Currency.Gold">({{item.milyem}})</span> </td>
                <td style="padding: 8px;"><button class="btn btn-sm btn-danger" (click)="delete(item)"><i class="fas fa-trash"></i></button></td>
              </tr>
          </tbody>
        </table>
      </div>
      <div class="px-4 py-3" *ngIf="cart.length === 0">
        <div class="text-muted text-center">
          <i class="fa fa-exclamation-circle me-2"></i> Henüz bir işlem eklemediniz.
      </div>
      </div>
  </div>
  <!-- <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button> -->
</div>
</div>
<div class="modal-body">
      <!-- Transaction Type Start-->
      <div class="mb-3">
        <label class="d-block"> İşlem Tipi </label>
        <div class="form-check form-check-inline">
          <input class="form-check-input" (change)="isCollection = true" [(ngModel)]="type" (change)="isCollection = true;" type="radio" id="receivable" [value]="0">
          <label class="form-check-label" for="receivable">Tahsilat</label>
        </div>
        <div class="form-check form-check-inline">
            <input class="form-check-input" (change)="isCollection = false" [(ngModel)]="type" (change)="isCollection = false;" type="radio" name="type" id="debt" [value]="1">
            <label class="form-check-label" for="debt">Ödeme</label>
        </div>
    </div>

      <!-- Transaction Type End-->
    <div class="mb-3" *ngIf="currencyPreloaded == null" >
      <label for="currency">Kasa</label>
      <div class="input-group">
          <select [(ngModel)]="formModel.caseId" class="form-control">
              <option *ngFor="let item of caseList" [value]="item.value"> {{item.text}} </option>
          </select>
        </div>
    </div>
      <div class="mb-3" *ngIf="customerPreloaded != null">
        <label for="customer">Müşteri</label>
        <div class="input-group">
        <input id="customer" class="form-control" type="text" [ngModel]="customerPreloaded.name" (ngModel)="formModel.customerId" disabled >
        </div>
      </div>
      <div class="mb-3" *ngIf="customerPreloaded == null">
        <label for="customer">Müşteri</label>
        <div class="input-group">
          <input
          id="typeahead-focus"
          type="text"
          class="form-control"
          [(ngModel)]="formModel.customerId"
          name="selectItemModel"
          [ngbTypeahead]="search"
          placeholder="Müşteriler..."
          [resultFormatter]="resultFormatBandListValue"
          [inputFormatter]="inputFormatBandListValue"
          (selectItem)="selectedCustomer($event)" />
        </div>
      </div>

      <!-- Currency Start -->
    <div class="mb-3" *ngIf="currencyPreloaded == null" >
        <label for="currency">Birim</label>
      <div class="input-group">
          <select [(ngModel)]="formModel.currency" (change)="currencyChanged()" name="currency" id="currency" class="form-control">
              <option *ngFor="let item of currencyList" [value]="item"> {{item | customCurrency}} </option>
          </select>
        </div>
    </div>
    <div class="mb-3" *ngIf="currencyPreloaded != null">
      <label for="currency">Birim</label>
      <input id="currency" class="form-control" type="text" [ngModel]="currencyPreloaded | customCurrency" (ngModel)="formModel.currency" disabled >
    </div>
    <!-- Currency End -->
  <div class="row">
      <!-- SubType Start -->
      <div class="col mb-3" *ngIf="subTypes?.length > 0 && subTypePreloaded == null">
        <label for="subType">Alt Birim</label>
        <div class="input-group">
            <select [(ngModel)]="formModel.subType" (change)="subTypeChanged()" name="subType" id="subType" class="form-control">
                <option  *ngFor="let item of subTypes" [value]="item"> {{item | subTypeDisplay}} </option>
            </select>
        </div>
      </div>
      <div class="col mb-3" *ngIf="subTypes?.length > 0 && subTypePreloaded != null">
      <label for="subType">Alt Birim</label>
      <input type="text" class="form-control" [value]="subTypePreloaded" disabled>
      </div>
      <!-- SubType End -->

      <!-- Milyem Start -->
      <div class="col mb-3" *ngIf="formModel.currency == currencyEnum.Gold || currencyPreloaded == currencyEnum.Gold">
      <label for="milyem" class="justify-content-between">Milyem</label>
      <div class="input-group">
      <input id="milyem" class="form-control" [(ngModel)]="formModel.milyem" name="milyem" type="number" autocomplete="off"  >
      </div>
      </div>
      <!-- Milyem End -->

  </div>

    <div class="mb-3">
      <label for="amount">Miktar <span class="text-danger" ngbTooltip="Zorunlu alan">*</span>
      <span class="text-muted" *ngIf="formModel.amount">({{formModel.amount | currencyAmount: formModel.currency}})</span>
    </label>
    <div class="input-group">
      <input id="amount" type="number" class="form-control" name="amount" (keyup.Enter)="addToCart()" autocomplete="off" [(ngModel)]="formModel.amount" >
    </div>
  </div>

  <div class="mb-3">
    <label for="description">Açıklama</label>
    <div class="input-group">
      <textarea name="description" id="description" class="form-control" [(ngModel)]="formModel.description" cols="100" rows="2"></textarea>
    </div>
  </div>

  <div class="mb-3 pt-1">
    <div class="form-check">
      <input class="form-check-input" [(ngModel)]="print"  type="checkbox" value="1"  id="flexCheckDefault" checked>
      <label class="form-check-label" for="flexCheckDefault">
        Fiş Yazdır
      </label>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-info" triggers="hover" ngbTooltip="Çoklu işlem için listeye ekle" (click)="addToCart();"> <i class="fas fa-plus"></i> Ekle </button>
  <button type="button" class="btn btn-primary" [disabled]="isDisabled" (click)="submit();">Kaydet</button>
  <button type="button" class="btn btn-secondary" [disabled]="isDisabled" (click)="activeModal.close('Save click')">Geri Dön</button>
  <div>
   </div>
</div>

