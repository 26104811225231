import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: 'workshop', loadChildren: () => import('./workshop/workshop.module').then(m=>m.WorkshopModule)},
  { path: 'raw-material', loadChildren: () => import('./raw-material/raw-material.module').then(m=>m.RawMaterialModule)},
  { path: 'pre-product', loadChildren: () => import('./pre-product/pre-product.module').then(m=>m.PreProductModule)},
  { path: 'order', loadChildren: () => import('./order/order.module').then(m=>m.OrderModule)},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ManufactureRoutingModule { }
