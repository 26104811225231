import { Component, OnInit } from "@angular/core";
import { NgbModal, NgbNavChangeEvent } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";
import { Account } from "src/app/models/account/account";
import { Branch } from "src/app/models/branch/branch";
import { Case } from "src/app/models/case/case";
import { AccountTransactionFilter } from "src/app/models/filters/account-transaction-filter";
import { FilterAreas } from "src/app/models/filters/filter-areas";
import { RootAccountBalanceDTO } from "src/app/models/account/root-account-balance-dto";
import {
  AccessoriesSubTypes,
  CurrencySubTypes,
  GoldSubTypes,
  OtherCurrencyList,
  SilverSubTypes,
} from "src/app/models/enums/currency-sub-types";
import { Currency } from "src/app/models/enums/currency";
import { AccountTransactionCreateModalComponent } from "src/app/ui/components/account-transactions/account-transaction-create-modal/account-transaction-create-modal.component";
import { AlertService } from "src/app/services/alert.service";
import {
  hasSubType,
  isNullOrUndefined,
} from "src/app/core/helpers/common-functions";
import { KeyToSubTypePipe } from "src/app/shared/pipes/key-to-sub-type.pipe";
import { SubTypeBalance } from "src/app/models/account/sub-type-balance";
import { BaseComponent } from "src/app/shared/components/base/base.component";
import { AccountService } from "src/app/services/account.service";
import { CaseService } from "src/app/services/case.service";
import { AccountTransactionService } from "src/app/services/account-transaction.service";
import { CaseTransferModalComponent } from "src/app/ui/components/cases/case-transfer-modal/case-transfer-modal.component";

@Component({
  selector: "app-case-index",
  templateUrl: "./case-index.component.html",
  styleUrls: ["./case-index.component.scss"],
  providers: [],
})
export class CaseIndexComponent extends BaseComponent implements OnInit {
  breadCrumbItems: Array<{}> = [
    { label: "Kasa Bakiyeleri" },
    { label: "Kasa", active: true },
  ];
  activeCase = 1;
  defaultCaseId: number;
  activeAccount = 0;
  branch: Branch = JSON.parse(localStorage.getItem("currentBranch"));
  caseSubscription: Subscription;
  Currency = Currency;
  tlBalance: number;
  tlAccountId;
  usdBalance: number;
  usdAccountId;
  euroBalance: number;
  euroAccountId;
  goldBalance: number;
  goldAccountId;
  defaultCurrency = Currency.Tl;
  caseList: Case[] = [];
  branchBalanceList: RootAccountBalanceDTO[] = null;
  totalBalanceHas: number;
  showScaleBalance = false;
  branchAccounts: Account[] = [];
  selectedCase: number;

  caseTransactionFilter: AccountTransactionFilter = {
    branchId: this.branch.id,
    currency: this.defaultCurrency,
  };

  filterAreas: FilterAreas = {
    case: false,
    subType: true,
    customer: true,
    state: true,
    startDate: true,
    amount: true,
    endDate: true,
    creater: true,
    clearAll: true,
  };

  constructor(
    private accountService: AccountService,
    private caseService: CaseService,
    private accountTransactionService: AccountTransactionService,
    private modalService: NgbModal,
    private alertService: AlertService,
    public keyToSubTypePipe: KeyToSubTypePipe
  ) {
    super();
  }

  ngOnInit() {
    //this.loadBranchBalanceList();
    //this.loadBranchAccounts();
    this.loadCaseList();
    this.caseSubscription = this.caseService.refreshIndex.subscribe((res) => {
      this.loadCaseList();
    });
    this.accountTransactionService.refreshIndex.subscribe((res) =>
      this.loadBranchBalanceList(this.selectedCase)
    );
  }

  ngOnDestroy() {
    this.caseSubscription.unsubscribe();
  }

  loadCaseList() {
    this.caseService
      .getAllWoutPaging({
        branchId: this.branch.id,
      })
      .toPromise()
      .then((res) => {
        this.caseList = res.data.Items;
        if (this.caseList.length > 0) {
          this.activeCase = this.caseList[0].id;
          this.defaultCaseId = this.caseList[0].id;
          this.caseTransactionFilter.caseId = this.defaultCaseId;
          this.loadBranchBalanceList(this.activeCase);
        }
      });
  }

  caseChanged(changeEvent: NgbNavChangeEvent) {
    this.selectedCase = parseInt(changeEvent.nextId);
    this.caseTransactionFilter.caseId = this.selectedCase;
    this.caseTransactionFilter.caseId = JSON.parse(
      JSON.stringify(this.selectedCase)
    );
    this.loadBranchBalanceList(this.selectedCase);
  }

  loadBranchBalanceList(caseId: number) {
    this.accountTransactionService
      .getCaseBalances({ caseId: caseId, branchId: this.branch.id })
      .toPromise()
      .then((res) => {
        this.branchBalanceList = res.data.balanceList;
        this.totalBalanceHas = res.data.totalBalanceHas;
      });
  }

  loadBranchAccounts() {
    this.accountService
      .getAllWoutPaging({
        branchId: this.branch.id,
      })
      .toPromise()
      .then((res) => {
        this.branchAccounts = res.data.Items;
      });
  }

  loadTransactionsByCurrency(currency: Currency) {
    this.showScaleBalance = currency == Currency.Gold || currency == Currency.GoldNew ? true : false;
    this.caseTransactionFilter.amount = null;
    this.caseTransactionFilter.type = null;
    this.caseTransactionFilter.customerId = null;
    this.caseTransactionFilter.currency = currency;
    this.caseTransactionFilter.beginDate = null;
    this.caseTransactionFilter.endDate = null;
    this.filterAreas.subType = this.hasSubTypeFilterAreas(currency);
  }

  deleteCase() {
    if (!this.branchBalanceList) {
      this.alertService.showDeleteAlert().then((result) => {
        if (result.isConfirmed) {
          this.caseService
            .delete(this.selectedCase)
            .toPromise()
            .then((res) => {
              this.alertService.showSuccess();
              this.caseService.refreshIndex.next();
            });
        }
      });
    } else {
      const modalRef = this.modalService.open(CaseTransferModalComponent);
      modalRef.componentInstance.model.sourceCaseId = this.selectedCase;
      modalRef.componentInstance.deleteAndTransfer = true;
    }
  }

  transferCase() {
    const modalRef = this.modalService.open(CaseTransferModalComponent);
    modalRef.componentInstance.model.sourceCaseId = this.selectedCase;
    modalRef.componentInstance.deleteAndTransfer = false;
  }
  openAccountTransactionAddModal(
    currency: Currency,
    subType: CurrencySubTypes
  ) {
    const modalRef = this.modalService.open(
      AccountTransactionCreateModalComponent
    );
    modalRef.componentInstance.branchPreloaded = this.branch;
    modalRef.componentInstance.currencyPreloaded = currency;
    modalRef.componentInstance.subTypePreloaded = subType;
    modalRef.componentInstance.isCustomerTransaction = false;
  }

  hasSubTypeFilterAreas(currency: Currency) {
    switch (currency) {
      case Currency.Gold:
      case Currency.Silver:
      case Currency.Other:
      case Currency.Accessories:
      case Currency.OtherAccessories:
        return true;
      default:
        return false;
    }
  }

  getTotalBalanceHas(balance: RootAccountBalanceDTO): number {
    return balance.subTypeBalances.reduce((sum, current) => sum + current.balanceHas, 0);
  }

  getTotalBalance(balance: RootAccountBalanceDTO): number {
    return balance.subTypeBalances.reduce((sum, current) => sum + current.balance, 0);
  }

  getTotalBalanceOfScales(balance: RootAccountBalanceDTO): number {
    return balance.subTypeBalances.reduce((sum, current) => sum + (current.balanceOfScales || 0), 0);
  }
}
