import { Currency } from '../enums/currency';
import { CurrencySubTypes } from '../enums/currency-sub-types';
import { ExchangeState } from '../enums/exchange-state';
import { BaseFilter } from './base-filter';
export class ExchangeFilter extends BaseFilter {
    branchId?: number;
    customerId?: number;
    state?: ExchangeState;
    exchangeCurrency?: Currency;
    paymentCurrency?: Currency;
    exchangeSubType?: CurrencySubTypes;
    paymentSubType?: CurrencySubTypes;
}
