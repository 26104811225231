export enum Role {
  User = 1,
  Retail = 2,
  Wholesale = 4,
  Manufacturing = 8,
  Admin = 1024,
  SuperAdmin = 2048,
}

export const RoleList = [
  Role.User,
  Role.Retail,
  Role.Wholesale,
  Role.Manufacturing,
  Role.Admin,
  Role.SuperAdmin,
]
