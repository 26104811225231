export enum AccountTransactionReason{
    Deposit,
    Withdraw,
    Exchange,
    ExchangeCancel,
    BranchTransfer,
    BranchMoneyEntry,
    Collection,
    BranchMoneyExit,
    CaseCounting,
    Virman,
    Sales,
    ProductionCost,
    RawMaterialPurchase,
    Expense,
    Income,
    ProductPurchase
}