import { Pipe, PipeTransform } from '@angular/core';
import { ExchangeState } from 'src/app/models/enums/exchange-state';

@Pipe({
  name: 'exchangeState'
})
export class ExchangeStatePipe implements PipeTransform {

  transform(state: ExchangeState): string {
    
    if (state == ExchangeState.Agreed)
      return  "Bağlantı";
    if (state == ExchangeState.DoneWithDebt)
      return  "Borç";
    if (state == ExchangeState.DoneWithDeposit)
      return  "Alacaklı";
    if (state == ExchangeState.Done){
        return 'Tamamlandı'
    }
  }

}

