<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Kasa Transferi</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body" *ngIf="caseList.length > 0">

    <label for="destinationCase">Aktarılacak Kasa</label>
    <div class="input-group">
        <select [(ngModel)]="model.destinationCaseId" name="destinationCase" id="destinationCase" class="form-control">
            <option  *ngFor="let item of caseList" [value]="item.value">{{item.text}}</option>
        </select>          
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" [disabled]="isDisabled" (click)="confirm()" *ngIf="!deleteAndTransfer" >Aktarımı Başlat</button>
    <button type="button" class="btn btn-danger"  [disabled]="isDisabled" (click)="confirm(true)" *ngIf="deleteAndTransfer">Aktar ve Sil</button>
    <button type="button" class="btn btn-secondary" [disabled]="isDisabled" (click)="activeModal.close()">Vazgeç</button>
</div>