import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParityCreateEditModalModule } from './parity-create-edit-modal/parity-create-edit-modal.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { ParityMultipleCreateEditModalModule } from './parity-multiple-create-edit-modal/parity-multiple-create-edit-modal.module';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ParityCreateEditModalModule,
    ParityMultipleCreateEditModalModule
  ],
  exports: [
  ]
})
export class ParitiesModule { }
