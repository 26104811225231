import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SelectItem } from 'src/app/core/models/responses/select-item';
import { WidgetItem } from 'src/app/models/chart/widget-item';
import { WidgetService } from 'src/app/services/widget.service';

@Component({
  selector: 'app-widget-customizer-modal',
  templateUrl: './widget-customizer-modal.component.html',
  styleUrls: ['./widget-customizer-modal.component.scss']
})
export class WidgetCustomizerModalComponent implements OnInit {
  widgets: SelectItem[];

  constructor(
    private widgetService: WidgetService,
    public activeModal: NgbActiveModal,
    )
    {
    }

  ngOnInit(): void {
    this.widgetService.getUserWidgets().toPromise().then(res => {
      this.widgets = res.data;
      this.widgetService.widgetsSubject.next(res.data);
    })  }

  confirm(){
    this.widgetService.updateUserWidgets(this.widgets).toPromise().then(res => {
      this.widgets = res.data;
      this.widgetService.widgetsSubject.next(res.data);
    });
    this.activeModal.close();
  }

  toggleChecked(checkbox: HTMLInputElement, item: SelectItem) {
    item.selected = !item.selected;
    checkbox.checked = !checkbox.checked;
  }
}
