import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { Role } from 'src/app/core/models/role.enum';
import { ApplicationUserService } from 'src/app/core/services/application-user.service';

export interface IRoleBased {
  hasPermission(): void;
}

@Component({
  selector: 'app-role-based',
  template: `
    <ng-container *ngIf="show">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </ng-container>
  `
})
export class RoleBasedComponent implements IRoleBased, OnInit {
  @Output() initState = new EventEmitter<boolean>();
  @Input() roles: Role[];
  @Input() content: TemplateRef<any>;
  show = false;

  constructor(private service: ApplicationUserService) {
  }

  ngOnInit(): void {
    this.hasPermission();
  }

  public hasPermission(){
    if (this.service.currentUserValue) {
      const userRoles = this.service.currentUserValue.roles;
      const roleMatches = userRoles.findIndex(role => this.roles.indexOf(role) !== -1);
      if (roleMatches >= 0) {
        this.show = true;
        this.initState.emit(true);
      }
    }
  }
}
