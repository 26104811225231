import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SelectItem } from 'src/app/core/models/responses/select-item';
import { Branch } from 'src/app/models/branch/branch';
import { CaseTransferItem } from 'src/app/models/case/case-transfer-item';
import { AlertService } from 'src/app/services/alert.service';
import { CaseService } from 'src/app/services/case.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-case-transfer-modal',
  templateUrl: './case-transfer-modal.component.html',
  styleUrls: ['./case-transfer-modal.component.scss']
})
export class CaseTransferModalComponent implements OnInit {
  @Input() model: CaseTransferItem = {};
  @Input() deleteAndTransfer: boolean = false;
  currentBranch: Branch = JSON.parse(localStorage.getItem('currentBranch'));
  caseList: SelectItem[] = [];
  isDisabled: boolean = false;
  constructor(
    public activeModal: NgbActiveModal, 
    private dataService: DataService, 
    private service: CaseService,
    private alertService: AlertService
    ) { }

  ngOnInit(): void {
    this.dataService.getCase({ branchId: this.currentBranch.id, getAll: true }).toPromise().then(res => {
      this.caseList = res.data.filter(p => p.value != this.model.sourceCaseId);
      this.model.destinationCaseId = null;
    });
  }


  confirm(deleteThen = false){
    this.model.destinationCaseId  = parseInt(this.model.destinationCaseId.toString());
    this.isDisabled = true;
    this.service.transferToOtherCase(this.model).toPromise().then(res => 
      {
        this.isDisabled = false;
        if (res.isSuccess) {
          this.alertService.showSuccessToast('Kasa transfer işlemi başarılı.');
          this.service.refreshIndex.next();
          this.activeModal.close();
          if (deleteThen)
            this.delete();          
        }
        else{
          this.alertService.showErrorToast('Kasa transfer işlemi başarısız.');
          this.activeModal.close();
        }
      }).catch(err => this.isDisabled = false);
  };

  delete(){
    this.isDisabled = true;
    this.service.delete(this.model.sourceCaseId).toPromise().then(res => {
      this.isDisabled = false;
      if (res.isSuccess) {
        this.alertService.showSuccessToast('Kasa silindi.');
        this.service.refreshIndex.next();
      }
      else{
        this.alertService.showErrorToast('Kasa silinemedi.')
      }
    }).catch(err => this.isDisabled = false)
  }


}