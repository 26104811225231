import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HasRoleGuard } from 'src/app/core/guards/has-role.guard';
import { Role } from 'src/app/core/models/role.enum';
import { CaseCountingComponent } from './case-counting/case-counting.component';
import { CaseIndexComponent } from './case-index/case-index.component';

const routes: Routes = [
  {path: '', component:CaseIndexComponent, canActivate: [HasRoleGuard], data: {allowedRoles: [Role.User]}},
  {path: 'balances', component:CaseIndexComponent, canActivate: [HasRoleGuard], data: {allowedRoles: [Role.User]}},
  {path: 'counting', component:CaseCountingComponent, canActivate: [HasRoleGuard], data: {allowedRoles: [Role.User]}},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CaseRoutingModule { }
