import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CollectionCreateComponent } from './collection-create.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDropdownModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { ComponentsModule as SharedComponentsModule } from 'src/app/shared/components/components.module'


@NgModule({
  declarations: [CollectionCreateComponent],
  imports: [
    CommonModule,
    PipesModule,
    SharedComponentsModule,
    FormsModule,
    NgbTypeaheadModule,
    ReactiveFormsModule,
    NgbDropdownModule
  ],
  exports: [CollectionCreateComponent]
})
export class CollectionCreateModule { }
