export class BaseFilter{
  sortBy?: string
  sortAsc?: boolean
  beginDate?: Date
  endDate?: Date
  createrId?: string
  lastUpdaterId?: string
  deleterId?: string
  isDeleted?: boolean
  createTime?: Date
  lastUpdateTime?: Date
  deleteTime?: Date
  deletionBeginDate?: Date
  deletionEndDate?: Date
  createrName?: string
  lastUpdaterName?: string
  deleterName?: string
}
