import { BaseResponse } from 'src/app/core/models/responses/base-response';
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PreRequest } from './landing-models';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})

export class LandingService {

  apiUrl = environment.urlAddress;
  constructor(private http: HttpClient) { }

  createPreRequest(preRequest: PreRequest): Observable<BaseResponse<PreRequest>>{ 
    return this.http.post<BaseResponse<PreRequest>>(this.apiUrl + '/Landing/createPreRequest' , preRequest)
  }
  

}

