import { Pipe, PipeTransform } from "@angular/core";
import { Currency } from "src/app/models/enums/currency";
import { CurrencySubTypes } from "src/app/models/enums/currency-sub-types";

@Pipe({
  name: "subTypeShort",
})
export class SubTypeShortPipe implements PipeTransform {
  transform(value: CurrencySubTypes, currency?: Currency): string {
    if (value != null && value != undefined) {
      value = Number(String(value));
      switch (value) {
        case CurrencySubTypes.Has:
          return "HAS";
        case CurrencySubTypes.Carat22:
          return "22AYR";
        case CurrencySubTypes.Carat21:
          return "21AYR";
        case CurrencySubTypes.Carat20:
          return "20AYR";
        case CurrencySubTypes.Carat18:
          return "18AYR";
        case CurrencySubTypes.Carat14:
          return "14AYR";
        case CurrencySubTypes.Carat8:
          return "8AYR";
        case CurrencySubTypes.IngotYarim:
          return "KUL0.5";
        case CurrencySubTypes.Ingot1:
          return "KUL1";
        case CurrencySubTypes.IngotIkiBucuk:
          return "KUL2.5";
        case CurrencySubTypes.Ingot5:
          return "KUL5";
        case CurrencySubTypes.Ingot10:
          return "KUL10";
        case CurrencySubTypes.Ingot20:
          return "KUL20";
        case CurrencySubTypes.Ingot50:
          return "KUL50";
        case CurrencySubTypes.Ingot100:
          return "KUL100";
        case CurrencySubTypes.Ingot250:
          return "KUL250";
        case CurrencySubTypes.Ingot500:
          return "KUL500";
        case CurrencySubTypes.Ingot1000:
          return "KUL1KG";
        case CurrencySubTypes.Gram24:
          return "GR24";
        case CurrencySubTypes.Gram22:
          return "GR22";
        case CurrencySubTypes.YeniCeyrek:
          return "YCYRK";
        case CurrencySubTypes.YeniYarim:
          return "YYRM";
        case CurrencySubTypes.YeniTam:
          return "YZYNT";
        case CurrencySubTypes.YeniAta:
          return "YATA";
        case CurrencySubTypes.YeniGremse:
          return "YGRMS";
        case CurrencySubTypes.EskiGremse:
          return "EGRMS";
        case CurrencySubTypes.YeniAta5:
          return "YATA5";
        case CurrencySubTypes.EskiAta5:
          return "EATA5";
        case CurrencySubTypes.Aziz:
          return "AZIZ";
        case CurrencySubTypes.HamitGremse:
          return "HMT2.5";
        case CurrencySubTypes.Hamit5:
          return "HMT5";
        case CurrencySubTypes.Hamit:
          return "HMT";
        case CurrencySubTypes.Adli:
          return "ADLI";
        case CurrencySubTypes.EskiPara:
          return "ESKPR";
        case CurrencySubTypes.Bilezik14:
          return "BLZK14";
        case CurrencySubTypes.Bilezik18:
          return "BLZK18";
        case CurrencySubTypes.Bilezik22:
          return "BLZK22";
        case CurrencySubTypes.Armali5li:
          return "ARM5";
        case CurrencySubTypes.Resat:
          return "RST";
        case CurrencySubTypes.Resat5:
          return "RST5";
        case CurrencySubTypes.EskiCeyrek:
          return "ECYRK";
        case CurrencySubTypes.EskiYarim:
          return "EYRM";
        case CurrencySubTypes.EskiAta:
          return "EATA";
        case CurrencySubTypes.EskiTam:
          return "EZYNT";
        case CurrencySubTypes.AtaGremse:
          return "ATA2.5";
        case CurrencySubTypes.AtaYarim:
          return "ATAYRM";
        case CurrencySubTypes.AtaCeyrek:
          return "ATACYRK";
        case CurrencySubTypes.ResatGremse:
          return "RST2.5";
        case CurrencySubTypes.ResatYarim:
          return "RSTYRM";
        case CurrencySubTypes.ResatCeyrek:
          return "RSTCYRK";
        case CurrencySubTypes.Ziynet5:
          return "ZYNT5";
        case CurrencySubTypes.Granular:
          return "GRANUL";
        case CurrencySubTypes.Ingot:
          return "KULCE";
        case CurrencySubTypes.JPY:
          return "JPY";
        case CurrencySubTypes.GBP:
          return "GBP";
        case CurrencySubTypes.DKK:
          return "DK";
        case CurrencySubTypes.SEK:
          return "İK";
        case CurrencySubTypes.NOK:
          return "NK";
        case CurrencySubTypes.CHF:
          return "CHF";
        case CurrencySubTypes.AUD:
          return "AUD";
        case CurrencySubTypes.CAD:
          return "CAD";
        case CurrencySubTypes.SAR:
          return "SR";
        case CurrencySubTypes.RUB:
          return "RUB";
        case CurrencySubTypes.BGN:
          return "BGN";
        case CurrencySubTypes.AED:
          return "AED";
        case CurrencySubTypes.QAR:
          return "QAR";
        case CurrencySubTypes.CNY:
          return "CNY";
        case CurrencySubTypes.AZN:
          return "AZN";
        case CurrencySubTypes.KWD:
          return "KWD";
        case CurrencySubTypes.ILS:
          return "ILS";
        case CurrencySubTypes.MAD:
          return "MAD";
        case CurrencySubTypes.JOD:
          return "JOD";
        case CurrencySubTypes.OMR:
          return "OMR";
        case CurrencySubTypes.XPT:
          return "XPT";
        case CurrencySubTypes.XPD:
          return "XPD";
        case CurrencySubTypes.Platin:
          return "PLATIN";
        case CurrencySubTypes.Palladium:
          return "PALADYUM";
        case CurrencySubTypes.GEL:
          return "GEL";
        case CurrencySubTypes.Ceyrek:
          return "CYRK";
        case CurrencySubTypes.Yarim:
          return "YRM";
        case CurrencySubTypes.Tam:
          return "ZYNT";
        case CurrencySubTypes.Ata:
          return "ATA";
        case CurrencySubTypes.Ata5:
          return "ATA5";
        case CurrencySubTypes.Gremse:
          return "GRMS";
        default:
          return "";
      }
    } else if (currency != null && currency != undefined) {
      switch (currency) {
        case Currency.Tl:
          return "TL";
        case Currency.Euro:
          return "EURO";
        case Currency.Usd:
          return "USD";
      }
    }
  }
}
