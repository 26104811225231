import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerCreateEditModalModule } from './customer-create-edit-modal/customer-create-edit-modal.module';
import { CustomerCreateModule } from './customer-create/customer-create.module';
import { CustomerImportModule } from './customer-import/customer-import.module';



@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    CustomerCreateEditModalModule,
    CustomerImportModule
    // CustomerCreateModule
  ],

})
export class CustomersModule { }
