import { Component, OnInit } from '@angular/core';
import { Role } from 'src/app/core/models/role.enum';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit {
  Role = Role;
  constructor() { }

  ngOnInit(): void {
  }

}
