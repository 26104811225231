import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApplicationUserCreateModule } from './application-user-create/application-user-create.module';
import { HistoryModule } from './history/history.module';



@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    ApplicationUserCreateModule,
    HistoryModule
  ],
  exports: [
  ]
})
export class ApplicationUsersModule { }
