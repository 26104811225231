import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";
import { Account } from "src/app/models/account/account";
import { Branch } from "src/app/models/branch/branch";
import { Collection } from "src/app/models/collection/collection";
import { CustomerBalanceDTO } from "src/app/models/customer/customerBalanceDTO";
import { Currency, AllCurrencyList } from "src/app/models/enums/currency";
import {
  AccessoriesSubTypes,
  CurrencySubTypes,
  GoldSubTypes,
  OtherAccessoriesSubTypes,
  OtherCurrencyList,
  SilverSubTypes,
} from "src/app/models/enums/currency-sub-types";
import { FastCollectionModalComponent } from "../../collections/fast-collection-modal/fast-collection-modal.component";
import { CustomerDebtReceivableListModel } from "src/app/models/account-transaction/customer-debt-receivable-list-model";
import { WidgetService } from "src/app/services/widget.service";
import { getCurrencySubTypeItem } from "src/app/core/helpers/common-functions";

@Component({
  selector: "app-customer-debt-receivable-list",
  templateUrl: "./customer-debt-receivable-list.component.html",
  styleUrls: ["./customer-debt-receivable-list.component.scss"],
})
export class CustomerDebtReceivableListComponent implements OnInit, OnDestroy {
  @Input() isDebtList: boolean;

  refreshSubs = new Subscription();
  receivableListSubs = new Subscription();
  cardHeaderClass;
  branch: Branch = JSON.parse(localStorage.getItem("currentBranch"));
  branchAccounts: Account[] = [];
  customerListWithDebt: CustomerBalanceDTO[] = [];
  customerListWithReceivable: CustomerBalanceDTO[] = [];
  defaultValue = null;
  currencyList = AllCurrencyList;
  subTypeList: CurrencySubTypes[] = null;
  debtModalContent: CustomerBalanceDTO = {};
  filter = { customerName: null, currency: null, subType: null };

  receivableCollection: Collection = {};
  receivableModalContent: CustomerBalanceDTO = {};
  filterableList: CustomerBalanceDTO[] = [];
  showedList: CustomerDebtReceivableListModel[] = [];

  constructor(
    private widgetService: WidgetService,
    private modalService: NgbModal
  ) {}
  ngOnDestroy(): void {
    this.refreshSubs.unsubscribe();
    this.receivableListSubs.unsubscribe();
  }

  ngOnInit(): void {
    this.reloadData();
    this.refreshSubs =
      this.widgetService.refreshCustomerDebtReceivableWidget.subscribe(
        (res) => {
          this.reloadData();
        }
      );
  }

  getDebtList() {
    this.widgetService
      .getCustomerListWithDebt(this.branch.id)
      .toPromise()
      .then((res) => {
        this.customerListWithDebt = res.data;
        this.filterableList = JSON.parse(
          JSON.stringify(this.customerListWithDebt)
        );
        this.showedList = this.prepareShowedList(this.filterableList);
      });
  }

  getReceivableList() {
    this.widgetService
      .getCustomerListWithReceivable(this.branch.id)
      .toPromise()
      .then((res) => {
        this.customerListWithReceivable = res.data;
        this.filterableList = JSON.parse(
          JSON.stringify(this.customerListWithReceivable)
        );
        this.showedList = this.prepareShowedList(this.filterableList);
      });
  }

  openFastCollectionModal(model: CustomerBalanceDTO) {
    if (this.isDebtList) {
      const modalRef = this.modalService.open(FastCollectionModalComponent);
      modalRef.componentInstance.modalContent = JSON.parse(
        JSON.stringify(model)
      );
      modalRef.componentInstance.isDebtCollection = true;
    } else {
      const modalRef = this.modalService.open(FastCollectionModalComponent);
      modalRef.componentInstance.modalContent = JSON.parse(
        JSON.stringify(model)
      );
      modalRef.componentInstance.isDebtCollection = false;
    }
  }

  setSubTypes() {
    let item = getCurrencySubTypeItem(this.filter.currency);
    this.filter.currency = item.currency;
    this.subTypeList = item.subtypes;
    this.filterToList();
  }

  filterToList() {
    let list;

    if (this.isDebtList) {
      list = JSON.parse(JSON.stringify(this.customerListWithDebt));
    } else {
      list = JSON.parse(JSON.stringify(this.customerListWithReceivable));
    }

    if (this.filter.customerName != null) {
      list = this.getByNameFromList(list, this.filter);
    }

    if (this.filter.currency != null) {
      list = this.getByCurrencyFromList(list, this.filter);
    }

    if (this.filter.subType != undefined) {
      list = this.getBySubTypeFromList(list, this.filter);
    }

    this.filterableList = list;
    this.showedList = this.prepareShowedList(this.filterableList);
  }

  getByNameFromList(list: CustomerBalanceDTO[], filter): CustomerBalanceDTO[] {
    return list.filter((customer) =>
      customer.name
        .toLocaleLowerCase()
        .match(filter.customerName.toLocaleLowerCase())
    );
  }

  getByCurrencyFromList(
    list: CustomerBalanceDTO[],
    filter
  ): CustomerBalanceDTO[] {
    return list.filter((customer) => customer.currency == filter.currency);
  }

  getBySubTypeFromList(
    list: CustomerBalanceDTO[],
    filter
  ): CustomerBalanceDTO[] {
    return list.filter((customer) => customer.subType == filter.subType);
  }

  reloadData() {
    if (this.isDebtList) {
      this.cardHeaderClass = "card-header pt-1 pb-1 bg-danger";
      this.getDebtList();
    } else {
      this.cardHeaderClass = "card-header pt-1 pb-1 bg-success";
      this.getReceivableList();

    }
  }

  prepareShowedList(list: CustomerBalanceDTO[]) {
    let newList: CustomerDebtReceivableListModel[] = [];
    list.map((p) => {
      let item = newList.find((x) => x.customerId == p.id);
      if (item == null) {
        let newItem = new CustomerDebtReceivableListModel();
        newItem.customerId = p.id;
        newItem.customerName = p.name;
        newItem.customerBalances = [
          {
            balance: p.balance,
            currency: p.currency,
            subType: p.subType,
            id: p.id,
            name: p.name,
          },
        ];
        newList.push(newItem);
      } else {
        item.customerBalances.push({
          balance: p.balance,
          currency: p.currency,
          subType: p.subType,
          id: p.id,
          name: p.name,
        });
      }
    });
    return newList;
  }
}
