import { PagedList } from "../../../core/models/responses/paged-list";
import {
  Component,
  Input,
  OnInit,
  EventEmitter,
  Output,
  SimpleChanges,
  OnChanges,
} from "@angular/core";
import { BasePagingRequest } from "src/app/core/models/requests/base-paging-request";

@Component({
  selector: "app-pagination",
  templateUrl: "./pagination.component.html",
  styleUrls: ["./pagination.component.scss"],
})
export class PaginationComponent implements OnInit, OnChanges {
  @Input() pageInfo: PagedList<any>;
  @Input() pageRequest: BasePagingRequest<any>;
  @Output() requestEvent: EventEmitter<any> = new EventEmitter();
  @Input() blurOn: boolean = false;

  buttonValues: any[] = [];
  nrOfPagesToDisplay = 10;
  itemPerPageIsChanged: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.setShowButtons();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.pageInfo) {
      if (!changes.pageInfo.firstChange) {
        if (changes.pageInfo.previousValue.PageSize != changes.pageInfo.currentValue.PageSize) {
          this.pageInfo = changes.pageInfo.currentValue;
          this.setShowButtons();
        }
        this.pageInfo = changes.pageInfo.currentValue;
      }
    }
  }
  setShowButtons() {
    this.buttonValues = [];
    if (this.pageInfo.PageCount >= 1) {
      for (let i = 1; i <= this.pageInfo.PageCount; i++) {
        let showButton = true;
        if(showButton){
          this.buttonValues.push(i);
        }
      }
    }
    if (this.itemPerPageIsChanged) {
      this.pageRequest.pageIndex = 1;
      this.itemPerPageIsChanged = false;
    }
  }

  sendNewPageRequest() {
    if (this.itemPerPageIsChanged){
      const pageIndexIsLower = this.pageRequest.pageIndex > this.pageInfo.TotalItemCount / this.pageRequest.itemPerPage
      pageIndexIsLower ? this.pageRequest.pageIndex = 1 : this.pageRequest.pageIndex 
    }
    
    this.requestEvent.emit(this.pageRequest);
  }

  nextPage() {
    this.pageRequest.pageIndex += 1;
    this.sendNewPageRequest();
  }

  previousPage() {
    if (this.pageRequest.pageIndex > 1) {
      this.pageRequest.pageIndex -= 1;
      this.sendNewPageRequest();
    }
  }

  firstPage() {
    this.pageRequest.pageIndex = 1;
    this.sendNewPageRequest();
  }

  lastPage() {
    if (this.buttonValues.length > 0) {
      const lastIndex: number = this.buttonValues[this.buttonValues.length - 1];
      this.pageRequest.pageIndex = lastIndex;
      this.sendNewPageRequest();
    }
  }

  getByNumber(value: number) {
    this.pageRequest.pageIndex = value;
    this.sendNewPageRequest();
  }

  setItemPerPage(){
    this.itemPerPageIsChanged = true;
    this.sendNewPageRequest()
  }

}
