import { Injectable } from '@angular/core';
import { Router,  RoutesRecognized } from '@angular/router';
import {filter, pairwise } from 'rxjs/operators';

@Injectable()
export class PreviousRouteService {

 private previousUrl: string;
 previousIsModal: boolean;
 previousIsLogin: boolean;
 constructor(private router: Router) {

this.router.events
  .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
  .subscribe((events: RoutesRecognized[]) => {
    this.previousUrl = events[0].urlAfterRedirects;
    this.previousIsModal = this.previousUrl.indexOf('modal') != -1;
    this.previousIsLogin = this.previousUrl == '/login' ? true : false;    
  });
 }

 public getPreviousUrl(): string {
  return this.previousUrl;
 }

 get getIsModal(): boolean
 {
   return this.previousIsModal;
 }

 get getIsLogin(): boolean
{
  return this.previousIsLogin;
}

} 