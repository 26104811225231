import { SelectItem } from 'src/app/core/models/responses/select-item';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { BaseListResponse } from 'src/app/core/models/responses/base-list-response';
import { BaseResponse } from 'src/app/core/models/responses/base-response';
import { AccountBalanceDTO } from 'src/app/models/account/account-balance-dto';
import { CustomerBalanceDTO } from 'src/app/models/customer/customerBalanceDTO';
import { environment } from 'src/environments/environment';
import { WidgetItem } from '../models/chart/widget-item';
import { WidgetList } from '../models/widget/widget-list';

@Injectable({
  providedIn: 'root'
})
export class WidgetService{
  public refreshCustomerDebtReceivableWidget = new Subject();
  widgetsSubject = new Subject<SelectItem[]>();
  apiUrl = environment.urlAddress + "/Widget"

  constructor(private http: HttpClient) {
  }

  getUserWidgets(): Observable<BaseListResponse<SelectItem>>{
    return this.http.get<BaseListResponse<SelectItem>>(this.apiUrl + "/user-widget-list");
  }

  updateUserWidgets(request: SelectItem[]): Observable<BaseListResponse<SelectItem>>{
    return this.http.post<BaseListResponse<SelectItem>>(this.apiUrl + "/update-user-widgets", request);
  }

  getCustomerListWithDebt(branchId: number): Observable<BaseListResponse<CustomerBalanceDTO>>{
    return this.http.get<BaseListResponse<CustomerBalanceDTO>>(this.apiUrl + "/get-customer-list-with-debt/" + branchId)
  }

  getCustomerListWithReceivable(branchId: number): Observable<BaseListResponse<CustomerBalanceDTO>>{
    return this.http.get<BaseListResponse<CustomerBalanceDTO>>(this.apiUrl + "/get-customer-list-with-receivable/" + branchId)
  }

  getBranchBalancesWithMerge(branchId: number): Observable<BaseResponse<AccountBalanceDTO[]>>{
    return this.http.get<BaseResponse<AccountBalanceDTO[]>>(this.apiUrl + "/get-branch-balances-with-merge/" + branchId)
  }
}
