import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  SimpleChanges,
} from "@angular/core";
import { NgbDatepickerI18n } from "@ng-bootstrap/ng-bootstrap";
import { BasePagingRequest } from "src/app/core/models/requests/base-paging-request";
import { BasePagingResponse } from "src/app/core/models/responses/base-paging-response";
import { SelectItem } from "src/app/core/models/responses/select-item";
import { AlertService } from "src/app/services/alert.service";
import { DataService } from "src/app/services/data.service";
import { BaseListComponent } from "src/app/shared/components/base-list/base-list.component";
import { AccountTransaction } from "src/app/models/account-transaction/account-transaction";
import { AccountTransactionReason } from "src/app/models/enums/account-transaction-reason";
import { AccountTransactionType } from "src/app/models/enums/account-transaction-type";
import { ExchangeItem } from "src/app/models/exchange/exchange-item-request";
import { AccountTransactionFilter } from "src/app/models/filters/account-transaction-filter";
import { FilterAreas } from "src/app/models/filters/filter-areas";
import { CurrencySubTypes } from "src/app/models/enums/currency-sub-types";
import {
  DatePickerTrLangService,
  I18n,
} from "src/app/services/date-picker-tr-lang.service";
import { AccountTransactionService } from "src/app/services/account-transaction.service";
import { CustomerService } from "src/app/services/customer.service";
import { ExchangeService } from "src/app/services/exchange.service";
import { SalesService } from "src/app/services/sales.service";
import { Sales } from "src/app/models/sales/sales";
import { Customer } from "src/app/models/customer/customer";
import { getCurrencySubTypeItem } from "src/app/core/helpers/common-functions";
import { AllCurrencyList } from "src/app/models/enums/currency";

@Component({
  selector: "app-account-transaction-list",
  templateUrl: "./account-transaction-list.component.html",
  styleUrls: ["./account-transaction-list.component.scss"],
  providers: [
    I18n,
    { provide: NgbDatepickerI18n, useClass: DatePickerTrLangService },
  ],
})
export class AccountTransactionListComponent
  extends BaseListComponent
  implements OnInit
{
  @Input() filter?: AccountTransactionFilter;
  @Input() showFilterAreas?: boolean = true;
  @Input() filterAreas?: FilterAreas;
  @Input() showAsPartial: boolean = false;
  @Input() showHeader: boolean = false;
  @Input() showBalanceField: boolean = false;
  @Input() showSelectionField: boolean = false;
  @Input() showCaseField: boolean = true;
  @Input() showExpenseField: boolean = false;
  @Input() showCreaterField: boolean = false;
  @Output() transactionForBalanceDate = new EventEmitter();

  Type = AccountTransactionType;
  collectionCaseName;
  dateSelectedForBalance: boolean = false;
  branch = JSON.parse(localStorage.getItem("currentBranch"));
  expenseList: SelectItem[] = [];
  defaultValue = null;
  salesDetail: Sales;
  request: BasePagingRequest<AccountTransactionFilter> = {
    pageIndex: this.pageIndex,
    itemPerPage: this.itemPerPage,
    filter: {
      branchId: this.branch.id,
      createrId: null,
      type: null,
      amount: null,
    },
  };
  applicationUsers: SelectItem[] = [];
  pageFilterAreas: FilterAreas = {
    case: true,
    customer: true,
    state: true,
    startDate: true,
    endDate: true,
    clearAll: true,
    expenses: true,
    amount: true,
  };

  currencyList = AllCurrencyList;
  accountTransactionTypes: AccountTransactionType[] = [
    AccountTransactionType.Receivable,
    AccountTransactionType.Debt,
  ];
  model: BasePagingResponse<AccountTransaction>;
  beginDateModel: any;
  endDateModel: any;
  customerList: SelectItem[];
  branchCases: SelectItem[];
  selectedCustomerId: number;
  reasonEnum = AccountTransactionReason;
  exchangeInfo: ExchangeItem = {};
  selectedIndex: number;
  transactionInfo: AccountTransaction = {};
  subTypeList: CurrencySubTypes[] = [];
  customerOfDetail: Customer;
  selectedItem: AccountTransaction;
  selectedItems: AccountTransaction[];
  selectedTransactionForReceiptIndex: number;
  constructor(
    private service: AccountTransactionService,
    private dataService: DataService,
    private customerService: CustomerService,
    private exchangeService: ExchangeService,
    private alertService: AlertService,
    private salesService: SalesService
  ) {
    super();
  }

  ngOnInit() {
    if (this.filter) {
      if (this.filter.customerId)
        this.filter.customerId = parseInt(this.filter.customerId.toString());
      else this.filter.customerId = null;
      if (!this.filter.type) this.filter.type = null;
      if (!this.filter.caseId) this.filter.caseId = null;

      if (this.filterAreas && this.filterAreas.subType) {
        this.currencyChanged();
      }
      this.request.filter = this.filter;
      this.request.filter.subType = null;
      this.request.filter.createrId = null;
    } else {
      this.clearFilter();
    }
    if (this.showFilterAreas && this.filterAreas)
      this.pageFilterAreas = this.filterAreas;
    this.getApplicationUsers();
    this.service
      .getAll(this.request)
      .toPromise()
      .then((res) => {
        this.Loading = false;
        this.model = res;
      });
    this.service.refreshIndex.subscribe((res) => this.reloadData(true));
    this.dataService
      .getCustomer({ isActive: true })
      .toPromise()
      .then((res) => {
        this.customerList = res.data;
      });
    this.dataService
      .getCase({ branchId: this.branch.id })
      .toPromise()
      .then((res) => (this.branchCases = res.data));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.filter.firstChange) {
      this.request.filter = changes.filter.currentValue;
      this.reloadData();
    }
  }

  customerSelected() {
    this.filter.customerId = Number(this.filter.customerId);
    this.filter.type = null;
    this.reloadData(true);
  }

  stateSelected() {
    // this.request.filter.type = + this.request.filter.type;
    this.reloadData(true);
  }

  reloadData(isFiltered: boolean = false) {
    this.Loading = true;
    if (isFiltered) this.request.pageIndex = 1;

    if (this.beginDateModel) {
      this.request.filter.beginDate = new Date();
      this.request.filter.beginDate.setFullYear(
        this.beginDateModel.year,
        this.beginDateModel.month - 1,
        this.beginDateModel.day
      );
    }

    if (this.endDateModel) {
      this.request.filter.endDate = new Date();
      this.request.filter.endDate.setFullYear(
        this.endDateModel.year,
        this.endDateModel.month - 1,
        this.endDateModel.day
      );
    }

    localStorage.setItem("itemPerPage", this.request.itemPerPage.toString());
    //this.request.filter.customerId = Number(this.request.filter.customerId)
    this.service
      .getAll(this.request)
      .toPromise()
      .then((res) => {
        this.Loading = false;
        this.model = res;
        this.model = JSON.parse(JSON.stringify(this.model));
      });
  }

  delete(id: number) {
    this.alertService.showDeleteAlert().then((result) => {
      if (result.isConfirmed) {
        this.service.delete(id).subscribe((res) => {
          if (res.isSuccess) {
            this.service.refreshIndex.next();
            this.alertService.showSuccess("Silme işlemi başarılı.");
            this.customerService.refreshIndex.next();
            this.reloadData(true);
          }
        });
      }
    });
  }

  amountChanged(event: KeyboardEvent) {
    if (event.key == "Enter") {
      this.request.filter.sortBy =
        this.request.filter.amount == null ? null : "Amount";
      this.reloadData(true);
    }
  }

  clearFilter() {
    this.request.filter = new AccountTransactionFilter();
    this.showAsPartial
      ? (this.request.filter.caseId = this.filter.caseId)
      : null;
    if (this.filter != null)
      this.request.filter.currency = this.filter.currency;
    else {
      this.request.filter.currency = null;
      this.request.filter.caseId = null;
    }
    this.request.filter.customerId = null;
    this.beginDateModel = null;
    this.endDateModel = null;
    this.request.filter.createrId = null;
    this.request.filter.expenseTypeId = null;
    this.request.filter.subType = null;
    this.request.filter.type = null;
    this.request.filter.sortBy = null;
    this.request.filter.amount = null;
    this.reloadData(true);
  }

  getExchangeDetails(exchangeId: number) {
    this.exchangeService
      .find(exchangeId)
      .toPromise()
      .then((res) => {
        this.exchangeInfo = res.data;
      });
  }

  getAccountTransactionDetails(id: number) {
    this.transactionInfo = {};
    this.service
      .find(id)
      .toPromise()
      .then((res) => {
        this.transactionInfo = res.data;
      });
  }

  getItemForBalance(item: AccountTransaction, checked: boolean) {
    if (item && checked) {
      this.transactionForBalanceDate.emit(item);
    } else {
      this.transactionForBalanceDate.emit(null);
    }
  }

  getCaseNameById(item: AccountTransaction) {
    this.service
      .getCaseNameById(item.id)
      .toPromise()
      .then((res) => {
        if (res.isSuccess) {
          this.collectionCaseName = res.data.caseName;
        } else {
          this.collectionCaseName = null;
        }
      });
  }

  getApplicationUsers() {
    this.dataService
      .getApplicationUser({ isValid: true })
      .toPromise()
      .then((res) => (this.applicationUsers = res.data));
  }

  changeSelection(event, index, item) {
    this.selectedIndex = event.target.checked ? index : undefined;
    this.getItemForBalance(item, event.target.checked);
  }

  addSelections(event, item: AccountTransaction) {
    this.selectedItem = event.target.checked ? item : undefined;
    if (this.selectedItems == undefined) this.selectedItems = [];

    if (this.selectedItem == undefined)
      this.selectedItems = this.selectedItems.filter((p) => p.id != item.id);
    else this.selectedItems.push(this.selectedItem);
  }

  getSelections(): AccountTransaction[] {
    return this.selectedItems;
  }

  currencyChanged() {
    let item = getCurrencySubTypeItem(this.filter.currency);
    this.filter.currency = item.currency;
    this.subTypeList = item.subtypes;
  }

  getSales(salesId) {
    this.salesService
      .find(salesId)
      .toPromise()
      .then((res) => (this.salesDetail = res.data));
  }

  getCustomerByGroupId(groupId: string) {
    this.service
      .getCustomerByGroupId(groupId)
      .toPromise()
      .then((res) => (this.customerOfDetail = res.data));
  }
}
