import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'basic-spinner',
  templateUrl: './basic-spinner.component.html',
  styleUrls: ['./basic-spinner.component.scss']
})
export class BasicSpinnerComponent implements OnInit {
  @Input() showSpinner: boolean;
  constructor() { }

  ngOnInit(): void {
  }

}
