import { Pipe, PipeTransform } from '@angular/core';
import { CurrencySubTypes } from 'src/app/models/enums/currency-sub-types';

@Pipe({
  name: 'subTypeMilyem'
})
export class SubTypeMilyemPipe implements PipeTransform {

  transform(subType: CurrencySubTypes, isPayment: boolean = false): number {
    let value = parseInt(subType.toString()) as CurrencySubTypes;
    switch (value) {
      default:
      case CurrencySubTypes.Has:
        return isPayment ? 995 : 995;
      case CurrencySubTypes.Carat22:
        return isPayment ? 912 : 912;
      case CurrencySubTypes.Carat21:
        return isPayment ? 875 : 875;
      case CurrencySubTypes.Carat20:
        return isPayment ? 833 : 833;
      case CurrencySubTypes.Carat18:
        return isPayment ? 750 : 750;
      case CurrencySubTypes.Carat14:
        return isPayment ? 570 : 570;
      case CurrencySubTypes.Carat8:
        return isPayment ? 333: 333
      }
  }

}
