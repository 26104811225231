import { Component, OnInit } from '@angular/core';
import { ApplicationUser } from 'src/app/models/application-user/application-user';
import { Branch } from 'src/app/models/branch/branch';

@Component({
  selector: 'app-laser-print',
  templateUrl: './laser-print.component.html',
  styleUrls: ['./laser-print.component.scss']
})
export class LaserPrintComponent implements OnInit {
  tenantName: string = JSON.parse(localStorage.getItem('currentUser'))!.subSystemName;
  branchName: string = JSON.parse(localStorage.getItem('currentBranch'))!.name;
  constructor() { }

  ngOnInit(): void {
  }

}
