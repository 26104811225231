import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule as SharedComponentsModule } from 'src/app/shared/components/components.module';
import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';

import { ExchangeRoutingModule } from './exchange-routing.module';
import { NgbDropdownModule, NgbDatepickerModule, NgbModalModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ExchangeDetailComponent } from './exchange-detail/exchange-detail.component';
import { SubTypeMilyemPipe } from 'src/app/shared/pipes/sub-type-milyem.pipe';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { CurrencyInputDirective } from 'src/app/core/directives/currency-input.directive';
import { ExchangeOperationComponent } from './exchange-operation/exchange-operation.component';
import { ExchangeListComponent } from './exchange-list/exchange-list.component';
import { CustomerBalanceModule } from '../../components/balances/customer-balance/customer-balance.module';
import { HistoryModule } from '../../components/application-users/history/history.module';
import { CoreModule } from 'src/app/core/core.module';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';
import { ExchangeCostsComponent } from './exchange-costs/exchange-costs.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [ExchangeOperationComponent, ExchangeListComponent, ExchangeDetailComponent, CurrencyInputDirective, ExchangeCostsComponent],
  imports: [
    CommonModule,
    ExchangeRoutingModule,
    DirectivesModule,
    CoreModule,
    SharedComponentsModule,
    NgbDropdownModule,
    ReactiveFormsModule,
    PipesModule,
    FormsModule,
    NgbDatepickerModule,
    NgSelectModule,
    CustomerBalanceModule,
    NgbModalModule,
    HistoryModule,
    NgbTooltipModule,
    NgxMaskModule.forRoot(),
  ],
  exports: [ ExchangeListComponent ],
  providers: [SubTypeMilyemPipe, CurrencyPipe  ]
})
export class ExchangeModule { }
