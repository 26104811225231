import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BranchCreateEditModalModule } from './branch-create-edit-modal/branch-create-edit-modal.module';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    BranchCreateEditModalModule
  ]
})
export class BranchesModule { }
