import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccountTransactionCreateModalComponent } from './account-transaction-create-modal.component';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { NgbTooltipModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
  declarations: [
    AccountTransactionCreateModalComponent
  ],
  imports: [
    CommonModule,
    PipesModule,
    NgbTypeaheadModule,
    ReactiveFormsModule,
    FormsModule,
    NgbTooltipModule
  ],
  exports: [
    AccountTransactionCreateModalComponent
  ]
})
export class AccountTransactionCreateModalModule { }
