import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParityCreateEditModalComponent } from './parity-create-edit-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModalModule, NgbTooltip, NgbTooltipModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';


@NgModule({
  declarations: [
    ParityCreateEditModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModalModule,
    PipesModule,
    NgbTypeaheadModule,
    NgbTooltipModule
  ],
  exports: [ParityCreateEditModalComponent]
})
export class ParityCreateEditModalModule { }
