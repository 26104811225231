import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkshopCreateEditModalModule } from './workshop-create-edit-modal/workshop-create-edit-modal.module';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    WorkshopCreateEditModalModule
  ]
})
export class WorkshopsModule { }
