import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from 'src/app/core/services/base.service';
import { ManufactureOrderFilter } from 'src/app/models/filters/manufacture-order-filter';
import { ManufactureOrder } from 'src/app/models/order/manufacture-order';
import { environment } from 'src/environments/environment';
import { ManufactureOrderState } from '../models/enums/manufacture-order-state';
import { BaseResponse } from '../core/models/responses/base-response';
import { Observable } from 'rxjs';
import { ManufactureOrderUpdateStateRequest } from '../models/order/manufacture-order-update-state-request';

@Injectable({
  providedIn: 'root'
})
export class ManufactureOrderService extends BaseService<ManufactureOrder, ManufactureOrderFilter, number> {
  apiUrl = environment.urlAddress + '/ManufactureOrder';
  constructor(private http: HttpClient) {
    super(http, environment.urlAddress + '/ManufactureOrder')
   }

   updateState(model: ManufactureOrderUpdateStateRequest): Observable<BaseResponse<ManufactureOrderUpdateStateRequest>>{
    return this._http.post<BaseResponse<ManufactureOrderUpdateStateRequest>>(this.apiUrl + "/updateState", model);
   }
}
