import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductGroupCreateModule } from './product-group-create/product-group-create.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ProductGroupCreateModule
  ]
})
export class ProductGroupModule { }
