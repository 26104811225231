import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Account } from 'src/app/models/account/account';
import { environment } from 'src/environments/environment';
import { BaseListResponse } from 'src/app/core/models/responses/base-list-response';
import { Observable } from 'rxjs';
import { Customer } from 'src/app/models/customer/customer';
import { AccountFilter } from 'src/app/models/filters/account-filter';
import { BaseService } from '../core/services/base.service';
     
  
@Injectable({
  providedIn: 'root'
})
export class AccountService extends BaseService<Account, AccountFilter, number> {

  constructor(private http:HttpClient){
    super(http, environment.urlAddress + '/Account')
  }

  
  getDebtRecievableList(branchId: number): Observable<BaseListResponse<Customer>>{
    return this._http.get<BaseListResponse<Customer>>(this._base + "/customers-debt-receivable/" + branchId)
  }
}