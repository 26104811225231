<div *ngIf="pageInfo" class="mid-right" [ngClass]="{'blur-content': blurOn}">
  <div class="btn-group me-2" role="group">
    <ng-container *ngIf="pageInfo.PageCount > 1">
      <button
        ngDefaultControl
        [disabled]="pageInfo.IsFirstPage ? true : false"
        type="button"
        class="btn btn-outline-secondary"
        (click)="firstPage()"
      >
        <i class="bx bx-first-page"></i>
      </button>
      <button
        ngDefaultControl
        [disabled]="pageInfo.IsFirstPage ? true : false"
        type="button"
        class="btn btn-outline-secondary"
        (click)="previousPage()"
        >
        Geri
      </button>
        <select name="pager" id="pager" class="page-number hide-arrow text-secondary d-inline border border-secondary border-start-none" [(ngModel)]="pageRequest.pageIndex" (ngModelChange)="getByNumber($event)" >
          <option class="text-center page-number-option" *ngFor="let value of buttonValues" [value]="value"> {{value}} </option>
        </select>
      <button
        ngDefaultControl
        [disabled]="pageInfo.IsLastPage ? true : false"
        type="button"
        class="btn btn-outline-secondary"
        (click)="nextPage()"
        >
        İleri
      </button>
      <button
        ngDefaultControl
        [disabled]="pageInfo.IsLastPage ? true : false"
        type="button"
        class="btn btn-outline-secondary"
        (click)="lastPage()"
      >
        <i class="bx bx-last-page"></i>
      </button>
    </ng-container>
  </div>

  <div *ngIf="pageInfo.TotalItemCount > 0" class="right-item"> <span class="fs-6" >{{pageInfo.TotalItemCount}} </span><span class="text-muted"> kayıttan </span>  
    <select class="item-count hide-arrow text-center border-none " name="pager" id="pager" [(ngModel)]="pageRequest.itemPerPage" (ngModelChange)="setItemPerPage()">
      <option value="10">10</option>
      <option value="25">25</option>
      <option value="50">50</option>
      <option value="100">100</option>
    </select> <span class="text-muted">  kayıt göster </span>
  </div>
</div>
