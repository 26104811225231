import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: 'input[type="number"]'
})
export class PreventScrollDirective {
  constructor(private renderer: Renderer2) {}

  @HostListener('wheel', ['$event'])
  onWheel(event: WheelEvent) {
    event.preventDefault();
    this.renderer.setProperty(event, 'passive', true);
  }
}
