import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/services/alert.service';
import { Customer } from 'src/app/models/customer/customer';
import { CustomerService } from 'src/app/services/customer.service';

@Component({
  selector: 'app-customer-create-edit-modal',
  templateUrl: './customer-create-edit-modal.component.html',
  styleUrls: ['./customer-create-edit-modal.component.scss']
})
export class CustomerCreateEditModalComponent implements OnInit {

  @Input() isCreate: boolean = true;
  @Input() customerId: number;
  request: Customer = {isActive: true}
  show = true;
  isDisabled: boolean = false;
  constructor(
    private service: CustomerService,
    private alertService: AlertService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    public activeModal: NgbActiveModal,
    // private uploadService: UploadService

  ) { }

  ngOnInit(): void {
    if(!this.isCreate && !this.customerId){
      let id = this.route.snapshot.params['id'];
      this.service.find(id).toPromise().then(res => this.request = res.data )
    }

    if(!this.isCreate && this.customerId){
      this.service.find(this.customerId).toPromise().then(res => this.request = res.data )
    }

  }
  onSubmit() {
    if (this.isValid()) {
    if(this.isCreate){
      this.isDisabled = true;
      this.request.displayName = this.request.displayName.toLocaleUpperCase(["Turkish","tr"])
      this.service.create(this.request).toPromise().then(res => {
        this.isDisabled = false;
        if(res.isSuccess){
          this.modalService.dismissAll();
          this.alertService.showSuccess('Yeni müşteri eklendi.')
          this.service.refreshIndex.next();
        }
        }).catch(err => this.isDisabled = false)
    }
    if(!this.isCreate){
      this.request.displayName = this.request.displayName.toLocaleUpperCase(["Turkish","tr"])
      this.service.update(this.request).toPromise().then(res => {
        this.isDisabled = false;
        if(res.isSuccess){
          this.modalService.dismissAll();
          this.alertService.showSuccess('Müşteri güncellendi.')
          this.service.refreshIndex.next();
        }
      }).catch(err => this.isDisabled = false)
    }
  }
}

  isValid(): boolean {
    let messages: string[] = [];
    if (!this.request.name)
      messages.push('Müşteri adı boş olmamalı.')
    if (!this.request.displayName)
      messages.push('Müşteri kısa adı boş olmamalı.')
    if (messages.length > 0) {
      this.alertService.showErrorsToast(messages);
      return false
    }
    else return true;
  }

  onFilechange(event: any) {
    // this.file = event.target.files[0]
  }

  upload() {
    // if (this.file) {
    //   this.uploadService.uploadfile(this.file).subscribe(resp => {
    //     alert("Uploaded")
    //   })
    // } else {
    //   alert("Please select a file first")
    // }
  }

}
