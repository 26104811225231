import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, of } from 'rxjs';
import { CustomerFilter } from 'src/app/models/filters/customer-filter';
import { Account } from 'src/app/models/account/account';
import { Customer } from 'src/app/models/customer/customer';
import { BaseService } from 'src/app/core/services/base.service';
import { BaseResponse } from 'src/app/core/models/responses/base-response';
import { BaseListResponse } from 'src/app/core/models/responses/base-list-response';
import { Collection } from 'src/app/models/collection/collection';
import { CustomerBalanceDTO } from 'src/app/models/customer/customerBalanceDTO';
import { CustomerTransaction } from 'src/app/models/customer/customer-transaction';
import { CustomerVirman } from 'src/app/models/customer/customer-virman';
import { CustomerVirmanItem } from 'src/app/models/customer/customer-virman-item';
import { CustomerBalanceRequest } from 'src/app/models/customer/customer-balance-request';
import { CollectionResponse } from 'src/app/models/collection/collectionResponse';
import { AccountTransaction } from 'src/app/models/account-transaction/account-transaction';
import { CollectionSummaryRequest } from 'src/app/models/collection/collection-summary-request';
import { CollectionSummaryResponse } from 'src/app/models/collection/collection-summary-response';
import { VirmanSummaryRequest } from '../models/customer/virman-summary-request';
import { VirmanSummaryResponse } from '../models/customer/virman-summary-response';
import { CustomerVirmanResponse } from '../models/customer/customer-virman-response';

@Injectable({
  providedIn: 'root'
})
export class CustomerService extends BaseService<Customer, CustomerFilter, number> {
  apiUrl = environment.urlAddress + '/Customer';
  constructor(private http: HttpClient) {
    super(http, environment.urlAddress + '/Customer')
  }

  findByBranch(id: number, branchId: number, endDate?: any): Observable<BaseResponse<Customer>> {
    return this._http.get<BaseResponse<Customer>>(this._base + "/" + id + "?branchId=" + branchId + "&endDate=" + endDate );
  }
  getBalanceByDate(request: CustomerBalanceRequest): Observable<BaseResponse<Customer>> {
    return this._http.post<BaseResponse<Customer>>(this._base + "/get-balance-by-date", request);
  }

  getAccountsHasDebt(customerId: number, branchId: number): Observable<BaseListResponse<Account>>{
    return this._http.get<BaseListResponse<Account>>(this._base + "/customer-debt-details/"+ customerId + "/" + branchId)
  }

  getAccountsHasReceivable(customerId: number, branchId: number): Observable<BaseListResponse<Account>>{
    return this._http.get<BaseListResponse<Account>>(this._base + "/customer-receivable-details/"+ customerId + "/" + branchId)
  }

  createCollection(model: Collection): Observable<BaseResponse<CollectionResponse>>{
    return this._http.post<BaseResponse<CollectionResponse>>(this._base + "/create-collection", model)
  }

  createTransaction(model: CustomerTransaction): Observable<BaseResponse<CustomerTransaction>>{
    return this._http.post<BaseResponse<CustomerTransaction>>(this._base + "/create-transaction", model)
  }

  createVirman(model: CustomerVirman): Observable<BaseResponse<CustomerVirmanResponse>>{
    return this._http.post<BaseResponse<CustomerVirmanResponse>>(this._base + "/create-virman", model)
  }

  getLastCollection(groupId: string): Observable<BaseResponse<AccountTransaction[]>>{
    return this._http.get<BaseResponse<AccountTransaction[]>>(this._base + '/get-last-collection/' + groupId)
  }

  collectionSummary(request: CollectionSummaryRequest):Observable<BaseResponse<CollectionSummaryResponse>>{
    return this._http.post<BaseResponse<CollectionSummaryResponse>>(this._base + "/collection-summary", request);
  }

  virmanSummary(request: VirmanSummaryRequest):Observable<BaseResponse<VirmanSummaryResponse>>{
    return this._http.post<BaseResponse<VirmanSummaryResponse>>(this._base + "/virman-summary", request);
  }

  importCustomers(branchId: number, files: any) {
    let fileToUpload = <File>files[0];
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);

    return this.http.post(this.apiUrl +  '/bulk-create/' + branchId, formData, {reportProgress: true, observe: 'events'})
   }
}
