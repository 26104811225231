import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HasRoleGuard } from 'src/app/core/guards/has-role.guard';
import { Role } from 'src/app/core/models/role.enum';
import { CustomerBalanceComponent } from './customer-balance/customer-balance.component';
import { CustomerTransactionsSummaryComponent } from './customer-transactions-summary/customer-transactions-summary.component';
import { ExchangeSummaryComponent } from './exchange-summary/exchange-summary.component';
import { VirmanSummaryComponent } from './virman-summary/virman-summary.component';

const routes: Routes = [
  { path: '', redirectTo: 'exchange-summary', pathMatch:'full'},
  { path: 'exchange-summary/:id', component: ExchangeSummaryComponent, canActivate: [HasRoleGuard], data: {allowedRoles: [Role.Wholesale]} },
  { path: 'customer-balance', component: CustomerBalanceComponent, canActivate: [HasRoleGuard], data: {allowedRoles: [Role.User]} },
  { path: 'customer-transactions-summary', component: CustomerTransactionsSummaryComponent, canActivate: [HasRoleGuard], data: {allowedRoles: [Role.User]} },
  { path: 'virman-summary', component: VirmanSummaryComponent, canActivate: [HasRoleGuard], data: {allowedRoles: [Role.User]} },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReceiptsRoutingModule { }
