<div class="container-fluid">
    <app-loader></app-loader>
    <app-page-title title="Ana Sayfa"></app-page-title>
    <div class="row">
        <div class="col-xl-12 col-12 table-responsive" style="overflow: hidden;">
            <app-branch-balance></app-branch-balance>
            <div class="d-flex justify-content-end mb-3">
                <button triggers="hover" ngbTooltip="Görünümü Özelleştir" (click)="openWidgetCustomizerModal()"
                    container="body" class="btn btn-sm btn-primary"><i class="far fa-window-restore"></i></button>
            </div>
            <div class="row">
                <ng-container *ngIf="manufactureOrders?.selected">
                    <div class="col-xl-6">
                        <app-manufacture-orders-tab-widget></app-manufacture-orders-tab-widget>
                    </div>
                </ng-container>
                <ng-container *ngIf="agreedList?.selected">
                    <div class="col-xl-6">
                        <app-exchange-list-mini [filter]="agreedFilter" title="Alım/Satım Bağlantı Listesi"></app-exchange-list-mini>
                    </div>
                </ng-container>
                <ng-container *ngIf="incomeBarChart?.selected">
                    <div class="col-xl-6">
                        <app-expense-income-chart [isIncome]="true"></app-expense-income-chart>
                    </div>
                </ng-container>
                <ng-container *ngIf="expenseBarChart?.selected">
                    <div class="col-xl-6">
                        <app-expense-income-chart [isIncome]="false"></app-expense-income-chart>
                    </div>
                </ng-container>
                <ng-container *ngIf="hasAssetsPieChart?.selected">
                    <div class="col-xl-6">
                        <app-branch-balance-pie-chart></app-branch-balance-pie-chart>
                    </div>
                </ng-container>
                <ng-container *ngIf="hasAssetsNegativeBarChart?.selected">
                    <div class="col-xl-6" >
                        <app-negative-bar-chart></app-negative-bar-chart>
                    </div>
                </ng-container>
                <ng-container *ngIf="debtList?.selected">
                    <div class="col-xl-6">
                        <app-customer-debt-receivable-list [isDebtList]="true"></app-customer-debt-receivable-list>
                    </div>
                </ng-container>
                <ng-container *ngIf="receivableList?.selected">
                    <div class="col-xl-6">
                        <app-customer-debt-receivable-list [isDebtList]="false"></app-customer-debt-receivable-list>
                    </div>
                </ng-container>
            </div>

        </div>
    </div>
</div>
