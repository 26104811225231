import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ApplicationUserService } from '../services/application-user.service';

@Injectable({
  providedIn: 'root'
})
export class HasRoleGuard implements CanActivate {
  /**
   *
   */
  constructor(private service: ApplicationUserService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.isAuthorized(route)){
      this.router.navigateByUrl('/dashboard');
      return false;
    }
    return true;
  }

  protected isAuthorized(route: ActivatedRouteSnapshot): boolean{
    const userRoles = this.service.currentUserValue.roles;
    const allowedRoles = route.data.allowedRoles;
    const roleMatches = userRoles.findIndex(role => allowedRoles.indexOf(role) !== -1);
    return (roleMatches < 0) ? false : true;
  }

}
