import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { DashboardsRoutingModule } from './dashboards-routing.module';
import { ComponentsModule as SharedComponentsModule } from 'src/app/shared/components/components.module';

import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap'

import { SharedModule } from 'src/app/shared/shared.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ExchangeModule } from '../exchange/exchange.module';
import { ChartComponentsModule } from '../../components/chart-components/chart-components.module';
import { CustomCurrencyPipe } from 'src/app/shared/pipes/custom-currency.pipe';
import { BranchBalanceModule } from '../../components/dashboard-components/branch-balance/branch-balance.module';
import { CustomerDebtReceivableListModule } from '../../components/dashboard-components/customer-debt-receivable-list/customer-debt-receivable-list.module';
import { WidgetCustomizerModalModule } from '../../components/dashboard-components/widget-customizer-modal/widget-customizer-modal.module';
import { ManufactureOrdersModule } from '../../components/manufacture-orders/manufacture-orders.module';
import { ExchangeListMiniModule } from '../../components/exchanges/exchange-list-mini/exchange-list-mini.module';

@NgModule({
  declarations: [DashboardComponent],
  imports: [
    CommonModule,
    DashboardsRoutingModule,
    SharedComponentsModule,
    SharedModule,
    ChartComponentsModule,
    NgbAccordionModule,
    ExchangeModule,
    FormsModule,
    BranchBalanceModule,
    CustomerDebtReceivableListModule,
    WidgetCustomizerModalModule,
    ManufactureOrdersModule,
    ExchangeListMiniModule
  ],
  providers: [CustomCurrencyPipe]
})
export class DashboardsModule { }
