import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreProductTransactionListComponent } from './pre-product-transaction-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDatepickerModule, NgbDropdownModule, NgbNavModule, NgbPopoverModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { ComponentsModule as SharedComponentsModule } from 'src/app/shared/components/components.module';
import { SimplebarAngularModule } from 'simplebar-angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    PreProductTransactionListComponent
  ],
  imports: [
    CommonModule ,
    ReactiveFormsModule,
    NgbDropdownModule,
    PipesModule,
    RouterModule,
    SharedComponentsModule,
    NgbNavModule,
    SimplebarAngularModule,
    NgSelectModule,
    FormsModule,
    NgbTypeaheadModule,
    NgbDatepickerModule,
    NgbPopoverModule
  ],
  exports: [
    PreProductTransactionListComponent
  ]
})
export class PreProductTransactionListModule { }
