import { BaseEntity } from '../models/base-entity';
import { HttpClient } from '@angular/common/http';
import {  Observable, of, Subject, throwError } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BasePagingRequest } from '../models/requests/base-paging-request';
import { BaseResponse } from '../models/responses/base-response';
import { BasePagingResponse } from '../models/responses/base-paging-response';

export interface CrudOperations<BaseEntity, TFilter, ID> {
  create(t: BaseEntity): Observable<BaseResponse<BaseEntity>>;
  update(t: BaseEntity): Observable<BaseResponse<BaseEntity>>;
  find(id: ID): Observable<BaseResponse<BaseEntity>>;
  getAll(basePagingRequest: BasePagingRequest<TFilter>): Observable<BasePagingResponse<BaseEntity>>;
  delete(id: ID): Observable<any>;
}

export class BaseService<BaseEntity, TFilter, ID> implements CrudOperations<BaseEntity, TFilter, ID> {

  public refreshIndex = new Subject();

  constructor(
    protected _http: HttpClient,
    protected _base: string
  ) {

  }

  create(t: BaseEntity): Observable<BaseResponse<BaseEntity>> {
    return this._http.post<BaseResponse<BaseEntity>>(this._base + "/create", t);
  }

  update(t: BaseEntity): Observable<BaseResponse<BaseEntity>> {
    return this._http.put<BaseResponse<BaseEntity>>(this._base, t);
  }

  find(id: ID): Observable<BaseResponse<BaseEntity>> {
    return this._http.get<BaseResponse<BaseEntity>>(this._base + "/" + id);
  }

  getAll(basePagingRequest: BasePagingRequest<TFilter>): Observable<BasePagingResponse<BaseEntity>> {
    let itemPerPage = localStorage.getItem("itemPerPage");
    basePagingRequest.itemPerPage = itemPerPage ? parseInt(itemPerPage) : 10;
    return this._http.post<BasePagingResponse<BaseEntity>>(this._base + '/list', basePagingRequest)
  }

  getAllDeleted(basePagingRequest: BasePagingRequest<TFilter>): Observable<BasePagingResponse<BaseEntity>> {
    let itemPerPage = localStorage.getItem("itemPerPage");
    basePagingRequest.itemPerPage = itemPerPage ? parseInt(itemPerPage) : 10;
    return this._http.post<BasePagingResponse<BaseEntity>>(this._base + '/list-of-deleted', basePagingRequest)
  }

  getAllWoutPaging(request: TFilter): Observable<BasePagingResponse<BaseEntity>> {
    let basePagingRequest = new BasePagingRequest<TFilter>();
    basePagingRequest.filter = request;
    basePagingRequest.itemPerPage = 100;
    basePagingRequest.pageIndex = 1;
    return this._http.post<BasePagingResponse<BaseEntity>>(this._base + '/list', basePagingRequest)
  }

  delete(id: ID): Observable<BaseResponse<BaseEntity>> {
    return this._http.delete<BaseResponse<BaseEntity>>(this._base + '/' + id);
  }

  undo(id: ID): Observable<BaseResponse<BaseEntity>> {
    return this._http.get<BaseResponse<BaseEntity>>(this._base + '/undo/' + id);
  }



  errorHandler(error:any) {
    console.log(error);
    let errorMessage = '';

    if(error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = error.message;
    }
    return throwError(errorMessage);
 }
}
