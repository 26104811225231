import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { CalculationType } from 'src/app/models/enums/calculaton-type';
import { Currency } from 'src/app/models/enums/currency';
import { GoldSubTypes } from 'src/app/models/enums/currency-sub-types';
import { Parity } from 'src/app/models/exchange/parity';

@Injectable({
  providedIn: 'root'
})
export class LocalParityService {

  parities: Parity[] = JSON.parse(localStorage.getItem('parities'));

  constructor() {
    if (!this.parities)
      localStorage.setItem('parities', JSON.stringify([]));
   }

  loadParities(){
    this.parities = JSON.parse(localStorage.getItem('parities'));
    }

  find(parity: Parity, reloadParities: boolean = false): Parity{
    if (reloadParities)
      this.parities = JSON.parse(localStorage.getItem('parities'));

    if (!this.parities)
      localStorage.setItem('parities', JSON.stringify([]));

    let foundedParity = this.parities.find(
      (item) =>
        item.sourceCurrency == parity.sourceCurrency &&
        item.destinationCurrency == parity.destinationCurrency &&
        item.sourceSubType == parity.sourceSubType &&
        item.destinationSubType == parity.destinationSubType
    );
      return foundedParity;
    }

  update(parity: Parity){
    this.parities = JSON.parse(localStorage.getItem('parities'));
    if (parity.sourceCurrency == Currency.Gold || parity.destinationCurrency == Currency.Gold) {
      const goldParities = this.parities.filter(p => p.destinationCurrency == parity.destinationCurrency && p.sourceCurrency == parity.sourceCurrency);
        goldParities.map(gp => {
        gp.value = parity.value
        const op = this.convertToOpposite(gp);
        this.find(op).value = parity.value;
      });
    }
    else{
      let currentParity = this.find(parity);
      currentParity.value = parity.value;
      let oppositeParity = this.convertToOpposite(currentParity);
      oppositeParity = this.find(oppositeParity);
      oppositeParity.value = currentParity.value;
    }

    localStorage.setItem('parities', JSON.stringify(this.parities))
    this.parities = JSON.parse(JSON.stringify(this.parities))
  }

  create(parity: Parity){
    if (!this.parities)
      localStorage.setItem('parities', JSON.stringify([]));
    parity = JSON.parse(JSON.stringify((parity)))
    parity = this.convertPropsToNumber(parity);
    if (parity.sourceCurrency == Currency.Gold || parity.destinationCurrency == Currency.Gold) {
      let addList: Parity[] = [];
      if (parity.destinationCurrency == Currency.Gold) {
        GoldSubTypes.map(s => {
          const newParity: Parity = {
            destinationCurrency: Currency.Gold,
            destinationSubType: s,
            sourceCurrency: parity.sourceCurrency,
            sourceSubType: parity.sourceSubType,
            calculationType: CalculationType.Multiple,
            value: parity.value
          }
          addList.push(newParity);
          addList.push(this.convertToOpposite(newParity));
        })
      }

        if (parity.sourceCurrency == Currency.Gold) {
          GoldSubTypes.map(s => {
            const newParity: Parity = {
              sourceCurrency: Currency.Gold,
              sourceSubType: s,
              destinationCurrency: parity.destinationCurrency,
              destinationSubType: parity.destinationSubType,
              calculationType: CalculationType.Divide,
              value: parity.value
            }
            addList.push(newParity);
            addList.push(this.convertToOpposite(newParity));
          })
      }
      this.parities = [...addList];
      localStorage.setItem('parities', JSON.stringify(this.parities));
  }
  else{
    let oppositeParity = this.convertToOpposite(parity);
    oppositeParity.value = parity.value;

    if (parity.sourceCurrency == parity.destinationCurrency) {
      parity.calculationType = CalculationType.Multiple;
      parity.value = 1;
    }
    if (oppositeParity.sourceCurrency == oppositeParity.destinationCurrency) {
      parity.calculationType = CalculationType.Multiple;
      parity.value = 1;
    }
    this.parities.push(parity);
    this.parities.push(oppositeParity);
    localStorage.setItem('parities', JSON.stringify(this.parities));
  }
  }

  convertToOpposite(parity: Parity): Parity{
    let calculationType = parity.calculationType == CalculationType.Divide ? CalculationType.Multiple : CalculationType.Divide;
    let sourceCurrency =  parity.destinationCurrency;
    let sourceSubType =  parity.destinationSubType;

    let oppositeParity: Parity = {
      calculationType: calculationType,
      sourceCurrency: sourceCurrency,
      sourceSubType: sourceSubType,
      destinationCurrency: parity.sourceCurrency,
      destinationSubType: parity.sourceSubType,
      value: parity.value
    }
    return oppositeParity;
  }

  hasValue(parity: Parity): Boolean{
    if (!this.parities)
      localStorage.setItem('parities', JSON.stringify([]));

      if (this.parities != null && this.parities.length > 0) {
        let founded = this.parities.find(
          (item) =>
            item.sourceCurrency == parity.sourceCurrency &&
            item.destinationCurrency == parity.destinationCurrency &&
            item.sourceSubType == parity.sourceSubType &&
            item.destinationSubType == parity.destinationSubType
        );
          return founded ? true : false
      }
      else
        false
  }

  convertPropsToNumber(parity: Parity){
    parity.calculationType = parseInt(parity.calculationType.toString())
    parity.destinationCurrency = parseInt(parity.destinationCurrency.toString())
    parity.destinationSubType = parity.destinationSubType != null ? parseInt(parity.destinationSubType.toString()) : null;
    parity.sourceCurrency = parseInt(parity.sourceCurrency.toString())
    parity.sourceSubType = parity.sourceSubType != null ? parseInt(parity.sourceSubType.toString()) : null;
    parity.value = parseFloat(parity.value.toString())
    return parity;
  }


}
