import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CollectionCreateModule } from './collection-create/collection-create.module';
import { FastCollectionModalModule} from './fast-collection-modal/fast-collection-modal.module';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CollectionCreateModule,
    FastCollectionModalModule
  ],
})
export class CollectionsModule { }
