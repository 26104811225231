import { Pipe, PipeTransform } from "@angular/core";
import {
  Currency,
  CurrencyListForCasting,
} from "src/app/models/enums/currency";
import { CurrencySubTypes } from "src/app/models/enums/currency-sub-types";

@Pipe({
  name: "currencyAmount",
})
export class CurrencyAmountPipe implements PipeTransform {
  transform(
    amount: number,
    currency?: any,
    subType?: CurrencySubTypes,
    isParity?: boolean
  ): string {
    const currenciesForCasting = CurrencyListForCasting;
    if (amount != null || amount != undefined) {
      let currencyCastModel = currenciesForCasting.find((p) => p == currency);
      if (currencyCastModel) currency = this.currencyCastToEnum(currency);

      if (typeof currency === "string") currency = parseInt(currency);

      let isSmallerThanZero: boolean;
      amount < 0 ? (isSmallerThanZero = true) : (isSmallerThanZero = false);

      let text = amount.toString();
      text = text.replace(/[A-Z-]/g, "");

      const hasDecimalDot = text.includes(".");
      const hasDecimalComma = text.includes(",");
      let hasDecimal;
      let firstPart: string;

      if (hasDecimalDot) {
        firstPart = text.substring(0, text.indexOf("."));
        hasDecimal = true;
      }
      if (hasDecimalComma) {
        firstPart = text.substring(0, text.indexOf(","));
        hasDecimal = true;
      }
      if (!hasDecimal) {
        firstPart = text;
      }

      let dotCount = 0;
      let dotIndexes = [];

      const firstPartMod = firstPart.length % 3;

      if (firstPartMod == 0) {
        dotCount = firstPart.length / 3 - 1;
      } else {
        dotCount = firstPart.length / 3;
      }

      let firstWork = true;
      for (let i = 1; i <= dotCount; i++) {
        firstWork
          ? dotIndexes.push(firstPart.length - 3)
          : dotIndexes.push(dotIndexes[dotIndexes.length - 1] - 3);
        firstWork = false;
      }

      let result = "";
      let i = dotIndexes.length - 1;
      let lastDot = 0;
      let isFirstDot = true;
      for (i; i >= 0; i--) {
        if (isFirstDot) {
          lastDot = dotIndexes[i];
          result += firstPart.slice(0, lastDot) + ".";
          isFirstDot = false;
        } else {
          result += firstPart.slice(lastDot, dotIndexes[i]) + ".";
          lastDot = dotIndexes[i];
        }
      }
      result += firstPart.slice(lastDot, firstPart.length);

      if (hasDecimal) {
        if (currency == Currency.Gold || currency == Currency.Silver) {
          result += this.transformDecimalPart(text, 3);
        } else {
          result += isParity
            ? this.transformDecimalPart(text, 3)
            : this.transformDecimalPart(text, 2);
        }
      }
      return isSmallerThanZero ? "-" + result : result;
    }
  }

  transformDecimalPart(text, digitsCount) {
    const hasDecimalDot = text.includes(".");
    const hasDecimalComma = text.includes(",");
    let lastPart = hasDecimalDot
      ? text.substring(text.indexOf(".") + 1, text.length)
      : text.substring(text.indexOf(",") + 1, text.length);
    let digitsAfterComma = digitsCount;

    lastPart = lastPart.slice(0, digitsAfterComma);

    let lastCharAfterComma = lastPart.charAt(lastPart.length - 1);

    lastCharAfterComma == "0"
      ? (digitsAfterComma = 2)
      : (digitsAfterComma = digitsCount);

    lastPart = "," + lastPart.slice(0, digitsAfterComma);
    return lastPart;
  }

  currencyCastToEnum(currency: string) {
    let castedCurrency: Currency;

    switch (currency) {
      case "Tl":
        castedCurrency = Currency.Tl;
        break;
      case "Usd":
        castedCurrency = Currency.Usd;
        break;
      case "Euro":
        castedCurrency = Currency.Euro;
        break;
      case "Gold":
        castedCurrency = Currency.Gold;
        break;
      case "Silver":
        castedCurrency = Currency.Silver;
        break;
      case "Other":
        castedCurrency = Currency.Other;
        break;
      case "GoldNew":
        castedCurrency = Currency.GoldNew;
        break;
    }
    return castedCurrency;
  }
}
