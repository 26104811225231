import { DataService } from "src/app/services/data.service";
import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from "@angular/core";
import { SelectItem } from "src/app/core/models/responses/select-item";
import { ApplicationUserService } from "src/app/core/services/application-user.service";
import { Currency, ExchangeCurrencyList } from "src/app/models/enums/currency";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { NgbDatepickerI18n } from "@ng-bootstrap/ng-bootstrap";
import { ActivatedRoute } from "@angular/router";
import { BaseListComponent } from "src/app/shared/components/base-list/base-list.component";
import { BasePagingResponse } from "src/app/core/models/responses/base-paging-response";
import { BasePagingRequest } from "src/app/core/models/requests/base-paging-request";
import { Branch } from "src/app/models/branch/branch";
import { ExchangeState } from "src/app/models/enums/exchange-state";
import { AlertService } from "src/app/services/alert.service";
import {
  AccessoriesSubTypes,
  CurrencySubTypes,
  GoldSubTypes,
  OtherAccessoriesSubTypes,
  OtherCurrencyList,
  SilverSubTypes,
} from "src/app/models/enums/currency-sub-types";
import { AccountTransaction } from "src/app/models/account-transaction/account-transaction";
import { ExchangeItem } from "src/app/models/exchange/exchange-item-request";
import { ExchangeFilter } from "src/app/models/filters/exchange-filter";
import {
  DatePickerTrLangService,
  I18n,
} from "src/app/services/date-picker-tr-lang.service";
import { ExchangeService } from "src/app/services/exchange.service";
import { getCurrencySubTypeItem } from "src/app/core/helpers/common-functions";

@Component({
  selector: "app-exchange-list",
  templateUrl: "./exchange-list.component.html",
  styleUrls: ["./exchange-list.component.scss"],
  providers: [
    I18n,
    { provide: NgbDatepickerI18n, useClass: DatePickerTrLangService },
  ],
})
export class ExchangeListComponent
  extends BaseListComponent
  implements OnInit, OnChanges
{
  @Input() showAsPartial: boolean;
  @Input() filter: ExchangeFilter;
  @Input() title: string;
  @Input() showBalanceField: boolean = false;
  @Output() transactionForBalanceDate = new EventEmitter();

  defaultValue = null;
  currentBranch: Branch = JSON.parse(localStorage.getItem("currentBranch"));
  currencyEnum = Currency;
  exchangeStateEnum = ExchangeState;
  applicationUsers: SelectItem[] = [];
  customerList: SelectItem[] = [];
  currencyList = ExchangeCurrencyList;
  subTypeList: CurrencySubTypes[] = [];
  exchangeStateList: ExchangeState[] = [
    ExchangeState.Agreed,
    ExchangeState.DoneWithDebt,
    ExchangeState.DoneWithDeposit,
    ExchangeState.Done,
  ];
  beginDateModel: NgbDateStruct;
  endDateModel: NgbDateStruct;
  model: BasePagingResponse<ExchangeItem>;
  request: BasePagingRequest<ExchangeFilter> = {
    pageIndex: this.pageIndex,
    itemPerPage: this.itemPerPage,
    filter: {
      customerId: null,
      state: null,
      createrId: null,
      exchangeCurrency: null,
      exchangeSubType: null,
      paymentCurrency: null,
      paymentSubType: null,
    },
  };
  isUndo = false;
  selectedCurrency: number;
  exchangeSubTypes: CurrencySubTypes[];
  paymentSubTypes: CurrencySubTypes[];
  selectedIndex: number;

  constructor(
    private service: ExchangeService,
    private alertService: AlertService,
    private dataService: DataService,
    private route: ActivatedRoute,
    private userService: ApplicationUserService,
    private _i18n: I18n // turkish language support for ngbDatePicker
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.filter.firstChange) {
      this.request.filter = changes.filter.currentValue;
      this.reloadData();
    }
  }

  ngOnInit() {
    if (this.filter) {
      this.request.filter = this.filter;
    }

    this.request.filter.branchId = this.currentBranch.id;
    this.dataService
      .getCustomer({ isActive: true })
      .toPromise()
      .then((res) => {
        this.customerList = JSON.parse(JSON.stringify(res.data));
      });
    this.service
      .getAll(this.request)
      .toPromise()
      .then((res) => {
        this.Loading = false;
        this.model = res;
      });
    if (!this.showAsPartial) this.getApplicationUsers();
  }

  reloadData(goFirstPage: boolean = false) {
    this.Loading = true;
    if (goFirstPage) this.request.pageIndex = 1;
    this.setDates();

    localStorage.setItem("itemPerPage", this.request.itemPerPage.toString());
    this.service
      .getAll(this.request)
      .toPromise()
      .then((res) => {
        this.model = JSON.parse(JSON.stringify(res));
        this.Loading = false;
      });
  }

  customerSelected() {
    if (this.request.filter.customerId != null) {
      this.request.filter.customerId = parseInt(
        this.request.filter.customerId.toString()
      );
    }
    this.reloadData(true);
  }

  stateChanged() {
    if (this.request.filter.state != null) {
      this.request.filter.state = parseInt(
        this.request.filter.state.toString()
      );
    }
    this.reloadData(true);
  }

  exchangeCurrencyChanged() {
    let item = getCurrencySubTypeItem(this.request.filter.exchangeCurrency);
    this.request.filter.exchangeCurrency = item.currency;
    this.exchangeSubTypes = item.subtypes;
    this.request.filter.exchangeSubType = null;
    this.reloadData(true);
  }

  exchangeSubTypeChanged() {
    this.reloadData(true);
  }
  paymentCurrencyChanged() {
    let item = getCurrencySubTypeItem(this.request.filter.paymentCurrency);
    this.paymentSubTypes = item.subtypes;
    this.request.filter.paymentCurrency = item.currency;
    this.request.filter.paymentSubType = null;
    this.reloadData(true);
  }

  paymentSubTypeChanged() {
    this.reloadData(true);
  }

  setDates() {
    if (this.beginDateModel) {
      this.request.filter.beginDate = new Date();
      this.request.filter.beginDate.setFullYear(
        this.beginDateModel.year,
        this.beginDateModel.month - 1,
        this.beginDateModel.day
      );
    }

    if (this.endDateModel) {
      this.request.filter.endDate = new Date();
      this.request.filter.endDate.setFullYear(
        this.endDateModel.year,
        this.endDateModel.month - 1,
        this.endDateModel.day
      );
    }
  }

  clearFilter() {
    this.request.filter = new ExchangeFilter();
    this.request.filter.branchId = this.currentBranch.id;
    this.request.filter.customerId = null;
    this.request.filter.createrId = null;
    this.request.filter.state = null;
    this.request.filter.exchangeCurrency = null;
    this.exchangeSubTypes = [];
    this.request.filter.paymentCurrency = null;
    this.paymentSubTypes = [];
    this.beginDateModel = null;
    this.endDateModel = null;
    this.reloadData(true);
  }

  delete(id: number) {
    this.alertService.showDeleteAlert().then((result) => {
      if (result.isConfirmed) {
        this.service
          .delete(id)
          .toPromise()
          .then((res) => {
            if (res.isSuccess) {
              this.alertService.showSuccess("Alım/Satım işlemi silindi.");
              this.reloadData();
            }
          });
      }
    });
  }

  changeState(exchangeId: number, exchangeState: ExchangeState) {
    this.service
      .changeState({ id: exchangeId, state: exchangeState })
      .toPromise()
      .then((res) => {
        this.alertService.showSuccessToast("Bağlantı işlemi tamamlandı.");
        this.reloadData(true);
      });
  }

  doneWithDebt(item: ExchangeItem) {
    this.service
      .doneWithDebt(item.id)
      .toPromise()
      .then((res) => {
        this.alertService.showSuccess();
        this.reloadData();
      });
  }

  doneWithAgreed(item: ExchangeItem) {
    this.service
      .doneWithAgreed(item.id)
      .toPromise()
      .then((res) => {
        this.alertService.showSuccess();
        this.reloadData();
      });
  }

  doneWithDeposit(item: ExchangeItem) {
    this.service
      .doneWithDeposit(item.id)
      .toPromise()
      .then((res) => {
        this.alertService.showSuccess();
        this.reloadData();
      });
  }

  getItemForBalance(item: ExchangeItem, checked: boolean) {
    let transaction: AccountTransaction;
    let exchange: ExchangeItem;
    this.service
      .getWithAccountTransactions(item.id)
      .toPromise()
      .then((res) => {
        exchange = res.data;
        transaction =
          exchange.state === ExchangeState.Done
            ? exchange.accountTransactionDTOs[0]
            : exchange.accountTransactionDTOs.find((p) => p.caseId === null);
        item && checked
          ? this.transactionForBalanceDate.emit(transaction)
          : this.transactionForBalanceDate.emit(null);
      });
  }

  getApplicationUsers() {
    this.dataService
      .getApplicationUser({ isValid: true })
      .toPromise()
      .then((res) => (this.applicationUsers = res.data));
  }

  changeSelection(event, index, item) {
    this.selectedIndex = event.target.checked ? index : undefined;
    this.getItemForBalance(item, event.target.checked);
  }
}
