import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreProductTransactionModalModule } from './pre-product-transaction-modal/pre-product-transaction-modal.module';
import { PreProductTransactionListModule } from './pre-product-transaction-list/pre-product-transaction-list.module';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    PreProductTransactionModalModule,
    PreProductTransactionListModule
  ]
})
export class PreProductTransactionsModule { }
