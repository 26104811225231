import { debounceTime } from 'rxjs/operators';
import { PreRequest } from './landing-models';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit } from '@angular/core';
import { interval } from 'rxjs';
import { map } from 'rxjs/internal/operators';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Validators } from '@angular/forms';
import { LandingService } from './landing.service';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export default class LandingComponent implements OnInit {

  siteKey = '6Ley3SYfAAAAAGVOqyWy-0S8FgXXnVOlLyPX5hRJ';
  secretKey = '6Ley3SYfAAAAANFXq-nIg_vY-eqBHpbvcGSg-npn';
  preRequestForm: FormGroup 
  firstLoad = true;
  environment = environment

  closeResult = '';
  // set the currenr year
  year: number = new Date().getFullYear();
  currentSection = 'home';

  carouselOption: OwlOptions = {
    items: 1,
    loop: false,
    margin: 24,
    nav: false,
    dots: false,
    responsive: {672: {items: 3},912: {items: 4},}
  }

  timelineCarousel: OwlOptions = {
    items: 1,
    loop: false,
    margin: 0,
    nav: true,
    navText: ["<i class='mdi mdi-chevron-left'></i>", "<i class='mdi mdi-chevron-right'></i>"],
    dots: false,
    responsive: { 672: {items: 3}, 576: {items: 2}, 936: {items: 4},}
  }

  private _trialEndsAt;

  private _diff: number = 0;
  _days: number = 0;
  _hours: number = 0;
  _minutes: number = 0;
  _seconds: number = 0;

  constructor(private modalService: NgbModal, private formBuilder: FormBuilder , private service: LandingService) {

  }

  ngOnInit() {
    this.preRequestForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      email: ['', [Validators.required, Validators.email]],
      phone:['', [Validators.required, Validators.minLength(11), Validators.maxLength(11)]],
      message:['', [Validators.required, Validators.minLength(3)]],
      recaptcha:['', [Validators.required]]
    });

      this._trialEndsAt = "2022-04-10";
    interval(3000).pipe(
      map((x) => {
        this._diff = Date.parse(this._trialEndsAt) - Date.parse(new Date().toString());
      })).subscribe((x) => {
        this._days = this.getDays(this._diff);
        this._hours = this.getHours(this._diff);
        this._minutes = this.getMinutes(this._diff);
        this._seconds = this.getSeconds(this._diff);
      });
  }

  get form(){
    return this.preRequestForm.controls;
  }

  getDays(t) {
    return Math.floor(t / (1000 * 60 * 60 * 24));
  }

  getHours(t) {
    return Math.floor((t / (1000 * 60 * 60)) % 24);
  }

  getMinutes(t) {
    return Math.floor((t / 1000 / 60) % 60);
  }

  getSeconds(t) {
    return Math.floor((t / 1000) % 60);
  }

  ngOnDestroy(): void {
    // this.subscription.unsubscribe();
  }
  /**
   * Window scroll method
   */
  windowScroll() {
    const navbar = document.getElementById('navbar');
    if (document.body.scrollTop >= 50 || document.documentElement.scrollTop >= 50) {
      navbar.classList.add('nav-sticky')
    } else {
      navbar.classList.remove('nav-sticky')
    }
  }

  /**
   * Toggle navbar
   */
  toggleMenu() {
    document.getElementById('topnav-menu-content').classList.toggle('show');
  }

  /**
   * Section changed method
   * @param sectionId specify the current sectionID
   */
  onSectionChange(sectionId: string) {
    this.currentSection = sectionId;
  }

  
  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  send(){
    let request : PreRequest = {
      name: this.form.name.value,
      email: this.form.email.value,
      phone: this.form.phone.value,
      message: this.form.message.value
    }
    this.service.createPreRequest(request).toPromise().then(res => {
      if (res.isSuccess) {
        this.firstLoad = false;
        setTimeout(() => {
          this.modalService.dismissAll();
          // this.firstLoad = true;
        }, 2000)
      }
    })
  }
}
