import { ApplicationInitStatus, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApplicationUserService } from '../services/application-user.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private service: ApplicationUserService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (environment.defaultauth === 'znobackend') {
            const currentUser = this.service.currentUserValue;
            const currentBranch = this.service.currentBranchValue;
            if (currentUser && currentUser.token && currentBranch) {
                request = request.clone({
                    setHeaders: {
                        authToken: currentUser.token,
                        branchId: currentBranch.id.toString()
                    }
                });
            }
        }
        return next.handle(request);
    }
}
