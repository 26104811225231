import { Pipe, PipeTransform } from "@angular/core";
import { AccountTransactionType } from "src/app/models/enums/account-transaction-type";

@Pipe({
    name: 'transactionTypeText'
  })
  export class TransactionTypeTextPipe implements PipeTransform {
  
    transform(value: AccountTransactionType, ...args: number[]): string {
      switch (value) {
        case AccountTransactionType.Debt:
          return 'Borç'
        case AccountTransactionType.Receivable:
          return 'Alacak'
        default:
          return 'Durum'
      }
    }
  
  }
  