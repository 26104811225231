import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaseCreateModalModule } from './case-create-modal/case-create-modal.module';
import { CaseTransferModalModule } from './case-transfer-modal/case-transfer-modal.module';
import { AllCaseBalancesExportModule } from './all-case-balances-export/all-case-balances-export.module';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CaseCreateModalModule,
    CaseTransferModalModule,
    AllCaseBalancesExportModule
  ]
})
export class CasesModule { }
