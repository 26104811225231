import { CustomerBalanceDTO } from "../customer/customerBalanceDTO"

export class CustomerDebtReceivableListModel{
    /**
     *
     */
    constructor() {
        this.customerBalances = [];        
    }
    customerId?: number;
    customerName?: string;
    customerBalances?: CustomerBalanceDTO[];
}