import { YesNoPipe } from "./yes-no.pipe";
import { CustomCurrencyPipe } from "./custom-currency.pipe";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CurrencyIconPipe } from "./currency-icon.pipe";
import { TransactionTypePipe } from "./transaction-type.pipe";
import { ExchangeStatePipe } from "./exchange-state.pipe";
import { ReasonTypePipe } from "./reason-type.pipe";
import { SubTypeMilyemPipe } from "./sub-type-milyem.pipe";
import { SubTypeDisplayPipe } from "./sub-type-display.pipe";
import { DateAgoPipe } from "./date-ago.pipe";
import { CurrencyWeightPipe } from "./currency-weight.pipe";
import { ExchangeItemStatePipe } from "./exchange-item-state.pipe";
import { TransactionTypeTextPipe } from "./transaction-type-text.pipe";
import { CurrencyCastEnumPipe } from "./currency-cast-enum.pipe";
import { EntryExitPipe } from "./entry-exit.pipe";
import { KeySubTypePipe } from "./key-sub-type.pipe";
import { CurrencyAmountPipe } from "./currency-amount.pipe";
import { MaxCharAfterCommaPipe } from "./max-char-after-comma.pipe";
import { CostCalculationTypePipe } from "./cost-calculation-type.pipe";
import { ReasonTypeShortPipe } from "./reason-type-short.pipe";
import { SubTypeShortPipe } from "./sub-type-short.pipe";
import { DictionaryTypePipe } from "./dictionary-type.pipe";
import { GemStoneClarityPipe } from "./gem-stone-clarity.pipe";
import { GemStoneColorPipe } from "./gem-stone-color.pipe";
import { GemStoneShapePipe } from "./gem-stone-shape.pipe";
import { GemStoneTypePipe } from "./gem-stone-type.pipe";
import { RawMaterialTypePipe } from "./raw-material-type.pipe";
import { SertificatePipe } from "./sertificate.pipe";
import { ProductionProcessPipe } from "./production-process.pipe";
import { ProductionStatePipe } from "./production-state.pipe";
import { RawMaterialStatePipe } from "./raw-material-state.pipe";
import { CollectionTypePipe } from "./collection-type.pipe";
import { CustomCurrencyXlPipe } from "./custom-currency-xl.pipe";
import { RawMaterialLaborTypePipe } from "./raw-material-labor-type.pipe";
import { UserRolePipe } from "./user-role.pipe";
import { KeyToSubTypePipe } from './key-to-sub-type.pipe';
import { ExpenseIncomePipe } from './expense-income.pipe';
import { CurrencyColorCodePipe } from './currency-color-code.pipe';
import { FourDigitPipe } from './four-digit.pipe';
import { CalculationTypePipe } from './calculation-type.pipe';
import { ExchangeItemStateForNumberPipe } from './exchange-item-state-for-number.pipe';
import { PriceCurrencyPipe } from './price-currency.pipe';
import { ProductStatePipe } from './product-state.pipe';
import { ProductStateTextPipe } from './product-state-text.pipe';
import { ReceiptMilyemPipe } from './receipt-milyem.pipe';
import { CostAmountPipe } from "./cost-amount.pipe";
import { CostStateIconPipe } from './cost-state-icon.pipe';

@NgModule({
  declarations: [
    CustomCurrencyPipe,
    CustomCurrencyXlPipe,
    YesNoPipe,
    CurrencyIconPipe,
    TransactionTypePipe,
    TransactionTypeTextPipe,
    ExchangeItemStatePipe,
    ReasonTypePipe,
    SubTypeMilyemPipe,
    SubTypeDisplayPipe,
    ExchangeStatePipe,
    CurrencyWeightPipe,
    DateAgoPipe,
    CurrencyCastEnumPipe,
    EntryExitPipe,
    KeySubTypePipe,
    CurrencyAmountPipe,
    MaxCharAfterCommaPipe,
    CostCalculationTypePipe,
    ReasonTypeShortPipe,
    SubTypeShortPipe,
    DictionaryTypePipe,
    GemStoneClarityPipe,
    GemStoneColorPipe,
    GemStoneShapePipe,
    RawMaterialStatePipe,
    GemStoneTypePipe,
    RawMaterialTypePipe,
    SertificatePipe,
    ProductionProcessPipe,
    ProductionStatePipe,
    CollectionTypePipe,
    RawMaterialLaborTypePipe,
    UserRolePipe,
    KeyToSubTypePipe,
    ExpenseIncomePipe,
    CurrencyColorCodePipe,
    FourDigitPipe,
    CalculationTypePipe,
    ExchangeItemStateForNumberPipe,
    PriceCurrencyPipe,
    ProductStatePipe,
    ProductStateTextPipe,
    ReceiptMilyemPipe,
    CostAmountPipe,
    CostStateIconPipe,
  ],
  imports: [CommonModule],
  exports: [
    CurrencyIconPipe,
    CustomCurrencyPipe,
    CustomCurrencyXlPipe,
    YesNoPipe,
    TransactionTypePipe,
    TransactionTypeTextPipe,
    ExchangeItemStatePipe,
    ReasonTypePipe,
    SubTypeMilyemPipe,
    SubTypeDisplayPipe,
    ExchangeStatePipe,
    CurrencyWeightPipe,
    DateAgoPipe,
    CurrencyCastEnumPipe,
    EntryExitPipe,
    KeySubTypePipe,
    CurrencyAmountPipe,
    MaxCharAfterCommaPipe,
    CostCalculationTypePipe,
    ReasonTypeShortPipe,
    SubTypeShortPipe,
    DictionaryTypePipe,
    GemStoneClarityPipe,
    GemStoneColorPipe,
    GemStoneShapePipe,
    RawMaterialStatePipe,
    GemStoneTypePipe,
    RawMaterialTypePipe,
    SertificatePipe,
    ProductionProcessPipe,
    ProductionStatePipe,
    CollectionTypePipe,
    RawMaterialLaborTypePipe,
    UserRolePipe,
    KeyToSubTypePipe,
    ExpenseIncomePipe,
    CurrencyColorCodePipe,
    FourDigitPipe,
    CalculationTypePipe,
    ExchangeItemStateForNumberPipe,
    PriceCurrencyPipe,
    ProductStatePipe,
    ProductStateTextPipe,
    ReceiptMilyemPipe,
    CostAmountPipe,
    CostStateIconPipe,
  ],
})
export class PipesModule {}
