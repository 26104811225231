import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input } from '@angular/core';
import { PreviousRouteService } from 'src/app/services/previous-route.service';

@Component({
  selector: 'app-page-title',
  templateUrl: './pagetitle.component.html',
  styleUrls: ['./pagetitle.component.scss']
})
export class PagetitleComponent implements OnInit {

  @Input() breadcrumbItems;
  @Input() title: string;
  isLogin = false;
  modalServiceForHtml;
  routeService;
  constructor(private previousRouteService: PreviousRouteService, private modalService: NgbModal) {
    this.modalServiceForHtml = modalService;
    this.routeService = previousRouteService;
    this.isLogin = this.previousRouteService.getIsLogin;

  }

  ngOnInit() {
  }

  loadPreviousPage(){
    let isModal = this.previousRouteService.getIsModal 
    if (!this.isLogin) {
      if (isModal){
        window.history.back()
        window.history.back()
      }
      else{
        window.history.back()
      }  
    }
    
  }

}
