import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "receiptMilyem",
})
export class ReceiptMilyemPipe implements PipeTransform {
  transform(value: number = 1000): string {
    if(value != null && value < 1000)
      return "0." + value.toString()
    else
     return "1.000";
  }
}
