import { Component, Input, OnInit } from '@angular/core';
import { Trackable } from 'src/app/core/models/trackable';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {

  @Input() trackable: Trackable
  single: boolean = true;
  constructor() { }

  ngOnInit(): void {
    
  }

}
