import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: 'access-right', loadChildren: () => import('./access-right/access-right.module').then(m=>m.AccessRightModule)},
  { path: 'account-transaction', loadChildren: () => import('./account-transaction/account-transaction.module').then(m=>m.AccountTransactionModule)},
  { path: 'branch', loadChildren: () => import('./branch/branch.module').then(m=>m.BranchModule)},
  { path: 'branch-account', loadChildren: () => import('./branch-account/branch-account.module').then(m=>m.BranchAccountModule)},
  { path: 'customer', loadChildren: () => import('./customer/customer.module').then(m=>m.CustomerModule)},
  { path: 'customer-account', loadChildren: () => import('./customer-account/customer-account.module').then(m=>m.CustomerAccountModule)},
  { path: 'user', loadChildren: () => import('./user/user.module').then(m=>m.UserModule)},
  { path: 'parities', loadChildren: () => import('./parities/parities.module').then(m=>m.ParitiesModule)},
  { path: 'category', loadChildren: () => import('./product-group/product-group.module').then(m=>m.ProductGroupModule)},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SpecificationsRoutingModule { }
