import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DictionaryCreateModalModule } from './dictionary-create-modal/dictionary-create-modal.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    DictionaryCreateModalModule,
  ]
})
export class DictionariesModule { }
