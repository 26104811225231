import { ExchangeDetailComponent } from './exchange-detail/exchange-detail.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ExchangeListComponent } from './exchange-list/exchange-list.component';
import { ExchangeOperationComponent } from './exchange-operation/exchange-operation.component';
import { CustomCurrencyPipe } from 'src/app/shared/pipes/custom-currency.pipe';
import { HasRoleGuard } from 'src/app/core/guards/has-role.guard';
import { Role } from 'src/app/core/models/role.enum';
import { ExchangeCostsComponent } from './exchange-costs/exchange-costs.component';

const routes: Routes = [
  {path: 'list', component: ExchangeListComponent, canActivate: [HasRoleGuard], data: {allowedRoles: [Role.Wholesale, Role.Wholesale]}},
  {path: 'exchange-operation', component: ExchangeOperationComponent, canActivate: [HasRoleGuard], data: {allowedRoles: [Role.Wholesale]}},
  {path: 'detail/:id', component: ExchangeDetailComponent, canActivate: [HasRoleGuard], data: {allowedRoles: [Role.Wholesale]}},
  {path: 'costs', component: ExchangeCostsComponent, canActivate: [HasRoleGuard], data: {allowedRoles: [Role.Wholesale]}},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers:[CustomCurrencyPipe]
})
export class ExchangeRoutingModule { }
