<div class="modal-header">
  <h4 [ngClass]="modalTitleClass">
    <ng-container *ngIf="isDebtCollection">Tahsilat</ng-container>
    <ng-container *ngIf="!isDebtCollection">Ödeme</ng-container>
  </h4>
  <button type="button" class="btn-close text-white" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
  <p> {{modalBodyText}} </p>
  <form>
    <div class="mb-3" *ngIf="modalContent.currency === Currency.Silver">
      <label for="subType">Alt Birim</label>
      <div class="input-group">
        <select class="form-control" name="subType" id="subType" [(ngModel)]="modalContent.subType">
          <option *ngFor="let item of subTypes" [value]="item"> {{item | subTypeDisplay }} </option>
        </select>
      </div>
    </div>
    <div class="mb-3" >
      <label for="case">Kasa</label>
      <div class="input-group">
        <select class="form-control" name="case" id="case" [(ngModel)]="collection.itemList[0].caseId">
          <option *ngFor="let item of caseList" [value]="item.value"> {{item.text}} </option>
        </select>
      </div>
    </div>
    <div class="mb-3">
      <label for="description">Açıklama</label>
      <div class="input-group">
        <textarea name="description" id="description" [(ngModel)]="modalContent.description" class="form-control" cols="100" rows="2"></textarea>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" [ngClass]="buttonClass" (click)="submitCollection()">
    <span *ngIf="isDebtCollection">Tahsilat</span> 
    <span *ngIf="!isDebtCollection">Ödeme</span> 
  </button>
  <button type="button" class="btn btn-secondary" (click)="activeModal.close('Save click')">Vazgeç</button>
</div>