<!-- breadcrumb item -->
<div class="row">
  <div class="col-12">
    <div class="page-title-box d-flex align-items-center justify-content-between">
      <span class="align-item-center">
        <button [disabled]="isLogin" type="button" class="btn btn-sm btn-secondary m-2" (click)="loadPreviousPage()">
          <i class="fa fa-chevron-left"></i>
          Geri
        </button>
       
        <h4 class="d-inline m-2 font-size-18">{{ title }} </h4>
        

      </span>
      <div class="page-title-right">
        <ol class="breadcrumb m-0">
          <ng-container *ngFor="let item of breadcrumbItems">
            <li class="breadcrumb-item" *ngIf="!item.active"><a href="{{item.href}}">{{ item.label }}</a>
            </li>
            <li class="breadcrumb-item active" *ngIf="item.active"> {{ item.label }}
              
            </li>
          </ng-container>
        </ol>
      </div>
    </div>
  </div>
</div>
