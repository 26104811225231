import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { NgbCollapseModule, NgbDatepickerModule, NgbTimepickerModule, NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { PagetitleComponent } from './pagetitle/pagetitle.component';
import { LoaderComponent } from './loader/loader.component';
import { PaginationComponent } from './pagination/pagination.component';
import { BaseComponent as BaseCalenderComponent } from '@fullcalendar/core';
import { BaseListComponent } from './base-list/base-list.component';
import { BaseComponent } from './base/base.component';
import { RoleBasedComponent } from './role-based-component/role-based-component.component';
import { BasicSpinnerComponent } from './basic-spinner/basic-spinner.component';
import { ListSpinnerComponent } from './list-spinner/list-spinner.component';
@NgModule({

    declarations: [PagetitleComponent, LoaderComponent, PaginationComponent, BaseComponent, BaseListComponent, RoleBasedComponent, BasicSpinnerComponent, ListSpinnerComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    NgbDropdownModule,
    NgbTooltipModule,

  ],
  exports: [PagetitleComponent, LoaderComponent, PaginationComponent, NgbTooltipModule, BaseListComponent, RoleBasedComponent, BasicSpinnerComponent, ListSpinnerComponent]
})
export class ComponentsModule { }
