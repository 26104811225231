    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Widget Listesi</h4>
    </div>
    <div class="modal-body">
        <div class="text-end">
            <span style="padding-right: 20px;"><i class="fas fa-eye"></i></span>
        </div>
    <ul class="list-group list-group-flush" *ngIf="widgets">
        <ul class="list-group list-group-flush">
            <li class="list-group-item" *ngFor="let item of widgets" (click)="toggleChecked(checkbox, item)">
                <div class="form-check d-flex justify-content-between">
                    <label class="form-check-label d-block" for="flexCheckDefault"> {{item.text}} </label>
                    <input  class="form-check-input d-block" #checkbox type="checkbox" [(ngModel)]="item.selected" (change)="toggleChecked(checkbox, item)" id="flexCheckDefault">
                </div>
            </li>
        </ul>
      </ul>
    </div>
<div class="modal-footer">
    <button class="btn btn-secondary" ngbAutofocus  (click)="activeModal.close()">Vazgeç</button>
    <button class="btn btn-primary" (click)="confirm()">Onayla</button>
</div>
