<h5 class="header text-center"> Müşteri Hesap Dökümü </h5>
<h6 class="header text-center" *ngIf="customer.name != ''">{{customer.name}} </h6>
<div class="d-flex justify-content-end header-info" *ngIf="filterType != 'Tümü'">
  <span *ngIf="filter.beginDate"> <strong> Baş. Tar. </strong> {{ filter.beginDate | date:'dd/MM/yyyy'}} </span>
</div>
<div class="d-flex justify-content-between header-info">
  <span> <strong>Tarih Aralığı:</strong> {{filterType}}</span>
  <span *ngIf="filterType != 'Tümü' && filter.endDate"> <strong> Son. Tar. </strong> {{ filter.endDate |
    date:'dd/MM/yyyy HH:mm'}}</span>
  <span *ngIf="filterType == 'Tümü' || !filter.endDate"> <strong> Tarih: </strong> {{ filter.endDate | date:'dd/MM/yyyy
    HH:mm'}} <span *ngIf="!filter.endDate">{{date | date:'dd/MM/yyyy HH:mm'}}</span> </span>
</div>
<div>
  <ng-container *ngIf="transactions$ | async">
  <table class="table mb-0 mt-3" *ngIf="transactions.length > 0">
    <thead>
      <tr>
        <th class="text-start">ISL</th>
        <th class="text-start">KODU</th>
        <th class="text-end">MİKTAR</th>
        <th class="text-end">MİLYEM</th>
        <th class="text-end">HAS/FİY</th>
        <th class="text-end">TUTAR</th>
        <th class="text-center">BİRİM</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let item of transactions; let i = index">
        <tr *ngIf="item.exchangeItem == null">
          <td class="text-start"><span *ngIf="item.type == Type.Debt">C</span> <span
              *ngIf="item.type == Type.Receivable">G</span></td>
          <td class="text-start">{{item.subType | subTypeShort: item.currency}}</td>
          <td class="text-end">{{item.amount | currencyAmount: item.currency: item.subType: true }}</td>
          <td class="text-end">{{item.milyem | receiptMilyem}}</td>
          <td class="text-end">1.000</td>
          <td class="text-end">{{item.amount | currencyAmount: item.currency: item.subType: true }}</td>
          <td class="text-center">{{item.subType | subTypeShort: item.currency}}</td>
        </tr>
        <tr *ngIf="item.exchangeItem != null">
          <td class="text-start"><span *ngIf="item.type == Type.Debt">C</span> <span
              *ngIf="item.type == Type.Receivable">G</span> EXC</td>
          <td class="pe-0 text-start">{{item.exchangeItem.exchangeSubType | subTypeShort:
            item.exchangeItem.exchangeCurrency}}/{{item.exchangeItem.paymentSubType | subTypeShort:
            item.exchangeItem.paymentCurrency}}</td>
          <td class="text-end">{{item.exchangeItem.exchangeAmount | currencyAmount: item.exchangeItem.exchangeCurrency:
            item.exchangeItem.exchangeSubType: true }}</td>
          <td class="text-end">{{item.milyem | receiptMilyem}}</td>
          <td class="text-end">{{item.exchangeItem.parity | currencyAmount: item.currency: item.subType: true}}</td>
          <td class="text-end">{{item.amount | currencyAmount: item.currency: item.subType: true }}</td>
          <td class="text-center">{{item.subType | subTypeShort: item.currency}}</td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <div class="text-center m-5" *ngIf="transactions.length == 0">
    <span><i class="fa fa-exclamation-circle"></i> Hesap hareketi bulunamadı.</span>
  </div>
</ng-container>
  <ng-container *ngIf="customer$ | async">
    <div class="customer-balance-summary mt-3 mb-0"> BAKİYE SON DURUMU </div>
    <table class="table mb-0 mt-1">
      <thead class="border-bottom">
        <tr>
          <th class="text-start">BİRİM</th>
          <th class="text-end">BORÇ</th>
          <th class="text-end">ALACAK</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let balanceDTO of customer.mergedBalanceList | keyvalue">
          <ng-container *ngIf="balanceDTO.value != 0">
            <td class="text-start">HAS</td>
            <td class="text-end"><span *ngIf="balanceDTO.value < 0">{{ balanceDTO.value * -1 |
                currencyAmount: balanceDTO.key}}</span></td>
            <td class="text-end"><span *ngIf="balanceDTO.value > 0">{{ balanceDTO.value |
                currencyAmount: balanceDTO.key}}</span></td>
          </ng-container>
        </tr>
        <tr *ngFor="let item of customer.balanceList">
          <td class="text-start">{{item.subType | subTypeShort: item.currency }}</td>
          <td class="text-end"><span *ngIf="item.finalBalance < 0">{{item.finalBalance * -1 | currencyAmount:
              item.currency:
              item.subType: true }}</span></td>
          <td class="text-end"><span *ngIf="item.finalBalance > 0">{{item.finalBalance | currencyAmount: item.currency:
              item.subType: true }}</span></td>
        </tr>
      </tbody>
    </table>
  </ng-container>
  <!-- <div class="barcode-font"> {{groupId}} </div> -->
  <p class="sarraf"> sarraf.io </p>
  <div class="groupId text-center">Bilgilendirme amaçlıdır.<br> Resmi belge olarak kullanılamaz. Mali değeri yoktur.
  </div>
</div>

<div class="row mt-4"></div>
