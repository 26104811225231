import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule as SharedComponentsModule } from 'src/app/shared/components/components.module';

import { RetailRoutingModule } from './retail-routing.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RetailRoutingModule,
    SharedComponentsModule,
  ]
})
export class RetailModule { }
