import { Currency } from 'src/app/models/enums/currency';
import { AccountTransactionType } from '../enums/account-transaction-type';
import { CurrencySubTypes } from '../enums/currency-sub-types';
import { AccountTransactionReason } from '../enums/account-transaction-reason';
import { BaseFilter } from './base-filter';
export class AccountTransactionFilter  extends BaseFilter {
    accountId?: number;
    branchId?: number;
    customerBranchId?: number;
    caseId?: number;
    expenseTypeId?: number
    customerId?: number;
    reasons?: AccountTransactionReason[]
    amount?: number;
    currency?: Currency;
    subType?: CurrencySubTypes;
    maxRecordCount?: number;
    type?: AccountTransactionType;
}
