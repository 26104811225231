import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { ApplicationUserService } from '../services/application-user.service';
import { Role } from '../models/role.enum';

@Directive({
  selector: '[hasRole]'
})
export class HasRoleDirective {
  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private service: ApplicationUserService) {
   }

  @Input() set hasRole(allowedRoles: Role[]) {
    const roleMatches = this.service.currentUserValue.roles.findIndex(role => allowedRoles.indexOf(role) !== -1);
      if(roleMatches < 0)
        this.viewContainer.clear();
      else
        this.viewContainer.createEmbeddedView(this.templateRef);
  }
}
