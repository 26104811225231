import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerDebtReceivableListComponent } from './customer-debt-receivable-list.component';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [
    CustomerDebtReceivableListComponent
  ],
  imports: [
    CommonModule,
    PipesModule,
    FormsModule,
    RouterModule
  ],
  exports: [CustomerDebtReceivableListComponent]
})
export class CustomerDebtReceivableListModule { }
