import { Component, OnInit } from "@angular/core";
import { NgbModal, NgbNavChangeEvent } from "@ng-bootstrap/ng-bootstrap";
import { Case } from "src/app/models/case/case";
import { AccountTransactionFilter } from "src/app/models/filters/account-transaction-filter";
import {
  AccessoriesSubTypes,
  CurrencySubTypes,
  GoldSubTypes,
  OtherCurrencyList,
  SilverSubTypes,
} from "src/app/models/enums/currency-sub-types";
import { Currency } from "src/app/models/enums/currency";
import { AccountTransactionCreateModalComponent } from "src/app/ui/components/account-transactions/account-transaction-create-modal/account-transaction-create-modal.component";
import { AlertService } from "src/app/services/alert.service";
import { Subscription } from "rxjs/internal/Subscription";
import { RootAccountBalanceDTO } from "src/app/models/account/root-account-balance-dto";
import { Account } from "src/app/models/account/account";
import { AccountTransaction } from "src/app/models/account-transaction/account-transaction";
import { BaseComponent } from "src/app/shared/components/base/base.component";
import { CaseService } from "src/app/services/case.service";
import { AccountTransactionService } from "src/app/services/account-transaction.service";
import { AccountService } from "src/app/services/account.service";
import { FilterAreas } from "src/app/models/filters/filter-areas";
@Component({
  selector: "app-case-counting",
  templateUrl: "./case-counting.component.html",
  styleUrls: ["./case-counting.component.scss"],
})
export class CaseCountingComponent extends BaseComponent implements OnInit {
  breadCrumbItems: Array<{}> = [
    { label: "Kasa Sayımı" },
    { label: "Kasa", active: true },
  ];
  filter: AccountTransactionFilter = {};
  showedDate = new Date();
  branch = JSON.parse(localStorage.getItem("currentBranch"));
  caseList: Case[] = [];
  defaultCaseId: number;
  activeCase = 1;
  activeAccount = 0;
  caseSubscription: Subscription;
  goldSubTypes = GoldSubTypes;
  accessoriesSubTypes = AccessoriesSubTypes;
  silverSubTypes = SilverSubTypes;
  otherCurrencyList = OtherCurrencyList;
  currencyEnum = Currency;
  subTypeEnum = CurrencySubTypes;
  tlBalance: number;
  tlAccountId;
  usdBalance: number;
  usdAccountId;
  euroBalance: number;
  euroAccountId;
  goldBalance: number;
  goldAccountId;
  defaultCurrency = Currency.Tl;
  branchBalanceList: RootAccountBalanceDTO[] = null;
  models: AccountTransaction[] = [];
  branchAccounts: Account[] = [];
  selectedCase: number;
  latestDay: boolean = false;
  filterAreas: FilterAreas = {
    case: false,
    subType: true,
    customer: true,
    state: true,
    startDate: true,
    amount: true,
    endDate: true,
    creater: true,
    clearAll: true,
  };
  constructor(
    private caseService: CaseService,
    private accountTransactionService: AccountTransactionService,
    private accountService: AccountService,
    private alertService: AlertService,
    private modalService: NgbModal
  ) {
    super();
  }

  ngOnInit(): void {
    this.filter.branchId = JSON.parse(localStorage.getItem("currentBranch")).id;
    //this.filter.beginDate = new Date();
    //this.filter.beginDate.setHours(0,0,1,0)
    this.filter.endDate = new Date();
    this.filter.endDate.setHours(23, 59, 0, 0);
    if (this.filter.endDate.toDateString() == new Date().toDateString())
      this.latestDay = true;
    this.filter.currency = this.defaultCurrency;
    this.loadCaseList();
    this.caseSubscription = this.caseService.refreshIndex.subscribe((res) => {
      this.loadCaseList();
    });
    this.accountTransactionService.refreshIndex.subscribe((res) =>
      this.loadBranchBalanceList(this.selectedCase)
    );
  }

  nextDate() {
    //this.filter.beginDate.setDate( this.filter.beginDate.getDate() + 1  )

    if (this.filter.endDate.toDateString() == new Date().toDateString())
      this.latestDay = true;
    else this.filter.endDate.setDate(this.filter.endDate.getDate() + 1);

    if (!this.latestDay) {
      this.activeCase = this.caseList[0].id;
      this.defaultCaseId = this.caseList[0].id;
      this.filter.caseId = this.defaultCaseId;
      this.filter = Object.assign({}, this.filter);
      this.showedDate = JSON.parse(JSON.stringify(this.filter.endDate));
      this.loadBranchBalanceList(this.activeCase);
      this.loadBranchBalanceList(this.caseList[0].id);
      if (this.filter.endDate.toDateString() == new Date().toDateString())
        this.latestDay = true;
    }
  }

  previousDate() {
    //this.filter.beginDate.setDate(this.filter.beginDate.getDate() - 1)
    this.filter.endDate.setDate(this.filter.endDate.getDate() - 1);
    if (this.filter.endDate.toDateString() != new Date().toDateString())
      this.latestDay = false;
    this.activeCase = this.caseList[0].id;
    this.defaultCaseId = this.caseList[0].id;
    this.filter.caseId = this.defaultCaseId;
    this.filter = Object.assign({}, this.filter);
    this.showedDate = JSON.parse(JSON.stringify(this.filter.endDate));
    this.loadBranchBalanceList(this.activeCase);
  }

  loadCaseList() {
    this.caseService
      .getAllWoutPaging({
        branchId: this.branch.id,
      })
      .toPromise()
      .then((res) => {
        this.caseList = res.data.Items;
        if (this.caseList.length > 0) {
          this.activeCase = this.caseList[0].id;
          this.defaultCaseId = this.caseList[0].id;
          this.filter.caseId = this.defaultCaseId;
          this.loadBranchBalanceList(this.activeCase);
        }
      });
  }

  caseChanged(changeEvent: NgbNavChangeEvent) {
    this.selectedCase = parseInt(changeEvent.nextId);
    this.filter.caseId = this.selectedCase;
    this.filter.caseId = JSON.parse(JSON.stringify(this.selectedCase));
    this.loadBranchBalanceList(this.selectedCase);
  }

  loadBranchBalanceList(caseId: number) {
    this.accountTransactionService
      .getCaseBalances({
        caseId: caseId,
        branchId: this.filter.branchId,
        endDate: this.filter.endDate,
      })
      .toPromise()
      .then((res) => {
        this.branchBalanceList = res.data.balanceList;
      });
  }

  loadBranchAccounts() {
    this.accountService
      .getAllWoutPaging({
        branchId: this.branch.id,
      })
      .toPromise()
      .then((res) => {
        this.branchAccounts = res.data.Items;
      });
  }

  loadTransactionsByCurrency(currency: Currency) {
    this.filter.type = null;
    this.filter.customerId = null;
    this.filter.currency = currency;
    // JSON.parse(JSON.stringify(currency));
  }

  openAccountTransactionAddModal(
    currency: Currency,
    subType: CurrencySubTypes
  ) {
    const modalRef = this.modalService.open(
      AccountTransactionCreateModalComponent
    );
    modalRef.componentInstance.branchPreloaded = this.branch;
    modalRef.componentInstance.currencyPreloaded = currency;
    modalRef.componentInstance.subTypePreloaded = subType;
    modalRef.componentInstance.isCustomerTransaction = false;
  }
}
