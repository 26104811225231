import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApplicationUserCreateComponent } from './application-user-create.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    ApplicationUserCreateComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule
  ],
  exports: [
    ApplicationUserCreateComponent
  ]
})
export class ApplicationUserCreateModule { }
