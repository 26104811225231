<!-- ========== Left Sidebar Start ========== -->

<ng-template #contentTemplate>

  <!--- Sidemenu -->
  <div id="sidebar-menu">
    <!-- Left Menu Start -->
    <ul class="metismenu list-unstyled" id="side-menu" #sideMenu>
      <!-- {{menuItems}} -->
      <li class="menu-title">Menü</li>
      <ng-container *ngFor="let item of menuItems">
        <li *ngIf="item.isActive">
          <a *ngIf="hasItems(item)" href="javascript:void(0);" class="is-parent"
            [ngClass]="{'has-arrow': !item.badge, 'has-dropdown': item.badge}">
            <i class="bx {{item.icon}}" *ngIf="item.icon"></i>
            <span> {{ item.label }}</span>
            <span class="badge rounded-pill float-end bg-{{item.badge.variant}}" *ngIf="item.badge">{{item.badge.text}}</span>
          </a>

          <a [routerLink]="item.link" *ngIf="!hasItems(item)" class="side-nav-link-ref" routerLinkActive="active">
            <i class="bx {{ item.icon }}" *ngIf="item.icon"></i>
            <span> {{ item.label }}</span>
            <span class="badge rounded-pill bg-{{item.badge.variant}} float-end" *ngIf="item.badge">{{item.badge.text}}</span>
          </a>

          <ul *ngIf="hasItems(item)" class="sub-menu" aria-expanded="false">
            <ng-container *ngFor="let subItem of item.childrenMenuItem">
            <li *ngIf="subItem.isActive" >
              <a [routerLink]="subItem.link" *ngIf="!hasItems(subItem)" class="side-nav-link-ref"
                [attr.data-parent]="subItem.parentMenuItemId" routerLinkActive="active">
                {{ subItem.label }}
              </a>
              <a *ngIf="hasItems(subItem)" class="side-nav-link-a-ref has-arrow" href="javascript:void(0);"
                [attr.data-parent]="subItem.parentMenuItemId">
                {{ subItem.label }}
              </a>
              <ul *ngIf="hasItems(subItem)" class="sub-menu mm-collapse" aria-expanded="false">
                <li *ngFor="let subItem of subItem.childrenMenuItem">
                  <a [attr.data-parent]="subItem.parentMenuItemId" [routerLink]="subItem.link" routerLinkActive="active"
                    class="side-nav-link-ref">
                    {{ subItem.label }}
                  </a>
                </li>
              </ul>
            </li>
          </ng-container>

          </ul>
        </li>
      </ng-container>
    </ul>
  </div>
</ng-template>
<!-- Sidebar -->

<div class="vertical-menu">
  <ngx-simplebar class="h-100" #componentRef>
    <div *ngIf="!isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
    <div *ngIf="isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
  </ngx-simplebar>
</div>
<!-- Left Sidebar End -->