import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { ComponentsModule as SharedComponentsModule } from 'src/app/shared/components/components.module';
import { BranchBalanceComponent } from './branch-balance.component';


@NgModule({
  declarations: [
    BranchBalanceComponent
  ],
  imports: [
    CommonModule,
    PipesModule,
    SharedComponentsModule,

  ],
  exports: [BranchBalanceComponent]
})
export class BranchBalanceModule { }
