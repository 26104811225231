import { IConfig, NgxMaskModule } from 'ngx-mask';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';

import {  NgbModalModule, NgbModule, NgbToastModule} from '@ng-bootstrap/ng-bootstrap';

import { ExtrapagesModule } from './extrapages/extrapages.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ToastrModule } from 'ngx-toastr';

// import { initFirebaseBackend } from './authUtils';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ErrorInterceptor } from './core/helpers/error.interceptor';
import { JwtInterceptor } from './core/helpers/jwt.interceptor';
import { ModalViewComponent } from 'src/app/shared/components/modal-view/modal-view.component';
import { LandingModule } from './landing/landing.module';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { BarcodePrintModule } from './ui/layouts/printing/barcode-print/barcode-print.module';
import { LaserPrintModule } from './ui/layouts/printing/laser-print/laser-print.module';
import { UiModule } from './ui/ui.module';
import { PreviousRouteService } from './services/previous-route.service';


// if (environment.defaultauth === 'firebase') {
//   initFirebaseBackend(environment.firebaseConfig);
// } else {
//   // tslint:disable-next-line: no-unused-expression
//   FakeBackendInterceptor;
// }

export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

const maskConfig: Partial<IConfig> = {
  validation: false,
};
@NgModule({
  declarations: [
    AppComponent,
    ModalViewComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    // LayoutsModule,
    LandingModule,
    AppRoutingModule,
    ExtrapagesModule,
    UiModule,
    ScrollToModule.forRoot(),
    NgbModule,
    BarcodePrintModule,
    LaserPrintModule,
    NgbToastModule,
    ToastrModule.forRoot({
      closeButton: true,
    }),
    NgxMaskModule.forRoot(maskConfig)

  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    PreviousRouteService
  ],
})
export class AppModule { }
