import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CaseFilter } from 'src/app/models/filters/case-filter';
import { Case } from 'src/app/models/case/case';
import { BaseService } from 'src/app/core/services/base.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseResponse } from 'src/app/core/models/responses/base-response';
import { CaseTransferItem } from '../models/case/case-transfer-item';

@Injectable({
  providedIn: 'root'
})
export class CaseService extends BaseService<Case, CaseFilter, number> {

  apiUrl = environment.urlAddress + '/Case'
  constructor(private http: HttpClient) {
    super(http, environment.urlAddress + '/Case')
   }

   getDefaultCaseByBranchId(branchId: number): Observable<BaseResponse<Case>>{
    return this.http.get<BaseResponse<Case>>(this.apiUrl + '/default/' + branchId)
   }

   transferToOtherCase(request: CaseTransferItem): Observable<BaseResponse<boolean>>{
    return this.http.post<BaseResponse<boolean>>(this.apiUrl + '/transfer-to-other-case', request);
   }
}




