    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Yeni Hesap Hareketi</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <div *ngIf="isCustomerTransaction">
          <div class="mb-3">
            <label class="d-block"> Hareket Tipi </label>
            <div class="form-check form-check-inline">
              <input class="form-check-input" [(ngModel)]="formModel.type" (change)="isDebt = false;" type="radio" id="receivable" [value]="0">
              <label class="form-check-label" for="receivable">Alacak</label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" [(ngModel)]="formModel.type" (change)="isDebt = true;" type="radio" name="type" id="debt" [value]="1">
                <label class="form-check-label" for="debt">Borç</label>
            </div>
          </div>
          <div class="mb-3" *ngIf="customerPreloaded != null">
            <label for="customer">Müşteri</label>
            <div class="input-group">
              <input id="customer" class="form-control" type="text" [ngModel]="customerPreloaded.name" (ngModel)="formModel.customerId" disabled >
            </div>
          </div>
          <div class="mb-3" *ngIf="customerPreloaded == null">
            <label for="customer">Müşteri</label>
            <div class="input-group">
              <input
              id="typeahead-focus"
              type="text"
              class="form-control"
              [(ngModel)]="formModel.customerId"
              name="selectItemModel"
              [ngbTypeahead]="search"
              placeholder="Müşteriler..."
              [resultFormatter]="resultFormatBandListValue"
              [inputFormatter]="inputFormatBandListValue"
              (selectItem)="selectedCustomer($event)" />
            </div>
          </div>
      </div>
      <div *ngIf="!isCustomerTransaction">
        <div class="mb-3" *ngIf="branchPreloaded != null">
          <label for="branch">Şube</label>
          <div class="input-group">
           <input id="branch" class="form-control" type="text" [ngModel]="branchPreloaded.name"  (ngModel)="formModel.branchId" disabled >
          </div>
        </div>
      </div>
      <div *ngIf="!isCustomerTransaction">
        <div class="mb-3" *ngIf="branchPreloaded != null">
          <label for="case">Kasa</label>
          <div class="input-group">
            <select class="form-control" name="case" id="case" [(ngModel)]="formModel.caseId">
              <option *ngFor="let item of caseList" [value]="item.value"> {{item.text}} </option>
            </select>
          </div>
        </div>
      </div>
      <!-- Currency Start -->
        <div class="mb-3" *ngIf="currencyPreloaded == null" >
            <label for="currency">Birim</label>
          <div class="input-group">
              <select [(ngModel)]="formModel.currency" (change)="currencyChanged()" name="currency" id="currency" class="form-control">
                  <option *ngFor="let item of currencyList" [value]="item"> {{item | customCurrency}} </option>
              </select>
            </div>
        </div>
        <div class="mb-3" *ngIf="currencyPreloaded != null">
          <label for="currency">Birim</label>
          <input id="currency" class="form-control" type="text" [ngModel]="currencyPreloaded | customCurrency" (ngModel)="formModel.currency" disabled >
        </div>
        <!-- Currency End -->

        <div class="row">
          <!-- SubType Start -->
          <div class="col mb-3" *ngIf="subTypes?.length > 0 && subTypePreloaded == null">
            <label for="subType">Alt Birim</label>
            <div class="input-group">
                <select [(ngModel)]="formModel.subType" (change)="subTypeChanged()" name="subType" id="subType" class="form-control">
                    <option  *ngFor="let item of subTypes" [value]="item"> {{item | subTypeDisplay}} </option>
                </select>
            </div>
          </div>
          <div class="col mb-3" *ngIf="subTypes?.length > 0 && subTypePreloaded != null">
          <label for="subType">Alt Birim</label>
          <input type="text" class="form-control" [value]="subTypePreloaded" disabled>
          </div>
          <!-- SubType End -->

          <!-- Milyem Start -->
          <div class="col mb-3" *ngIf="formModel.currency == Currency.Gold ||formModel.currency == Currency.GoldNew || currencyPreloaded == Currency.Gold || currencyPreloaded == Currency.GoldNew">
            <label for="milyem">Milyem <span class="text-danger" ngbTooltip="Zorunlu alan">*</span> </label>
          <input id="milyem" class="form-control" [(ngModel)]="formModel.milyem" name="milyem" type="number" autocomplete="off">
          </div>
          <!-- Milyem End -->
        </div>


        <div class="mb-3">
          <label for="amount">Miktar <span class="text-danger me-1" ngbTooltip="Zorunlu alan">*</span>
          <span class="text-muted" *ngIf="formModel.amount">({{formModel.amount | currencyAmount: formModel.currency}})</span>
        </label>
        <div class="input-group">
          <input id="amount" class="form-control" name="amount" type="number" autocomplete="off" [(ngModel)]="formModel.amount" >
        </div>
      </div>

      <div class="mb-3">
        <label for="description">Açıklama</label>
        <div class="input-group">
          <textarea id="description" class="form-control" name="description" [(ngModel)]="formModel.description" rows="2" cols="100"></textarea>
        </div>
      </div>

    </div>
    <div class="modal-footer">
      <button *ngIf="!isCustomerTransaction" type="button" class="btn btn-primary" [disabled]="isDisabled" (click)="submit()">Kaydet</button>
      <button *ngIf="isCustomerTransaction" type="button" class="btn btn-warning" (click)="addTransaction()">Ekle</button>
      <button type="button" class="btn btn-secondary" [disabled]="isDisabled" (click)="activeModal.close('Save click')">Geri Dön</button>
      <div>
      </div>
    </div>

