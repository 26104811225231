<ng-template #popTitle> İşlem Geçmişi </ng-template>
<!--
<ng-template #popContentSingle>
<p><strong> Oluşturan: </strong> {{trackable.createrName}}</p>
</ng-template>
<ng-template #popContentMultiple>
<p><strong> Oluşturan: </strong> {{trackable.createrName}}</p>
<p><strong>Son Güncelleyen: </strong> {{trackable.lastUpdaterName}}  </p>
</ng-template>
<i *ngIf="single" class="fas fa-user" [ngbPopover]="popContentSingle" [popoverTitle]="popTitle" ></i>
<i *ngIf="!single" class="fas fa-users" [ngbPopover]="popContentSingle" [popoverTitle]="popTitle" ></i> -->

<ng-template #popContent>
  <span class="d-block"><strong class="me-1"> Oluşturan: </strong> {{trackable.createrName}}</span>
  <div *ngIf="trackable.deleterId != null">
    <span class="d-block"><strong class="me-1"> Silen: </strong> {{trackable.deleterName}}</span>
    <span class="d-block"><strong class="me-1"> Sil. Tar.: </strong> {{trackable.deleteTime | date:'dd.MM.y HH:mm' }}</span>

  </div>
    <!-- <hr> -->
    <!-- <span class="d-block"><strong>Son Güncelleyen: </strong> {{trackable.lastUpdaterName}}  </span> -->
</ng-template>

<i class="fas fa-history" container="body" [ngbPopover]="popContent" [popoverTitle]="popTitle" ></i>
