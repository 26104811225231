<app-role-based [roles]="[Role.User]" [content]="content" (initState)="_fetchData()"></app-role-based>
<ng-template #content>
  <div class="card">
    <div class="card-body">
        <div class="card-title">
            Varlıkların Dağılımı
        </div>
        <p class="text-secondary m-0">Müşteri Bakiyeleri Dahil ( Has Cinsinden )</p>
        <div *ngIf="showChart" style="width: 600px;height:300px;" echarts [options]="negativeBarChart" class="demo-chart"></div>
    </div>
</div>
</ng-template>


