    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title" *ngIf="isCreate">Yeni Müşteri</h4>
      <h4 class="modal-title" id="modal-basic-title" *ngIf="!isCreate">Müşteriyi Güncelle</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="mb-3 col">
                <label for="description">Açıklama </label>
                <div class="input-group">
                    <input type="text" id="description" class="form-control" [(ngModel)]="request.description" placeholder="Açıklama">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="mb-3 col">
                <label for="name">Müşteri Adı <span class="text-danger" ngbTooltip="Zorunlu alan">*</span> </label>
                <span class="d-block text-muted">- Arama kısmında görünecek isimdir.</span>
                <div class="input-group">
                    <input type="text" id="name"  class="form-control" [(ngModel)]="request.name" placeholder="Müşteri Adı">
                </div>
            </div>
        </div>
        <div class="row">
          <div class="mb-3 col">
            <label for="displayName">Kısa Adı <span class="text-danger" ngbTooltip="Zorunlu alan">*</span></label>
            <span class="d-block text-muted">- Fiş, Barkod gibi alanlarda kullanılacak kısaltılmış isimdir.</span>
            <span class="d-block text-muted">- En fazla 5 karakter uzunluğunda olmalıdır.</span>
            <div class="input-group">
                <input type="text" id="displayName"class="form-control" [(ngModel)]="request.displayName" placeholder="Kısa Adı">
            </div>
        </div>
        </div>

        <div class="row">
            <div class="mb-3 col-md-6">
                <label for="taxAdministration">Vergi Dairesi </label>
                <div class="input-group">
                    <input type="text" id="taxAdministration" class="form-control" [(ngModel)]="request.taxAdministration" placeholder="Vergi Dairesi">
                </div>
            </div>

            <div class="mb-3 col-md-6">
                <label for="taxNumber">Vergi Numarası </label>
                <div class="input-group">
                    <input type="text" id="taxNumber" class="form-control" [(ngModel)]="request.taxNumber" placeholder="Vergi Numarası">
                </div>
            </div>
        </div>

        <div class="row">
            <div class="mb-3 col-md-6">
                <label for="phone">Telefon </label>
                <div class="input-group">
                    <input type="text" id="phone" class="form-control" [(ngModel)]="request.phone" placeholder="Telefon">
                </div>
            </div>
            <div class="mb-3 col-md-6">
                <label for="debtLimit" triggers="hover" ngbTooltip="Has cinsinden">İşlem Limiti</label>
                <div class="input-group">
                    <input type="number" id="debtLimit" class="form-control" [(ngModel)]="request.debtLimit" placeholder="İşlem Limiti">
                </div>
            </div>
        </div>

        <div class="row ps-3">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" [(ngModel)]="request.isActive" id="isActive">
            <label class="form-check-label" for="isActive">
              Aktif mi?
            </label>
          </div>
        </div>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-primary" [disabled]="isDisabled" (click)="onSubmit()">Kaydet</button>
        <button type="button" class="btn btn-secondary" [disabled]="isDisabled" (click)="activeModal.close('Save click')">Geri Dön</button>
    </div>
