import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetCustomizerModalComponent } from './widget-customizer-modal.component';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    WidgetCustomizerModalComponent
  ],
  imports: [
    CommonModule,
    NgbModalModule,
    FormsModule
  ],
  exports:[
    WidgetCustomizerModalComponent
  ]
})
export class WidgetCustomizerModalModule { }
