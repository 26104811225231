import { AfterContentInit, AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from "@angular/core";
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexResponsive,
  ApexXAxis,
  ApexLegend,
  ApexFill
} from "ng-apexcharts";
import { Role } from "src/app/core/models/role.enum";
import { CurrencySubTypesDTO } from "src/app/models/account-transaction/currency-subtypes-dto";
import { Branch } from "src/app/models/branch/branch";
import { ExpenseIncomeChartResponse } from "src/app/models/chart/expense-income-chart-response";
import { AccountTransactionReason } from "src/app/models/enums/account-transaction-reason";
import { ChartPeriodType } from "src/app/models/enums/chart-enums";
import { Currency } from "src/app/models/enums/currency";
import { ChartService } from "src/app/services/custom-chart.service";
import { BaseComponent } from "src/app/shared/components/base/base.component";
import { CurrencyAmountPipe } from "src/app/shared/pipes/currency-amount.pipe";


export type ChartOptions = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    dataLabels: ApexDataLabels;
    plotOptions: ApexPlotOptions;
    responsive: ApexResponsive[];
    xaxis: ApexXAxis;
    legend: ApexLegend;
    fill: ApexFill;
  };

@Component({
  selector: 'app-expense-income-chart',
  templateUrl: './expense-income-chart.component.html',
  styleUrls: ['./expense-income-chart.component.scss'],
  providers:[CurrencyAmountPipe]
})
export class ExpenseIncomeChartComponent extends BaseComponent {
    @Input() isIncome: boolean = false;
    @ViewChild("chart") chart: ChartComponent;
    initState: boolean;
    itemKey: string = Date.now().toString();
    currentBranch: Branch = JSON.parse(localStorage.getItem("currentBranch"));
    options;
    public chartOptions: Partial<ChartOptions>;
    ChartPeriodType = ChartPeriodType
    periodType = ChartPeriodType.Monthly;
    response: ExpenseIncomeChartResponse = {}
    Currency = Currency;
    currency = Currency.Tl;
    currencyWithSubTypes: CurrencySubTypesDTO[] = [];
    subType = null;
    currencyList = [Currency.Tl];
    subTypeList = []
    constructor(private service: ChartService) {
      super();
      this.options = {
        series: [
        ],
        chart: {
          width: 600,
          redrawOnParentResize: true,
          redrawOnWindowResize: true,
          type: "bar",
          height: 300,
          stacked: true,
          toolbar: {
            show: true
          },
          zoom: {
            enabled: true
          }
        },
        
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0
              }
            }
          }
        ],
        plotOptions: {
          bar: {
            horizontal: false
          }
        },
        xaxis: {
          type: "category",
          categories: []
        },
        legend: {
          position: "right",
          offsetY: 40
        },
        fill: {
          opacity: 1
        }
      };
    }

    changePeriod(periodType: ChartPeriodType){
        this.periodType = periodType;
        this.reloadData();
    }

    reloadData(){
      this.options = JSON.parse(JSON.stringify(this.options));
      this.options.series = [];
      this.options.xaxis.categories = [];
      this.currencyList = [];
      let _reason = this.isIncome ? AccountTransactionReason.BranchMoneyEntry : AccountTransactionReason.BranchMoneyExit;
      this.service.expenseIncome({branchId: this.currentBranch.id, chartPeriodType: this.periodType, currency: this.currency, subType: this.subType, reason: _reason}).toPromise().then(res => {
        this.currencyWithSubTypes = res.data.currencyWithSubTypes;
        res.data.currencyWithSubTypes.map(p => this.currencyList.push(p.currency))
        res.data.categories.map(p => this.options.xaxis.categories.push(p));
        res.data.series.map(p => {
        this.options.series.push({name: p.name, data: []})
        let temp = this.options.series.find(x => x.name == p.name);
        temp.data = p.data;
      })
      this.chartOptions = this.options;
    }
      );
    }

    currencyChanged(){
      let item = this.currencyWithSubTypes.find(p => p.currency == this.currency);
      if (item != null) {
        if (item.subTypes[0] != null) {
          this.subTypeList = item.subTypes;
          this.subType = item.subTypes[0];
        }
        else
          this.subTypeList = []
        this.reloadData();
      }
    }




}


