<div class="card">
  <list-spinner class="center-body p-3" [ngClass]="{'position-absolute': model?.data?.Items.length > 0}"
    [showSpinner]="Loading" [length]="model?.data?.Items.length"></list-spinner>
  <div class="table-responsive" [ngClass]="{'blur-content': Loading}">
    <table class="table table mb-0" *ngIf="model?.data?.Items.length > 0">
      <thead>
        <tr>
          <th class="text-center">Detay</th>
          <th>Sipariş No</th>
          <th>Müşteri</th>
          <!-- <th>Oluşt. Tarihi</th> -->
          <th>Teslim Tarihi</th>
          <th>Durum</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of model?.data?.Items">
          <td class="text-center"> <i class="fas fa-info-circle text-muted" container="body" [ngbTooltip]="item.description"></i>
          </td>
          <td><a routerLink="/manufacture/order/detail/{{item.id}}">{{item.code}}</a></td>
          <td>{{item.customerName}}</td>
          <!-- <td>{{item.receiveDate | date: "dd.MM.y"}}</td> -->
          <td>{{item.deliveryDate | date: "dd.MM.y" }}</td>
          <td><app-manufacture-order-state [state]="item.state"></app-manufacture-order-state></td>
        </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="model?.data">
    <app-pagination [blurOn]="Loading" [pageInfo]="model.data" [pageRequest]="request" (requestEvent)="reloadData()">
    </app-pagination>
  </div>
</div>