import { SubTypeDisplayPipe } from "./../../../../shared/pipes/sub-type-display.pipe";
import {
  Component,
  OnInit,
} from "@angular/core";
import { NgbDatepickerI18n, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { AlertService } from "src/app/services/alert.service";
import {
  DatePickerTrLangService,
  I18n,
} from "src/app/services/date-picker-tr-lang.service";
import { ExchangeService } from "src/app/services/exchange.service";
import { UnknownCostResponseItem } from "src/app/models/exchange/unknown-cost-response-item";
import { BaseListResponse } from "src/app/core/models/responses/base-list-response";
import { SetUnknownCostRequest } from "src/app/models/exchange/set-unknown-cost-request";
import { Currency, ExchangeCurrencyList } from "src/app/models/enums/currency";
import { CurrencySubTypes } from "src/app/models/enums/currency-sub-types";
import { CustomCurrencyPipe } from "src/app/shared/pipes/custom-currency.pipe";
import { ExchangeItem } from "src/app/models/exchange/exchange-item-request";
import { BaseListComponent } from "src/app/shared/components/base-list/base-list.component";
import { BasePagingRequest } from "src/app/core/models/requests/base-paging-request";
import { ExchangeFilter } from "src/app/models/filters/exchange-filter";
import { ExchangeCost } from "src/app/models/exchange/exchange-cost";
import { BasePagingResponse } from "src/app/core/models/responses/base-paging-response";
import { getCurrencySubTypeItem } from "src/app/core/helpers/common-functions";

@Component({
  selector: "app-exchange-costs",
  templateUrl: "./exchange-costs.component.html",
  styleUrls: ["./exchange-costs.component.scss"],
  providers: [
    I18n,
    { provide: NgbDatepickerI18n, useClass: DatePickerTrLangService },
    CustomCurrencyPipe,
    SubTypeDisplayPipe,
  ],
})
export class ExchangeCostsComponent
  extends BaseListComponent
  implements OnInit
{
  model: BaseListResponse<UnknownCostResponseItem>;
  request: SetUnknownCostRequest[];
  clicked: boolean = false;
  Currency = Currency;
  CurrencySubTypes = CurrencySubTypes;
  item: SetUnknownCostRequest;
  selectedIndex: number = 0;
  TL_PLACEHOLDER = "/TL Alış Maliyeti";
  USD_PLACEHOLDER = "/USD Alış Maliyeti";
  HAS_PLACEHOLDER = "/HAS Alış Maliyeti";
  costTLPlaceHolder = "";
  costUSDPlaceHolder = "";
  costHASPlaceHolder = "";
  beginDateModel: NgbDateStruct;
  endDateModel: NgbDateStruct;
  defaultValue = null;
  currencyList = ExchangeCurrencyList;
  paymentSubTypes;
  listRequest: BasePagingRequest<ExchangeFilter> = {
    pageIndex: this.pageIndex,
    itemPerPage: this.itemPerPage,
    filter: {
      exchangeCurrency: null,
      exchangeSubType: null,
      paymentCurrency: null,
      paymentSubType: null,
    },
  };
  listResponse: BasePagingResponse<ExchangeCost>;
  costs: ExchangeItem[];
  constructor(
    private service: ExchangeService,
    private alertService: AlertService,
    private customCurrencyPipe: CustomCurrencyPipe,
    private subTypePipe: SubTypeDisplayPipe,
  ) {
    super();
  }

  ngOnInit() {
    this.service.refreshIndex.subscribe((res) => {
      this.reloadUnkwnownCostsData();
      this.reloadCosts();
    });
    this.reloadUnkwnownCostsData();
    this.reloadCosts();
  }

  reloadUnkwnownCostsData() {
    this.service
      .getUnknownCosts()
      .toPromise()
      .then((res) => {
        this.model = res;
        this.request = [];
        res.data.map((p) => {
          this.request.push({
            paymentCurrency: p.paymentCurrency,
            paymentSubType: p.paymentSubType,
            totalPaymentAmount: p.totalPaymentAmount,
          });
        });
        this.getCostItem(this.selectedIndex);
      });
  }

  reloadCosts() {
    this.Loading = true;
    this.setDates();
    this.service
      .getAllCosts(this.listRequest)
      .toPromise()
      .then((res) => {
        this.Loading = false;
        this.listResponse = res;
      });
  }

  setDates() {
    if (this.beginDateModel) {
      this.listRequest.filter.beginDate = new Date();
      this.listRequest.filter.beginDate.setFullYear(
        this.beginDateModel.year,
        this.beginDateModel.month - 1,
        this.beginDateModel.day,
      );
      this.listRequest.filter.beginDate.setHours(0,0,0);
    }

    if (this.endDateModel) {
      this.listRequest.filter.endDate = new Date();
      this.listRequest.filter.endDate.setFullYear(
        this.endDateModel.year,
        this.endDateModel.month - 1,
        this.endDateModel.day
      );
      this.listRequest.filter.beginDate.setHours(23,59,59);

    }
  }

  validate(item: SetUnknownCostRequest): boolean {
    if (!item.costTL || !item.costHAS || !item.costUSD) return false;
    return true;
  }

  paymentCurrencyChanged() {
    let item = getCurrencySubTypeItem(this.listRequest.filter.paymentCurrency);
    this.paymentSubTypes = item.subtypes;
    this.listRequest.filter.paymentSubType = null;
    this.reloadCosts();
  }

  getCostItem(index) {
    this.item = this.request[this.selectedIndex];
    let payment = this.customCurrencyPipe.transform(this.item.paymentCurrency);
    if (this.item.paymentSubType)
      payment = this.subTypePipe.transform(this.item.paymentSubType);

    this.costTLPlaceHolder = payment + this.TL_PLACEHOLDER;
    this.costUSDPlaceHolder = payment + this.USD_PLACEHOLDER;
    this.costHASPlaceHolder = payment + this.HAS_PLACEHOLDER;

    if (this.item.paymentCurrency == Currency.Tl) this.item.costTL = 1;
    if (this.item.paymentCurrency == Currency.Usd) this.item.costUSD = 1;
    if (
      this.item.paymentCurrency == Currency.Gold &&
      this.item.paymentSubType == CurrencySubTypes.Has
    )
      this.item.costHAS = 1;
  }

  save(item: SetUnknownCostRequest) {
    if (this.validate(item)) {
      this.clicked = true;
      this.service
        .setUnknownCosts(item)
        .toPromise()
        .then((res) => {
          this.clicked = false;
          if (res.isSuccess) {
            this.service.refreshIndex.next();
            this.alertService.showSuccessToast();
          }
        });
    } else
      this.alertService.showErrorToast(
        "Lütfen satırdaki tüm alanları doldurun!"
      );
  }

  delete(item: SetUnknownCostRequest) {
    this.alertService.showConfirmAlert("Seçtiğiniz birime ait maliyetlerin tümü silinecek. Devam etmek istiyor musunuz?").then(res => {
      if (res.isConfirmed) {
        this.service.deleteCosts(item).toPromise().then(res => {
          this.service.refreshIndex.next();
          this.alertService.showSuccessToast();
        })
      }
    })
  }

  update(item: SetUnknownCostRequest) {}
}
