import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderCreateEditModalComponent } from './order-create-edit-modal/order-create-edit-modal.component';
import { NgbDatepickerModule, NgbDropdownModule, NgbModalModule, NgbNavModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { UploadModule } from '../uploads/upload/upload.module';
import { ManufactureOrdersComponent } from './manufacture-orders/manufacture-orders.component';
import { StatesModule } from '../states/states.module';
import { CoreModule } from 'src/app/core/core.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { ComponentsModule as SharedComponentsModule} from 'src/app/shared/components/components.module';
import { ManufactureOrdersTabWidgetComponent } from './manufacture-orders-tab-widget/manufacture-orders-tab-widget.component';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [
    OrderCreateEditModalComponent,
    ManufactureOrdersComponent,
    ManufactureOrdersTabWidgetComponent

  ],
  imports: [
    CommonModule,
    NgbModalModule,
    NgSelectModule,
    FormsModule,
    NgbDatepickerModule,
    UploadModule,
    NgbTooltipModule,
    StatesModule,
    CoreModule,
    NgbDropdownModule,
    CoreModule,
    PipesModule,
    SharedComponentsModule,
    NgbNavModule,
    RouterModule
  ],
  exports: [
    OrderCreateEditModalComponent,
    ManufactureOrdersComponent,
    ManufactureOrdersTabWidgetComponent

  ]
})
export class ManufactureOrdersModule { }
