import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AccountTransaction } from "src/app/models/account-transaction/account-transaction";
import { Customer } from "src/app/models/customer/customer";
import { AccountTransactionType } from "src/app/models/enums/account-transaction-type";
import { Currency } from "src/app/models/enums/currency";
import { CollectionSummaryRequest } from "src/app/models/collection/collection-summary-request";
import { CollectionSummaryResponse } from "src/app/models/collection/collection-summary-response";
import { CustomerService } from "src/app/services/customer.service";
import { Observable } from "rxjs";
import { BaseResponse } from "src/app/core/models/responses/base-response";
import { finalize, tap } from "rxjs/operators";

@Component({
  selector: "app-customer-balance",
  templateUrl: "./customer-balance.component.html",
  styleUrls: ["./customer-balance.component.scss"],
})
export class CustomerBalanceComponent implements OnInit {
  @Input() customerId?: number;
  currentBranch = JSON.parse(localStorage.getItem("currentBranch"));
  operationDate;
  onlyBalance = true;
  collection: AccountTransaction[] = [];
  customer: Customer;
  currencyEnum = Currency;
  collectionType: string;
  model: CollectionSummaryResponse = {};
  summaryData$: Observable<BaseResponse<CollectionSummaryResponse>>;
  customer$: Observable<BaseResponse<Customer>>;
  constructor(
    private service: CustomerService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.operationDate = new Date();
    this.route.queryParams.subscribe((params) => {
      this.customerId = params.id;
      if (params.groupId) {
        this.onlyBalance = false;
        this.summaryData$ = this.getCollectionSummaryData(params.groupId);
      } else {
        this.customer$ = this.findCustomer(this.customerId,this.currentBranch.id);
      }
    });
  }

  findCustomer(customerId, branchId): Observable<BaseResponse<Customer>> {
    return this.service.findByBranch(customerId, branchId).pipe(
      tap((res) => {
        this.customer = res.data;
      }),
      finalize(() => {
        this.print();
      })
    );
  }

  getCollectionSummaryData(
    groupId: string
  ): Observable<BaseResponse<CollectionSummaryResponse>> {
    let request: CollectionSummaryRequest = {
      branchId: this.currentBranch.id,
      customerId: this.customerId,
      groupId: groupId,
    };
    return this.service.collectionSummary(request).pipe(
      tap((res) => {
        this.model = res.data;
        this.customer = {};
        this.collection = res.data.transactions;
        this.customer.name = res.data.displayName;
        if (res.data.transactions.length > 0) {
          const isCollection = res.data.transactions[0].type == AccountTransactionType.Receivable;
          this.collectionType = isCollection ? "Tahsilat" : "Ödeme";
        }
      }),
      finalize(() => {
        this.print();
      })
    );
  }

  print() {
    setTimeout(() => {
      window.onafterprint = window.close;
      window.print();
    }, 500);
  }
}
