import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/services/base.service';
import { SalesFilter } from 'src/app/models/filters/sales-filter';
import { Sales } from 'src/app/models/sales/sales';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SalesService extends BaseService<Sales, SalesFilter, number> {
  apiUrl = environment.urlAddress + '/Sales';
  constructor(private http: HttpClient) {
    super(http, environment.urlAddress + '/Sales')
   }
  }