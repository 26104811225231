import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExchangeItem } from 'src/app/models/exchange/exchange-item-request';
import { ApplicationUserService } from 'src/app/core/services/application-user.service';
import { Branch } from 'src/app/models/branch/branch';
import { BaseResponse } from 'src/app/core/models/responses/base-response';
import { Currency } from 'src/app/models/enums/currency';
import { ExchangeState } from 'src/app/models/enums/exchange-state';
import { AlertService } from 'src/app/services/alert.service';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { ExchangeService } from 'src/app/services/exchange.service';
@Component({
  selector: 'app-exchange-detail',
  templateUrl: './exchange-detail.component.html',
  styleUrls: ['./exchange-detail.component.scss']
})
export class ExchangeDetailComponent extends BaseComponent implements OnInit {

  breadCrumbItems: Array<{}> = [{ label: 'Exchange' }, { label: 'Detay', active: true }];
  currencyEnum = Currency;
  model: BaseResponse<ExchangeItem>;
  subsItemList: any[] = new Array();
  subsItem: any;
  exchangeId: number;
  currentBranch: Branch = JSON.parse(localStorage.getItem('currentBranch'));
  public exchangeStateEnum = ExchangeState
  groupId: string;

  constructor(
    private service: ExchangeService,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private router: Router
  ) {
    super();
    this.exchangeId = this.route.snapshot.params["id"];
  }

  async ngOnInit() {
    this.model = await this.service.find(this.exchangeId).toPromise();
    if (this.model) {
      this.groupId = this.model.data.groupId
    }
  }

  delete(id: number) {
    this.alertService.showDeleteAlert().then(result => {
      if (result.isConfirmed) {
        this.service.delete(id).toPromise().then(res => {
          if (res.isSuccess) {
            this.alertService.showSuccess('Alım/Satım kaydı silindi.');
            this.router.navigateByUrl('/exchange/list')
          }
        })
      }
    })
  }
  donePayment(item: ExchangeItem) {
      this.service.paymentsDone(item.id).subscribe(async res => {
        this.alertService.showSuccess();
        this.model = await this.service.find(this.exchangeId).toPromise();
      });
  }
  doneWithDebt(item: ExchangeItem) {
    if (item.state == ExchangeState.Agreed) {
      this.service.doneWithDebt(item.id).subscribe(async res => {
        this.alertService.showSuccess();
        this.model = await this.service.find(this.exchangeId).toPromise();
      });
    }
  }
  doneWithDeposit(item: ExchangeItem) {

    if (item.state == ExchangeState.Agreed) {
      this.service.doneWithDeposit(item.id).subscribe(async res => {
        this.alertService.showSuccess();
        this.model = await this.service.find(this.exchangeId).toPromise();
      });
    }
  }


  paymentClass(item: ExchangeItem) {
    switch (item.state) {
      case ExchangeState.Done:
        return "text-success";
      case ExchangeState.Agreed:
        return "text-warning";
      case ExchangeState.DoneWithDebt:
        return "text-danger";
      case ExchangeState.DoneWithDeposit:
        return "text-primary"
    }
  }
}



