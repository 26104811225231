import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { BaseResponse } from 'src/app/core/models/responses/base-response';
import { BaseService } from 'src/app/core/services/base.service';
import { BranchFilter } from 'src/app/models/filters/branch-filter';
import { BranchBalanceResponse } from 'src/app/models/branch/branch-balance-response';
import { BranchBalanceRequest } from 'src/app/models/branch/branch-balance-request';
import { Branch } from 'src/app/models/branch/branch';

@Injectable({
  providedIn: 'root'
})
export class BranchService extends BaseService<Branch, BranchFilter, number> {

  apiUrl = environment.urlAddress + '/Branch'
  constructor(private http: HttpClient) {
    super(http, environment.urlAddress + '/Branch')
   }

   getBalance(request: BranchBalanceRequest): Observable<BaseResponse<BranchBalanceResponse>>{
     return this.http.post<BaseResponse<BranchBalanceResponse>>(this.apiUrl + '/get-balance', request);
   }
}




