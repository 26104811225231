<div class="row d-flex">
    <div class="col" *ngIf="tlBalance.balance > 0 || tlBalance.customersBalance > 0 || tlBalance.finalBalance > 0">
        <div class="d-flex justify-content-end">
            <div triggers="hover" triggers="hover" triggers="hover" ngbTooltip="Hareket Ekle" [autoClose]="true" container="body" class="transaction-add align-btn"> 
                <span  (click)="openAccountTransactionAddModal(Currency.Tl)"> <i class="fas fa-plus"></i></span>
            </div>
        </div>
        <div class="card mini-stats-wid">
            <div class="card-header bg-white border-bottom border-danger">
                <h4 class="text-danger mt-2">
                    Türk Lirası
                </h4>
            </div>
            <div class="card-body">
                <div class="media">
                    <div class="media-body">
                        <h5 class="mb-0 d-flex flex-column gap-2"> 
                            <span class="d-flex justify-content-between">
                                <span triggers="hover" triggers="hover" ngbTooltip="Kasa">K:</span>
                                <span *ngIf="tlBalance">{{ tlBalance.balance| currencyAmount: Currency.Tl }}</span>
                                <span *ngIf="!tlBalance">0</span>
                            </span>
                            <span class="d-flex justify-content-between" [ngClass]="setCustomerBalanceColor(tlBalance.customersBalance)">
                                <span triggers="hover" triggers="hover" ngbTooltip="Müşteri">M:</span>
                                <span *ngIf="tlBalance">{{ tlBalance.customersBalance| currencyAmount: Currency.Tl }}</span>
                                <span *ngIf="!tlBalance">0</span>
                            </span>
                            <span class="d-flex justify-content-between">
                                <span triggers="hover" triggers="hover" ngbTooltip="Toplam">T:</span>
                                <span *ngIf="tlBalance">{{ tlBalance.finalBalance | currencyAmount: Currency.Tl }}</span>
                                <span *ngIf="!tlBalance">0</span>
                            </span>
                        </h5>
                    </div>
                </div>
            </div>
        </div>  
    </div>
    <div class="col" *ngIf="usdBalance.balance > 0 || usdBalance.customersBalance > 0 || usdBalance.finalBalance > 0">
        <div class="d-flex justify-content-end">
            <div triggers="hover" triggers="hover" ngbTooltip="Hareket Ekle" [autoClose]="true" container="body" class="transaction-add align-btn"> 
                <span  (click)="openAccountTransactionAddModal(Currency.Usd)"> <i class="fas fa-plus"></i></span>
            </div>
        </div>
        <div class="card mini-stats-wid">
            <div class="card-header bg-white border-bottom border-success">
                <h4 class="text-success mt-2">Dolar</h4>
            </div>
            <div class="card-body">
                <div class="media">
                    <div class="media-body">
                        <h5 class="mb-0 d-flex flex-column gap-2"> 
                            <span class="d-flex justify-content-between">
                                <span triggers="hover" triggers="hover" ngbTooltip="Kasa">K:</span>
                                <span *ngIf="usdBalance">{{ usdBalance.balance | currencyAmount: Currency.Usd }}</span>
                                <span *ngIf="!usdBalance">0</span>
                            </span>
                            <span class="d-flex justify-content-between" [ngClass]="usdBalance.customersBalance >= 0 ? 'text-success': 'text-danger'">
                                <span triggers="hover" triggers="hover" ngbTooltip="Müşteri">M:</span>
                                <span>{{ usdBalance.customersBalance | currencyAmount: Currency.Usd }}</span>
                                <span *ngIf="!usdBalance">0</span>
                            </span>
                            <span class="d-flex justify-content-between">
                                <span triggers="hover" triggers="hover" ngbTooltip="Toplam">T:</span>
                                <span>{{ usdBalance.finalBalance | currencyAmount: Currency.Usd }}</span>
                                <span *ngIf="!usdBalance">0</span>
                            </span>
                        </h5>
                    </div>
                </div>
            </div>
        </div>  
    </div>
    <div class="col" *ngIf="usdBalance.balance > 0 || euroBalance.customersBalance > 0 || euroBalance.finalBalance > 0">
        <div class="d-flex justify-content-end">
            <div triggers="hover" triggers="hover" ngbTooltip="Hareket Ekle" [autoClose]="true" container="body" class="transaction-add align-btn"> 
                <span  (click)="openAccountTransactionAddModal(Currency.Euro)"> <i class="fas fa-plus"></i></span>
            </div>
        </div>
        <div class="card mini-stats-wid">
            <div class="card-header bg-white border-bottom border-info">
                <h4 class="text-info mt-2">
                    Euro
                </h4>
            </div>
            <div class="card-body">
                <div class="media">
                    <div class="media-body">
                        <h5 class="mb-0 d-flex flex-column gap-2"> 
                            <span class="d-flex justify-content-between">
                                <span triggers="hover" triggers="hover" ngbTooltip="Kasa">K:</span>
                                <span *ngIf="euroBalance">{{ euroBalance.balance | currencyAmount: Currency.Euro }}</span>
                                <span *ngIf="!euroBalance">0</span>
                            </span>
                            <span class="d-flex justify-content-between"
                                [ngClass]="euroBalance.customersBalance >= 0 ? 'text-success': 'text-danger'">
                                <span triggers="hover" triggers="hover" ngbTooltip="Müşteri">M:</span>
                                <span>{{ euroBalance.customersBalance | currencyAmount: Currency.Euro }}</span>
                                <span *ngIf="!euroBalance">0</span>
                            </span>
                            <span class="d-flex justify-content-between">
                                <span triggers="hover" triggers="hover" ngbTooltip="Toplam">T:</span>
                                <span>{{ euroBalance.finalBalance | currencyAmount: Currency.Euro }}</span>
                                <span *ngIf="!euroBalance">0</span>
                            </span>
                        </h5>
                    </div>
                </div>
            </div>
        </div>  
    </div>
    <div class="col" *ngIf="goldBalance.balance > 0 || goldBalance.customersBalance > 0 || goldBalance.finalBalance > 0">
        <div class="d-flex justify-content-end">
            <div triggers="hover" triggers="hover" ngbTooltip="Hareket Ekle" [autoClose]="true" container="body" class="transaction-add align-btn"> 
                <span  (click)="openAccountTransactionAddModal(Currency.Gold)"> <i class="fas fa-plus"></i></span>
            </div>
        </div>
        <div class="card mini-stats-wid">
            <div class="card-header bg-white border-bottom border-warning">
                <h4 class="text-warning mt-2">
                    Hurda
                </h4>
            </div>
            <div class="card-body">
                <div class="media">
                    <div class="media-body">
                        <h5 class="mb-0 d-flex flex-column gap-2"> 
                            <span class="d-flex justify-content-between">
                                <span triggers="hover" triggers="hover" ngbTooltip="Kasa">K:</span>
                                <span *ngIf="goldBalance">{{ goldBalance.balance | currencyAmount: Currency.Gold }}</span>
                                <span *ngIf="!goldBalance">0</span>
                            </span>
                            <span class="d-flex justify-content-between"
                                [ngClass]="goldBalance.customersBalance >= 0 ? 'text-success': 'text-danger'">
                                <span triggers="hover" triggers="hover" ngbTooltip="Müşteri">M:</span>
                                <span>{{ goldBalance.customersBalance | currencyAmount: Currency.Gold }}</span>
                                <span *ngIf="!goldBalance">0</span>

                            </span>
                            <span class="d-flex justify-content-between">
                                <span triggers="hover" triggers="hover" ngbTooltip="Toplam">T:</span>
                                <span>{{ goldBalance.finalBalance | currencyAmount: Currency.Gold }}</span>
                                <span *ngIf="!goldBalance">0</span>
                            </span>
                        </h5>
                    </div>
                </div>
            </div>
        </div>  
    </div>
    <div class="col" *ngIf="goldNewBalance.balance > 0 || goldNewBalance.customersBalance > 0 || goldNewBalance.finalBalance > 0">
        <div class="d-flex justify-content-end">
            <div triggers="hover" triggers="hover" ngbTooltip="Hareket Ekle" [autoClose]="true" container="body" class="transaction-add align-btn"> 
                <span  (click)="openAccountTransactionAddModal(Currency.GoldNew)"> <i class="fas fa-plus"></i></span>
            </div>
        </div>
        <div class="card mini-stats-wid">
            <div class="card-header bg-white border-bottom border-warning">
                <h4 class="text-warning mt-2">
                    Vitrin
                </h4>
            </div>
            <div class="card-body">
                <div class="media">
                    <div class="media-body">
                        <h5 class="mb-0 d-flex flex-column gap-2"> 
                            <span class="d-flex justify-content-between">
                                <span triggers="hover" triggers="hover" ngbTooltip="Kasa">K:</span>
                                <span *ngIf="goldNewBalance">{{ goldNewBalance.balance | currencyAmount: Currency.GoldNew }}</span>
                                <span *ngIf="!goldNewBalance">0</span>
                            </span>
                            <span class="d-flex justify-content-between"
                                [ngClass]="goldNewBalance.customersBalance >= 0 ? 'text-success': 'text-danger'">
                                <span triggers="hover" triggers="hover" ngbTooltip="Müşteri">M:</span>
                                <span>{{ goldNewBalance.customersBalance | currencyAmount: Currency.GoldNew }}</span>
                                <span *ngIf="!goldNewBalance">0</span>

                            </span>
                            <span class="d-flex justify-content-between">
                                <span triggers="hover" triggers="hover" ngbTooltip="Toplam">T:</span>
                                <span>{{ goldNewBalance.finalBalance | currencyAmount: Currency.GoldNew }}</span>
                                <span *ngIf="!goldNewBalance">0</span>
                            </span>
                        </h5>
                    </div>
                </div>
            </div>
        </div>  
    </div>
    <div class="col" *ngIf="goldIgnotBalance.balance > 0 || goldIgnotBalance.customersBalance > 0 || goldIgnotBalance.finalBalance > 0">
        <div class="d-flex justify-content-end">
            <div triggers="hover" triggers="hover" ngbTooltip="Hareket Ekle" [autoClose]="true" container="body" class="transaction-add align-btn"> 
                <span  (click)="openAccountTransactionAddModal(Currency.GoldIgnot)"> <i class="fas fa-plus"></i></span>
            </div>
        </div>
        <div class="card mini-stats-wid">
            <div class="card-header bg-white border-bottom border-warning">
                <h4 class="text-warning mt-2">
                    Külçe
                </h4>
            </div>
            <div class="card-body">
                <div class="media">
                    <div class="media-body">
                        <h5 class="mb-0 d-flex flex-column gap-2"> 
                            <span class="d-flex justify-content-between">
                                <span triggers="hover" triggers="hover" ngbTooltip="Kasa">K:</span>
                                <span *ngIf="goldIgnotBalance">{{ goldIgnotBalance.balance | currencyAmount: Currency.GoldIgnot }}</span>
                                <span *ngIf="!goldIgnotBalance">0</span>
                            </span>
                            <span class="d-flex justify-content-between"
                                [ngClass]="goldIgnotBalance.customersBalance >= 0 ? 'text-success': 'text-danger'">
                                <span triggers="hover" triggers="hover" ngbTooltip="Müşteri">M:</span>
                                <span>{{ goldIgnotBalance.customersBalance | currencyAmount: Currency.GoldIgnot }}</span>
                                <span *ngIf="!goldNewBalance">0</span>

                            </span>
                            <span class="d-flex justify-content-between">
                                <span triggers="hover" triggers="hover" ngbTooltip="Toplam">T:</span>
                                <span>{{ goldIgnotBalance.finalBalance | currencyAmount: Currency.GoldIgnot }}</span>
                                <span *ngIf="!goldIgnotBalance">0</span>
                            </span>
                        </h5>
                    </div>
                </div>
            </div>
        </div>  
    </div>
    <div class="col" *ngIf="accessoriesBalance.balance > 0 || accessoriesBalance.customersBalance > 0 || accessoriesBalance.finalBalance > 0">
        <div class="d-flex justify-content-end">
            <div triggers="hover" triggers="hover" ngbTooltip="Hareket Ekle" [autoClose]="true" container="body" class="transaction-add align-btn"> 
                <span  (click)="openAccountTransactionAddModal(Currency.Accessories)"> <i class="fas fa-plus"></i></span>
            </div>
        </div>
        <div class="card mini-stats-wid">
            <div class="card-header bg-white border-bottom border-warning">
                <h4 class="text-warning mt-2">
                    Sarrafiye
                </h4>
            </div>
            <div class="card-body">
                <div class="media">
                    <div class="media-body">
                        <h5 class="mb-0 d-flex flex-column gap-2"> 
                            <span class="d-flex justify-content-between">
                                <span triggers="hover" triggers="hover" ngbTooltip="Kasa">K:</span>
                                <span *ngIf="accessoriesBalance">{{ accessoriesBalance.balance | currencyAmount: Currency.Gold }}</span>
                                <span *ngIf="!accessoriesBalance">0</span>
                            </span>
                            <span class="d-flex justify-content-between"
                                [ngClass]="accessoriesBalance.customersBalance >= 0 ? 'text-success': 'text-danger'">
                                <span triggers="hover" triggers="hover" ngbTooltip="Müşteri">M:</span>
                                <span>{{ accessoriesBalance.customersBalance | currencyAmount: Currency.Gold }}</span>
                                <span *ngIf="!accessoriesBalance">0</span>

                            </span>
                            <span class="d-flex justify-content-between">
                                <span triggers="hover" triggers="hover" ngbTooltip="Toplam">T:</span>
                                <span>{{ accessoriesBalance.finalBalance | currencyAmount: Currency.Gold }}</span>
                                <span *ngIf="!accessoriesBalance">0</span>
                            </span>
                        </h5>
                    </div>
                </div>
            </div>
        </div>  
    </div>
    <div class="col" *ngIf="otherAccessoriesBalance.balance > 0 || otherAccessoriesBalance.customersBalance > 0 || otherAccessoriesBalance.finalBalance > 0">
        <div class="d-flex justify-content-end">
            <div triggers="hover" triggers="hover" ngbTooltip="Hareket Ekle" [autoClose]="true" container="body" class="transaction-add align-btn"> 
                <span  (click)="openAccountTransactionAddModal(Currency.OtherAccessories)"> <i class="fas fa-plus"></i></span>
            </div>
        </div>
        <div class="card mini-stats-wid">
            <div class="card-header bg-white border-bottom border-warning">
                <h4 class="text-warning mt-2">
                    Sarrafiye(Diğer)
                </h4>
            </div>
            <div class="card-body">
                <div class="media">
                    <div class="media-body">
                        <h5 class="mb-0 d-flex flex-column gap-2"> 
                            <span class="d-flex justify-content-between">
                                <span triggers="hover" triggers="hover" ngbTooltip="Kasa">K:</span>
                                <span *ngIf="otherAccessoriesBalance">{{ otherAccessoriesBalance.balance | currencyAmount: Currency.Gold }}</span>
                                <span *ngIf="!otherAccessoriesBalance">0</span>
                            </span>
                            <span class="d-flex justify-content-between"
                                [ngClass]="otherAccessoriesBalance.customersBalance >= 0 ? 'text-success': 'text-danger'">
                                <span triggers="hover" triggers="hover" ngbTooltip="Müşteri">M:</span>
                                <span>{{ otherAccessoriesBalance.customersBalance | currencyAmount: Currency.Gold }}</span>
                                <span *ngIf="!otherAccessoriesBalance">0</span>

                            </span>
                            <span class="d-flex justify-content-between">
                                <span triggers="hover" triggers="hover" ngbTooltip="Toplam">T:</span>
                                <span>{{ otherAccessoriesBalance.finalBalance | currencyAmount: Currency.Gold }}</span>
                                <span *ngIf="!otherAccessoriesBalance">0</span>
                            </span>
                        </h5>
                    </div>
                </div>
            </div>
        </div>  
    </div>
</div>