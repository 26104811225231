import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadModule } from './upload/upload.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    UploadModule
  ]
})
export class UploadsModule { }
