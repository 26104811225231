<!-- <div class="receipt-container">
    <div class="page">
        <div class="subpage">
            <router-outlet></router-outlet>
        </div>
    </div>
</div> -->


<!-- <div class="barcode-container"> -->
  <router-outlet></router-outlet>
<!-- </div> -->
