import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FastCollectionModalComponent } from './fast-collection-modal.component';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    FastCollectionModalComponent
  ],
  imports: [
    CommonModule,
    PipesModule,
    FormsModule
  ],
  exports: [
    FastCollectionModalComponent
  ]
})
export class FastCollectionModalModule { }
