<h5 class="header text-center"> Müşteri Bakiyesi </h5>
<h6 *ngIf="customer?.name" class="header text-center">{{customer.name}} </h6>
<div *ngIf="operationDate" class="text-center header-info">
  <span>{{ operationDate | date:'dd/MM/yyyy HH:mm:ss'}} </span>
</div>
<ng-container *ngIf="summaryData$ | async">
  <div class="customer-balance-summary mt-3 mb-0"> Son İşlem ({{collectionType}}) </div>
<div class="table-responsive">
  <table class="table mb-0 mt-1">
    <thead>
      <tr>
        <th>Birim</th>
        <th>Miktar</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of collection">
        <td>{{ item.currency | customCurrency }}
          <span *ngIf="item.subType != undefined"> ({{item.subType |subTypeDisplay}}) </span>
          <span *ngIf="item.milyem != null"> ({{item.milyem}}) </span>
        </td>
        <td><span>{{ item.amount | currencyAmount }}</span></td>
      </tr>
    </tbody>
  </table>
</div>
</ng-container>
<ng-container *ngIf="customer$ | async">
  <div class="table-responsive">
    <table class="table mb-0 mt-3">
      <thead>
        <tr>
          <th>Birim</th>
          <th>Borç</th>
          <th>Alacak</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let balanceDTO of customer.mergedBalanceList | keyvalue">
          <ng-container *ngIf="balanceDTO.value != 0">
            <td class="text-start">HAS</td>
            <td class="text-end"><span *ngIf="balanceDTO.value < 0">{{ balanceDTO.value * -1 |
                currencyAmount: balanceDTO.key}}</span></td>
            <td class="text-end"><span *ngIf="balanceDTO.value > 0">{{ balanceDTO.value |
                currencyAmount: balanceDTO.key}}</span></td>
          </ng-container>
        </tr>
        <tr *ngFor="let balanceDTO of customer.balanceList">
          <td class="text-start">
            <span *ngIf="balanceDTO.subType != undefined"> {{balanceDTO.subType | subTypeDisplay }}
              ({{balanceDTO.currency | customCurrency}})</span>
            <span *ngIf="balanceDTO.subType == null"> {{balanceDTO.currency | customCurrency }} </span>
          </td>
          <td class=" text-end"><span *ngIf="balanceDTO.balance < 0">{{balanceDTO.balance * -1 |
              currencyAmount: balanceDTO.key}}</span></td>
          <td class=" text-end"><span *ngIf="balanceDTO.balance > 0">{{balanceDTO.balance |
              currencyAmount: balanceDTO.key}}</span></td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-container>

<ng-container *ngIf="summaryData$ | async">
  <div class="customer-balance-summary mt-3 mb-0"> BAKİYE SON DURUMU </div>
  <table class="table mb-0 mt-1">
    <thead class="border-bottom">
      <tr>
        <th class="text-center">BİRİM</th>
        <th class="text-center">ÖNCEKİ BAKİYE</th>
        <th class="text-center">GİRİŞ/ÇIKIŞ</th>
        <th class="text-center">SON BAKİYE</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of model.customerBalances">
        <td class="text-center">{{item.subType | subTypeShort: item.currency }}</td>
        <td class="text-center">{{item.oldBalance | currencyAmount: item.currency: item.subType: true }}</td>
        <td class="text-center">{{item.operationAmount | currencyAmount: item.currency: item.subType: true }}</td>
        <td class="text-center">{{item.newBalance | currencyAmount: item.currency: item.subType: true }}</td>
      </tr>
    </tbody>
  </table>
</ng-container>
<p class="sarraf">sarraf.io</p>
<div class="groupId text-center">Bilgilendirme amaçlıdır.<br> Resmi belge olarak kullanılamaz. Mali değeri yoktur.
</div>
