import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { BaseResponse } from 'src/app/core/models/responses/base-response';
import { environment } from 'src/environments/environment';
import { ChartPeriodType } from 'src/app/models/enums/chart-enums';
import { BranchBalanceChartResponse } from 'src/app/models/branch/branch-balance-chart-response';
import { BranchBalanceChartFilter } from 'src/app/models/filters/branch-balance-chart-filter';
import { ExpenseIncomeChartRequest } from 'src/app/models/chart/expense-income-chart-request';
import {  ExpenseIncomeChartResponse } from 'src/app/models/chart/expense-income-chart-response';
import { BasicColumnChartRequest } from 'src/app/models/chart/basic-column-chart-request';
import { BasicColumnChartItem } from 'src/app/models/chart/basic-column-chart-item';
import { PieChartResponse } from 'src/app/models/chart/pie-chart-response';
import { NegativeBarChartResponse } from 'src/app/models/chart/negative-bar-chart-response';

@Injectable({
  providedIn: 'root'
})
export class ChartService {

  apiUrl = environment.urlAddress + '/Chart'
  constructor(private http: HttpClient) {}

  branchBalance(branchId: number, periodType: ChartPeriodType): Observable<BaseResponse<BranchBalanceChartResponse>> {
     let filter: BranchBalanceChartFilter = {
       branchId: branchId,
       periodType: periodType
     };
    return this.http.post<BaseResponse<BranchBalanceChartResponse>>(this.apiUrl + '/branch-balance', filter);
  }

  expenseIncome(request: ExpenseIncomeChartRequest): Observable<BaseResponse<ExpenseIncomeChartResponse>> {
    return this.http.post<BaseResponse<ExpenseIncomeChartResponse>>(this.apiUrl + '/expense-income', request);
  }

  expenseIncomeOfHas(request: ExpenseIncomeChartRequest): Observable<BaseResponse<BasicColumnChartItem[]>> {
  return this.http.post<BaseResponse<BasicColumnChartItem[]>>(this.apiUrl + '/expense-income-of-has', request);
  }

  branchBalanceOfHas(id: number): Observable<BaseResponse<PieChartResponse>> {
  return this.http.get<BaseResponse<PieChartResponse>>(this.apiUrl + '/branch-balance-of-has/' + id);
  }

  branchFinalBalanceOfHas(id: number): Observable<BaseResponse<NegativeBarChartResponse>> {
    return this.http.get<BaseResponse<NegativeBarChartResponse>>(this.apiUrl + '/branch-final-balance-of-has/' + id);
    }
}




