import { Pipe, PipeTransform } from "@angular/core";
import { ExchangeState } from "src/app/models/enums/exchange-state";
import { ExchangeItem } from "src/app/models/exchange/exchange-item-request";

@Pipe({
  name: 'exchangeItemState'
})

export class ExchangeItemStatePipe implements PipeTransform {

  transform(item: ExchangeItem, asHtml: boolean = true): string {
    
    if (item.state == ExchangeState.Agreed)
      return asHtml ? '<span class="badge bg-warning ">Bağlantı</span>' : "Bağlantı";
    if (item.state == ExchangeState.DoneWithDebt)
      return asHtml ? '<span class="badge bg-danger">Borç</span>' : "Borç";
    if (item.state == ExchangeState.DoneWithDeposit)
      return asHtml ? '<span class="badge bg-info ">Alacaklı</span>' : "Alacaklı";
    if (item.state == ExchangeState.Done){
      return asHtml ? '<span class="badge bg-success">Tamamlandı</span>' : 'Tamamlandı'
    }
  }
}