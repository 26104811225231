import { DataService } from "src/app/services/data.service";
import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { Currency } from "src/app/models/enums/currency";
import { NgbDatepickerI18n } from "@ng-bootstrap/ng-bootstrap";
import { BaseListComponent } from "src/app/shared/components/base-list/base-list.component";
import { BasePagingResponse } from "src/app/core/models/responses/base-paging-response";
import { BasePagingRequest } from "src/app/core/models/requests/base-paging-request";
import { Branch } from "src/app/models/branch/branch";
import { ExchangeState } from "src/app/models/enums/exchange-state";
import { ExchangeItem } from "src/app/models/exchange/exchange-item-request";
import { ExchangeFilter } from "src/app/models/filters/exchange-filter";
import {
  DatePickerTrLangService,
  I18n,
} from "src/app/services/date-picker-tr-lang.service";
import { ExchangeService } from "src/app/services/exchange.service";

@Component({
  selector: "app-exchange-list-mini",
  templateUrl: "./exchange-list-mini.component.html",
  styleUrls: ["./exchange-list-mini.component.scss"],
  providers: [
    I18n,
    { provide: NgbDatepickerI18n, useClass: DatePickerTrLangService },
  ],
})
export class ExchangeListMiniComponent
  extends BaseListComponent
  implements OnInit, OnChanges
{
  @Input() filter: ExchangeFilter;
  @Input() title: string;

  defaultValue = null;
  currentBranch: Branch = JSON.parse(localStorage.getItem("currentBranch"));
  Currency = Currency;
  ExchangeState = ExchangeState;
  model: BasePagingResponse<ExchangeItem>;
  request: BasePagingRequest<ExchangeFilter> = {
    pageIndex: this.pageIndex,
    itemPerPage: this.itemPerPage,
    filter: {
      customerId: null,
      state: null,
    },
  };

  constructor(
    private service: ExchangeService,
    private dataService: DataService,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.filter.firstChange) {
      this.request.filter = changes.filter.currentValue;
      this.reloadData();
    }
  }

  ngOnInit() {
    if (this.filter) {
      this.request.filter = this.filter;
    }

    this.request.filter.branchId = this.currentBranch.id;
    this.service
      .getAll(this.request)
      .toPromise()
      .then((res) => {
        this.Loading = false;
        this.model = res;
      });
  }

  reloadData(goFirstPage: boolean = false) {
    this.Loading = true;
    if (goFirstPage) this.request.pageIndex = 1;
    localStorage.setItem("itemPerPage", this.request.itemPerPage.toString());
    this.service
      .getAll(this.request)
      .toPromise()
      .then((res) => {
        this.model = JSON.parse(JSON.stringify(res));
        this.Loading = false;
      });
  }


  clearFilter() {
    this.request.filter = new ExchangeFilter();
    this.request.filter.branchId = this.currentBranch.id;
    this.request.filter.customerId = null;
    this.request.filter.state = null;
    this.reloadData(true);
  }




}
