<nav class="navbar navbar-expand-lg navigation fixed-top sticky" (window:scroll)="windowScroll()" id="navbar">
  <div class="container">
    <a class="navbar-logo" routerLink="/">
      <img src="assets/images/logo-dark.png" alt="" height="19" class="logo logo-dark">
      <img src="assets/images/logo-light.png" alt="" height="19" class="logo logo-light">
    </a>

    <button type="button" class="btn btn-sm px-3 font-size-16 d-lg-none header-item" data-toggle="collapse"
      data-target="#topnav-menu-content" (click)="toggleMenu()">
      <i class="fa fa-fw fa-bars"></i>
    </button>

    <div class="collapse navbar-collapse" id="topnav-menu-content">
      <ul class="navbar-nav ms-auto" id="topnav-menu">
        <li class="nav-item">
          <a class="nav-link" href="tel:+905327076888"><i class="fas fa-mobile-alt me-1"></i> +90 532 707 68 88</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="javascript: void(0);" [ngxScrollTo]="'home'"
            [ngClass]="{'active':currentSection === 'home'}">Ana Sayfa</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="javascript: void(0);" [ngxScrollTo]="'about'"
            [ngClass]="{'active':currentSection === 'about'}">Hakkında</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="javascript: void(0);" [ngxScrollTo]="'features'" [ngxScrollToOffset]="5"
            [ngClass]="{'active':currentSection === 'features'}">Özellikler</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="javascript: void(0);" [ngxScrollTo]="'faqs'"
            [ngClass]="{'active':currentSection === 'faqs'}">SSS</a>
        </li>

      </ul>

      <div class="my-2 ms-lg-2">
        <a routerLink="/dashboard" class="btn btn-outline-success w-xs">Giriş Yap</a>
      </div>
    </div>
  </div>
</nav>

<div appScrollspy spiedTags="['SECTION']" (sectionChange)="onSectionChange($event)">
  <!-- hero section start -->
  <section class="section hero-section bg-ico-hero" id="home">
    <div class="bg-overlay bg-primary"></div>
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-5">
          <div class="text-white-50">
            <h1 class="text-white fw-semibold mb-3 hero-title">Sarraf - işletmeniz için ihtiyacınız olan her şey. </h1>
            <p class="font-size-14" style="color: #A1A6D7 !important;"> İçerisindeki kur robotu ile işlemlerinizi güncel
              kurlar üzerinden hızlıca yapın. </p>

            <div class="button-items mt-4">
              <button type="button" class="btn btn-success" (click)="open(content)"> Ön Talep Oluştur!</button>
              <a href="javascript: void(0);" class="btn btn-light">Nasıl çalışır?</a>
            </div>
          </div>
        </div>
        <div class="col-lg-7 col-md-8 col-sm-10 ms-lg-auto">
          <div class="card overflow-hidden mb-0 mt-5 mt-lg-0" style="background-color: rgba(0, 0, 0, 0);">
            <div class="card-body p-0">
              <div class="text-center">
                <img src="assets/images/landing/features-img/img-0.png" alt="" class="mx-auto img-fluid">

              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end row -->
    </div>
    <!-- Form -->
    <ng-template #content let-modal>
      <div class="modal-header" *ngIf="firstLoad">
        <h4 class="modal-title text-success" id="modal-basic-title">Ön Talep Formu</h4>

        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
      </div>
      <div class="modal-body" *ngIf="firstLoad">
        <form [formGroup]="preRequestForm" (ngSubmit)="send()">
          <div class="row">
            <div class="col">
              <div class="form-floating mb-3">
                <input type="text" id="name" formControlName="name" class="form-control input-sm" placeholder="Adınız">
                <label for="floatingnameInput">Adınız</label>
                <div *ngIf="form.name.invalid && (form.name.dirty || form.name.touched)" class="alert alert-danger">
                  <div *ngIf="form.name.errors?.['required']">adınız boş olmamalı.</div>
                  <div *ngIf="form.name.errors?.['minlength']">adınız en az 3 karakter barındırmalı.</div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="form-floating mb-3">
                <input type="email" id="email" formControlName="email" class="form-control input-sm"
                  placeholder="E-posta">
                <label for="floatingnameInput">E-posta</label>
                <div *ngIf="form.email.invalid && (form.email.dirty || form.email.touched)" class="alert alert-danger">
                  <div *ngIf="form.email.errors?.['required']">e-posta alanı boş olmamalı.</div>
                  <div *ngIf="form.email.errors?.['minlength']">e-postanız en az 3 karakter barındırmalı.</div>
                  <div *ngIf="form.email.errors?.['email']">e-posta formatına uygun olmalı.</div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="form-floating mb-3">
                <input type="tel" id="phone" formControlName="phone" class="form-control input-sm"
                  placeholder="Telefon">
                <label for="floatingnameInput">Telefon</label>
                <div *ngIf="form.phone.invalid && (form.phone.dirty || form.phone.touched)" class="alert alert-danger">
                  <div *ngIf="form.phone.errors?.['required']">telefon alanı boş olmamalı.</div>
                  <div *ngIf="form.phone.errors?.['minlength']">telefonunuz 11 karakter barındırmalı ve başında 0
                    olmalıdır.</div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="form-floating mb-3">
                <textarea type="text" id="message" formControlName="message" class="form-control"
                  placeholder="Mesajınız" rows="4" cols="50"> </textarea>
                <label for="floatingnameInput">Mesajınız</label>
                <div *ngIf="form.message.invalid && (form.message.dirty || form.message.touched)"
                  class="alert alert-danger">
                  <div *ngIf="form.message.errors?.['required']">mesajınız boş olmamalı.</div>
                  <div *ngIf="form.message.errors?.['minlength']">mesajınız en az 3 karakter barındırmalı.</div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" secretKey="secretKey"
                formControlName="recaptcha"></ngx-recaptcha2>
            </div>
          </div>
        </form>
      </div>

      <div class="modal-body text-center" *ngIf="!firstLoad">
        <h4 class="text-success"> <i class="fas fa-check-double"></i> Mesajınız iletildi! </h4>
      </div>
      <div class="modal-footer" *ngIf="firstLoad">
        <button type="submit" class="btn btn-outline-success" [disabled]="!preRequestForm.valid"
          (click)="send()">Gönder</button>
      </div>
    </ng-template>
    <!-- Form end -->
    <!-- end container -->
  </section>
  <!-- hero section end -->

  <!-- currency price section start -->
  <section class="section bg-white p-0">
    <div class="container">
      <div class="currency-price">
        <div class="row">
          <div class="col-md-4">
            <div class="card" data-aos="fade-left" data-aos-anchor-placement="bottom-bottom">
              <div class="card-body">
                <div class="media">
                  <div class="media-body text-center">
                    <h5>Talep Oluşturun <span>🙋‍♂️</span></h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
              <div class="card-body">
                <div class="media">
                  <div class="media-body text-center">
                    <h5>Detayları Konuşalım <span>🤝</span></h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card" data-aos="fade-right" data-aos-anchor-placement="bottom-bottom">
              <div class="card-body">
                <div class="media">
                  <div class="media-body text-center">
                    <h5>Kullanmaya Başlayın <span>⚡</span></h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- end row -->
      </div>
    </div>
    <!-- end container -->
  </section>
  <!-- currency price section end -->


  <!-- about section start -->
  <section class="section bg-white" id="about">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="text-center mb-5">
            <div class="small-title">Hakkında</div>
            <h4>Sarraf.io nedir?</h4>
          </div>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-lg-5">
          <div class="text-muted">
            <h4>Kuyumculuk sektörü için en yeni çözüm</h4>
            <p> Sarraf, kuyumculuk sektörüne özel olarak hazırlanan yapısıyla toptan satış, perakende satış, kuyum
              üretimi dahil tüm süreçleri baştan sona yönetebileceğiniz bir yazılımdır. </p>
            <p> Döviz ile altın alım satım, altın ile altın alım satımı, döviz ile ziynet alım satımı gibi işlemleri
              kolaylıkla yapmanıza imkan sağlar. </p>
            <p>Konumunuz, cihazınız ne olursa olsun sistem üzerinde gerçekleştirilen her işlemi anlık görebilecek ve
              yönetebileceksiniz. </p>
            <p class="mb-4">Temel olarak sunduğumuz paket dışında işletmenize özel çözümler için bizimle iletişime
              geçebilirsiniz.</p>
          </div>
        </div>
        <div class="col-lg-6 ms-auto">
          <div class="mt-4 mt-lg-0">
            <div class="row">
              <div class="col-sm-6">
                <p> <i class="fas fa-circle-notch me-1"></i> Canlı Piyasa Kurları </p>
                <p> <i class="fas fa-circle-notch me-1"></i> Anlık Bakiye Takibi </p>
                <p> <i class="fas fa-circle-notch me-1"></i> Personel Yetkilendirme </p>
                <p> <i class="fas fa-circle-notch me-1"></i> Çoklu Şube Desteği </p>
                <p> <i class="fas fa-circle-notch me-1"></i> Şubeler Arası Transfer </p>
                <p> <i class="fas fa-circle-notch me-1"></i> Hızlı Alım/Satım İşlemi </p>
              </div>
              <div class="col-sm-6">
                <p> <i class="fas fa-circle-notch me-1"></i> Müşteri Raporları </p>
                <p> <i class="fas fa-circle-notch me-1"></i> Üretim ve Maliyet Raporları </p>
                <p> <i class="fas fa-circle-notch me-1"></i> Has Üzerinden Kâr/Zarar Raporları </p>
                <p> <i class="fas fa-circle-notch me-1"></i> Dakikalar İçerisinde Kullanıma Hazır </p>
                <p> <i class="fas fa-circle-notch me-1"></i> Sade ve Anlaşılır Arayüzler </p>
                <p> <i class="fas fa-circle-notch me-1"></i> Tablet, Akıllı Telefon ve Bilgisayardan Erişim </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end row -->
      <div class="text-center my-5">
        <h4> İş Ortaklarımız</h4>
        <hr>
        <div class="row">
          <div class="col-lg-12">
            <owl-carousel-o [options]="carouselOption" class="events navs-carousel">
              <ng-template carouselSlide>
                <div class="item">
                  <div class="client-images">
                    <img src="assets/images/clients/client-ars.png" alt="client-img" class="mx-auto img-fluid d-block">
                  </div>
                </div>
              </ng-template>
            </owl-carousel-o>
          </div>
        </div>
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->
  </section>
  <!-- about section end -->

  <!-- Features start -->
  <section class="section" id="features">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="text-center mb-5">
            <div class="small-title">Özellikler</div>
            <h4>Uygulamanın Temel Modülleri</h4>
          </div>
        </div>
      </div>
      <!-- end row -->

      <div class="row align-items-center pt-4" data-aos="fade-right" data-aos-duration="1500"
        data-aos-anchor-placement="center-center">
        <div class="col-md-6 col-sm-8">
          <div>
            <img src="assets/images/landing/features-img/img-5.png" alt="" class="img-fluid mx-auto d-block">
          </div>
        </div>
        <div class="col-md-5 ms-auto">
          <div class="mt-4 mt-md-auto">
            <div class="d-flex align-items-center mb-2">
              <div class="features-number fw-semibold display-4 me-3">01</div>
              <h4 class="mb-0">Hızlı Alım/Satım Modülü</h4>
            </div>
            <p class="text-muted"> Pratik tasarlanmış ekranı sayesinde şube personeli müşterisinin ziynet alım-satımı,
              döviz bozdurma gibi işlemleri uygulama üzerinden hızlıca gerçekleştirebilir. Personeliniz alışverişi </p>
            <div class="text-muted mt-4">
              <p class="mb-2"><i class="mdi mdi-circle-medium text-success me-1"></i>Bağlantı Kur </p>
              <p><i class="mdi mdi-circle-medium text-success me-1 mb-2"></i>Borçlandır </p>
              <p><i class="mdi mdi-circle-medium text-success me-1 mb-2"></i>Tahsil edildi gibi alt işlem seçenekleri
                ile tamamlayabilir. </p>
            </div>
          </div>
        </div>
      </div>
      <!-- end row -->

      <div class="row align-items-center mt-5 pt-md-5" data-aos="fade-left" data-aos-duration="1500"
        data-aos-anchor-placement="center-center">
        <div class="col-md-5">
          <div class="mt-4 mt-md-0">
            <div class="d-flex align-items-center mb-2">
              <div class="features-number fw-semibold display-4 me-3">02</div>
              <h4 class="mb-0">Toptan Modülü</h4>
            </div>
            <p class="text-muted"> Şubenizin alım/satım yaptığı toptan müşterilerinizi kaydedebilir, yapacağınız
              alım/satım işlemini detaylarıyla uygulamaya ekleyebilirsiniz. </p>
            <div class="text-muted mt-4">
              <p class="mb-2"><i class="mdi mdi-circle-medium text-success me-1"></i>Varlıklarınızın stok kontrolünü
                kolayca yapmanızı sağlar.</p>
              <p class="mb-2"><i class="mdi mdi-circle-medium text-success me-1"></i>Ürün maliyetleriniz kayıt altında
                olur.</p>
              <p class="mb-2"><i class="mdi mdi-circle-medium text-success me-1"></i>Toptan müşterilerinizin borç-alacak
                durumlarını anlık olarak görmenizi sağlar.</p>
              <p class="mb-2"><i class="mdi mdi-circle-medium text-success me-1"></i>Bilanço raporunuz daha kolay
                hesaplanır.</p>
            </div>
          </div>
        </div>
        <div class="col-md-6  col-sm-8 ms-md-auto">
          <div class="mt-4 me-md-0">
            <img src="assets/images/landing/features-img/img-8.png" alt="" class="img-fluid mx-auto d-block">
          </div>
        </div>
      </div>
      <!-- end row -->
      <div class="row align-items-center mt-5 pt-md-5" data-aos="fade-right" data-aos-duration="1500"
        data-aos-anchor-placement="center-center">
        <div class="col-md-6 col-sm-8">
          <div>
            <img src="assets/images/landing/features-img/img-4.png" alt="" class="img-fluid mx-auto d-block">
          </div>
        </div>
        <div class="col-md-5 ms-auto">
          <div class="mt-4 mt-md-0">
            <div class="d-flex align-items-center mb-2">
              <div class="features-number fw-semibold display-4 me-3">03</div>
              <h4 class="mb-0">Perakende Modülü</h4>
            </div>
            <p class="text-muted"> Şubelerinize gelen her tezgah müşteriniz için kullanacağınız modül. Şube
              personelleriniz adına açılan kullanıcılar tarafından işlemler yürütülür.
            </p>
            <div class="text-muted mt-4">
              <p class="mb-2"><i class="mdi mdi-circle-medium text-success me-1"></i> Alım-satım yaparken güncel kurları
                görebilir ve dilerseniz güncel kur üzerinden dilerseniz kuru kendiniz girerek devam edebililirsiniz.</p>
              <p class="mb-2"><i class="mdi mdi-circle-medium text-success me-1"></i> İşlem anında kayıtlı veya kayıtlı
                olmayan müşteri seçme imkanı sunar.</p>
              <p class="mb-2"><i class="mdi mdi-circle-medium text-success me-1"></i> Altın alım-satımlarında saflık
                dereceleri üzerinden işlem yapabilirsiniz. </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row align-items-center mt-5 pt-md-5" data-aos="fade-left" data-aos-duration="1500"
        data-aos-anchor-placement="center-center">
        <div class="col-md-5">
          <div class="mt-4 mt-md-0">
            <div class="d-flex align-items-center mb-2">
              <div class="features-number fw-semibold display-4 me-3">04</div>
              <h4 class="mb-0">Üretim Modülü</h4>
            </div>
            <p class="text-muted"> Ziynet işleme ve ziynet üretimi yapan müşterilerimiz için özel tasarlanmıştır.</p>
            <div class="text-muted mt-4">
              <p class="mb-2"><i class="mdi mdi-circle-medium text-success me-1"></i> Detaylı parametreler ile ürün
                tanımlama </p>
              <p class="mb-2"><i class="mdi mdi-circle-medium text-success me-1"></i> Ürünleri hızlıca satışa çıkarma
              </p>
              <p class="mb-2"><i class="mdi mdi-circle-medium text-success me-1"></i> Üretim maliyetlerinizi girerek net
                kârınızı hesaplama </p>
            </div>
          </div>
        </div>
        <div class="col-md-6  col-sm-8 ms-md-auto">
          <div class="mt-4 me-md-0">
            <img src="assets/images/landing/features-img/img-10.png" alt="" class="img-fluid mx-auto d-block">
          </div>
        </div>
      </div>
      <div class="row align-items-center mt-5 pt-md-5" data-aos="fade-right" data-aos-duration="1500"
        data-aos-anchor-placement="center-center">
        <div class="col-md-6 col-sm-8">
          <div>
            <img src="assets/images/landing/features-img/img-6.png" alt="" class="img-fluid mx-auto d-block">
          </div>
        </div>
        <div class="col-md-5 ms-auto">
          <div class="mt-4 mt-md-0">
            <div class="d-flex align-items-center mb-2">
              <div class="features-number fw-semibold display-4 me-3">05</div>
              <h4 class="mb-0">Şube Modülü</h4>
            </div>
            <p class="text-muted"> Uygulamaya birden fazla şube tanımlayabilirsiniz. Yeni bir şube oluşturduğunuzda her
              bir ziynet ve para birimi üzerinden şube hesaplarınız otomatik oluşturulur.</p>
            <div class="text-muted mt-4">
              <p class="mb-2"><i class="mdi mdi-circle-medium text-success me-1"></i> Şubeleriniz arasında transfer
                imkanı</p>
              <p><i class="mdi mdi-circle-medium text-success me-1"></i> Şube bazlı anlık bakiye görüntüleme</p>
              <p><i class="mdi mdi-circle-medium text-success me-1"></i> Şube bazlı ve Personel bazlı işlem takibi</p>
              <p><i class="mdi mdi-circle-medium text-success me-1"></i> Her işlem sonrası güncellenen şube raporları ve
                grafikler</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row align-items-center mt-5 pt-md-5" data-aos="fade-left" data-aos-duration="1500"
        data-aos-anchor-placement="center-center">
        <div class="col-md-5">
          <div class="mt-4 mt-md-0">
            <div class="d-flex align-items-center mb-2">
              <div class="features-number fw-semibold display-4 me-3">06</div>
              <h4 class="mb-0">Müşteri Modülü</h4>
            </div>
            <p class="text-muted">Müşteri bir kuyumcunun en önemli paydaşıdır. Toptan ve perakende müşterilerinizi
              uygulamaya kaydedebilir ve işlem yapmaya başlayabilirsiniz.</p>
            <div class="text-muted mt-4">
              <p class="mb-2"><i class="mdi mdi-circle-medium text-success me-1"></i> Müşterilerinizin tüm işlemlerini
                görüntüleme </p>
              <p class="mb-2"><i class="mdi mdi-circle-medium text-success me-1"></i> Borcunu belirtilen sürede ödemeyen
                müşterileriniz için hatırlatıcı </p>
              <p class="mb-2"><i class="mdi mdi-circle-medium text-success me-1"></i> Müşteri borcunu diğer birimlere
                dönüştürme </p>
              <p class="mb-2"><i class="mdi mdi-circle-medium text-success me-1"></i> Müşteri raporlarıyla durum takibi
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6  col-sm-8 ms-md-auto">
          <div class="mt-4 me-md-0">
            <img src="assets/images/landing/features-img/img-7.png" alt="" class="img-fluid mx-auto d-block">
          </div>
        </div>
      </div>
      <div class="row align-items-center mt-5 pt-md-5" data-aos="fade-right" data-aos-duration="1500"
        data-aos-anchor-placement="center-center">
        <div class="col-md-6 col-sm-8">
          <div>
            <img src="assets/images/landing/features-img/img-9.png" alt="" class="img-fluid mx-auto d-block">
          </div>
        </div>
        <div class="col-md-5 ms-auto">
          <div class="mt-4 mt-md-0">
            <div class="d-flex align-items-center mb-2">
              <div class="features-number fw-semibold display-4 me-3">07</div>
              <h4 class="mb-0">Raporlama Modülü</h4>
            </div>
            <p class="text-muted"> Çeşitli raporlar ile işletmenizin kâr-zarar, bilanço verilerini dilediğiniz an
              görüntüleyebilirsiniz. Bir çok durumda zaman ve paradan tasarruf etmenizi sağlar.</p>
            <div class="text-muted mt-4">
              <p class="mb-2"><i class="mdi mdi-circle-medium text-success me-1"></i>Tüm şubelerinize ait raporlar</p>
              <p class="mb-2"><i class="mdi mdi-circle-medium text-success me-1"></i>Şube bazlı raporlar</p>
              <p class="mb-2"><i class="mdi mdi-circle-medium text-success me-1"></i>Müşteri bazlı raporlar</p>
              <p class="mb-2"><i class="mdi mdi-circle-medium text-success me-1"></i>Toptan işlem raporları</p>
              <p class="mb-2"><i class="mdi mdi-circle-medium text-success me-1"></i>Perakende işlem raporları</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end container -->
  </section>
  <!-- Features end -->


  <!-- Faqs start -->
  <section class="section" id="faqs">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="text-center mb-5">
            <div class="small-title">SSS</div>
            <h4>Sık Sorulan Sorular</h4>

          </div>
        </div>
      </div>
      <!-- end row -->

      <div class="row">
        <div class="col-lg-12">
          <div class="vertical-nav">
            <div class="row">
              <div class="col-lg-2 col-sm-4">
                <ul ngbNav #nav="ngbNav" [activeId]="1" class="nav-pills flex-column">
                  <li [ngbNavItem]="1">
                    <a ngbNavLink>
                      <i class="bx bx-help-circle nav-icon d-block mb-2"></i>
                      <p class="fw-bold mb-0">Genel Sorular</p>
                    </a>
                    <ng-template ngbNavContent>
                      <div class="card">
                        <div class="card-body">
                          <h4 class="card-title mb-4">Genel Sorular</h4>

                          <ngb-accordion [closeOthers]="true" activeIds="static-1" class="custom-accordion">
                            <ngb-panel id="static-1">
                              <ng-template ngbPanelTitle>
                                <a href="javascript: void(0);" class="accordion-list">
                                  <div>Sarraf.io nedir ve ne işe yarar?</div>
                                </a>
                              </ng-template>
                              <ng-template ngbPanelContent>
                                <p class="mb-0">Sarraf.io, kuyumculuk sektörüne özel olarak geliştirilen bir yazılımdır.
                                  Toptan satış, perakende satış, kuyum üretimi dahil olmak üzere kuyumculuk
                                  işletmelerinin tüm süreçlerini baştan sona yönetmeyi sağlar. Ayrıca döviz ile altın
                                  alım satımı, altın ile altın alım satımı, döviz ile ziynet alım satımı gibi işlemleri
                                  kolaylıkla gerçekleştirmenizi sağlar.</p>
                              </ng-template>
                            </ngb-panel>
                            <ngb-panel id="static-2">
                              <ng-template ngbPanelTitle>
                                <a href="javascript: void(0);" class="accordion-list">
                                  <div>Sarraf.io'yu hangi işletmeler kullanabilir?</div>
                                </a>
                              </ng-template>
                              <ng-template ngbPanelContent>
                                <p class="mb-0">Sarraf.io, kuyumculuk sektöründe faaliyet gösteren her türlü işletme
                                  için uygundur. Toptan ve perakende satış yapanlar, kuyum üretenler ve döviz büroları
                                  gibi bu sektörde
                                  faaliyet gösteren diğer işletmeler Sarraf.io'dan faydalanabilir.</p>
                              </ng-template>
                            </ngb-panel>
                            <ngb-panel id="static-3">
                              <ng-template ngbPanelTitle>
                                <a href="javascript: void(0);" class="accordion-list">
                                  <div>Sarraf.io'yu kullanmak için teknik bilgiye ihtiyacım var mı?</div>
                                </a>
                              </ng-template>
                              <ng-template ngbPanelContent>
                                <p class="mb-0">Hayır, Sarraf.io kullanımı oldukça kolay ve kullanıcı dostudur. Sade ve
                                  anlaşılır arayüzler sayesinde herhangi bir teknik bilgiye ihtiyaç duymadan sistemi
                                  kullanabilirsiniz.</p>
                              </ng-template>
                            </ngb-panel>
                            <ngb-panel id="static-4">
                              <ng-template ngbPanelTitle>
                                <a href="javascript: void(0);" class="accordion-list">
                                  <div>Sarraf.io'nun faydaları nelerdir?</div>
                                </a>
                              </ng-template>
                              <ng-template ngbPanelContent>
                                <p class="mb-0">Sarraf.io, işletmenizin verimliliğini artırır, süreçleri
                                  otomatikleştirir, stok yönetimini kolaylaştırır, müşteri ilişkilerini güçlendirir ve
                                  finansal analizler yapmanızı sağlar. Ayrıca, anlık bakiye takibi, personel
                                  yetkilendirme, çoklu şube desteği gibi birçok avantaj sunar.</p>
                              </ng-template>
                            </ngb-panel>
                          </ngb-accordion>
                        </div>
                      </div>
                    </ng-template>
                  </li>
                  <li [ngbNavItem]="2">
                    <a ngbNavLink>
                      <i class="bx bx-receipt nav-icon d-block mb-2"></i>
                      <p class="fw-bold mb-0">Teknik Sorular</p>
                    </a>
                    <ng-template ngbNavContent>
                      <div class="card">
                        <div class="card-body">
                          <h4 class="card-title mb-4">Teknik Sorular</h4>

                          <ngb-accordion [closeOthers]="true" activeIds="static-1" class="custom-accordion">
                            <ngb-panel id="static-1">
                              <ng-template ngbPanelTitle>
                                <a href="javascript: void(0);" class="accordion-list">
                                  <div>Sarraf.io'yu hangi cihazlardan kullanabilirim?</div>
                                </a>
                              </ng-template>
                              <ng-template ngbPanelContent>
                                <p class="mb-0">Sarraf.io'ya tablet, akıllı telefon ve bilgisayar üzerinden
                                  erişebilirsiniz. Bu sayede konumunuz veya cihazınız ne olursa olsun işlemlerinizi
                                  anlık olarak gerçekleştirebilirsiniz.</p>
                              </ng-template>
                            </ngb-panel>
                            <ngb-panel id="static-2">
                              <ng-template ngbPanelTitle>
                                <a href="javascript: void(0);" class="accordion-list">
                                  <div>Sarraf.io'nun kurulumu ve kullanımı ne kadar zaman alır?</div>
                                </a>
                              </ng-template>
                              <ng-template ngbPanelContent>
                                <p class="mb-0">Sarraf.io dakikalar içerisinde kullanıma hazır hale gelir. Basit ve
                                  hızlı bir kurulum sürecinin ardından işletmenizin ihtiyaçlarına uygun olarak
                                  kullanmaya başlayabilirsiniz.</p>
                              </ng-template>
                            </ngb-panel>

                            <ngb-panel id="static-3">
                              <ng-template ngbPanelTitle>
                                <a href="javascript: void(0);" class="accordion-list">
                                  <div>Sarraf.io'nun güvenliği nasıl sağlanmaktadır?</div>
                                </a>
                              </ng-template>
                              <ng-template ngbPanelContent>
                                <p class="mb-0">Sarraf.io güvenlik önlemleriyle donatılmıştır ve en son teknolojiler
                                  kullanılarak güçlendirilmiştir. Verileriniz güvenli bir şekilde saklanır ve
                                  işlemleriniz SSL şifreleme ile korunur.</p>
                              </ng-template>
                            </ngb-panel>
                            <ngb-panel id="static-4">
                              <ng-template ngbPanelTitle>
                                <a href="javascript: void(0);" class="accordion-list">
                                  <div>Sarraf.io'nun entegrasyon imkanları nelerdir?</div>
                                </a>
                              </ng-template>
                              <ng-template ngbPanelContent>
                                <p class="mb-0">Sarraf.io, diğer işletme yazılımlarıyla entegre olabilir ve işletmenizin
                                  diğer sistemleriyle sorunsuz bir şekilde çalışabilir.</p>
                              </ng-template>
                            </ngb-panel>
                          </ngb-accordion>
                        </div>
                      </div>
                    </ng-template>
                  </li>
                  <li [ngbNavItem]="3">
                    <a ngbNavLink>
                      <i class="bx bx-timer d-block nav-icon mb-2"></i>
                      <p class="fw-bold mb-0">Fiyatlandırma ve Destek Soruları</p>
                    </a>
                    <ng-template ngbNavContent>
                      <div class="card">
                        <div class="card-body">
                          <h4 class="card-title mb-4">Fiyatlandırma ve Destek Soruları</h4>

                          <ngb-accordion [closeOthers]="true" activeIds="static-1" class="custom-accordion">
                            <ngb-panel id="static-1">
                              <ng-template ngbPanelTitle>
                                <a href="javascript: void(0);" class="accordion-list">
                                  <div>Sarraf.io'nun fiyatlandırması nasıl yapılır?</div>
                                </a>
                              </ng-template>
                              <ng-template ngbPanelContent>
                                <p class="mb-0">Fiyatlandırma işletmenizin ihtiyaçlarına ve kullanıcı sayısına göre
                                  değişiklik gösterir. Size özel çözümler için bizimle iletişime geçebilirsiniz.</p>
                              </ng-template>
                            </ngb-panel>
                            <ngb-panel id="static-2">
                              <ng-template ngbPanelTitle>
                                <a href="javascript: void(0);" class="accordion-list">
                                  <div>Sarraf.io'nun sunduğu destek hizmetleri nelerdir?</div>
                                </a>
                              </ng-template>
                              <ng-template ngbPanelContent>
                                <p class="mb-0">Sarraf.io, kullanıcılarına teknik destek, eğitim ve danışmanlık
                                  hizmetleri sunar. Uzman ekip tarafından her türlü sorunuz ve ihtiyacınız için destek
                                  sağlanır.</p>
                              </ng-template>
                            </ngb-panel>
                            <ngb-panel id="static-3">
                              <ng-template ngbPanelTitle>
                                <a href="javascript: void(0);" class="accordion-list">
                                  <div>Sarraf.io'nun lisans süresi nedir?</div>
                                </a>
                              </ng-template>
                              <ng-template ngbPanelContent>
                                <p class="mb-0">Lisans süresi işletmenizin tercihine göre belirlenir. Esnek lisans
                                  modelleri ile istediğiniz zaman lisansınızı yenileyebilir veya güncelleyebilirsiniz.
                                </p>
                              </ng-template>
                            </ngb-panel>
                            <ngb-panel id="static-4">
                              <ng-template ngbPanelTitle>
                                <a href="javascript: void(0);" class="accordion-list">
                                  <div>Sarraf.io'yu nasıl satın alabilirim?</div>
                                </a>
                              </ng-template>
                              <ng-template ngbPanelContent>
                                <p class="mb-0">Sarraf.io'yu satın almak veya daha fazla bilgi almak için web
                                  sitemizdeki iletişim formunu doldurarak veya doğrudan bizimle iletişime geçerek satış
                                  ekibimizden destek alabilirsiniz.</p>
                              </ng-template>
                            </ngb-panel>
                          </ngb-accordion>
                        </div>
                      </div>
                    </ng-template>
                  </li>
                </ul>
              </div>
              <div class="col-lg-10">
                <div [ngbNavOutlet]="nav"></div>
              </div>
            </div>
            <!-- end vertical nav -->
          </div>
        </div>
        <!-- end row -->
      </div>
    </div>
    <!-- end container -->
  </section>
  <!-- Faqs end -->
  <!-- Footer start -->
  <footer class="landing-footer">
    <div class="container">

      <div class="row">
        <div class="col-lg-4 col-sm-6">
          <div class="mb-4 mb-lg-0">
            <h5 class="mb-3 footer-list-title">Uygulama</h5>
            <ul class="list-unstyled footer-list-menu">
              <li><a href="javascript: void(0);" [ngxScrollTo]="'about'">Hakkında</a></li>
              <li><a href="javascript: void(0);" [ngxScrollTo]="'features'">Özellikler</a></li>
              <li><a href="javascript: void(0);" [ngxScrollTo]="'faqs'">Sık Sorulan Sorular</a></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6">
          <div class="mb-4 mb-lg-0">
            <h5 class="mb-3 footer-list-title">Kaynaklar</h5>
            <ul class="list-unstyled footer-list-menu">
              <li><a href="javascript: void(0);">Güvenlik Politikası</a></li>
              <li><a href="javascript: void(0);">Koşullar ve diğer Politikalar</a></li>
            </ul>
          </div>
        </div>

        <div class="col-lg-4 col-sm-6">
          <div class="mb-4 mb-lg-0">
            <h5 class="mb-3 footer-list-title">Son Haberler</h5>
            <div class="blog-post">
              <a href="javascript: void(0);" class="post">
                <div class="badge badge-soft-success font-size-11 mb-3">Sarraf.io</div>
                <h5 class="post-title">İlk versiyon yayınlandı <span> 🐣 </span> </h5>
                <p class="mb-0"><i class="bx bx-calendar me-1"></i> 10 Nis, 2022</p>
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- end row -->
      <hr class="footer-border my-5">
      <div class="row">
        <div class="col-lg-6">
          <div class="mb-4">
            <img src="assets/images/logo-light.png" alt="" height="20"> v{{environment.version}}
          </div>
          <p class="mb-2">{{year}} © SARRAF.IO - Design & Develop by ZNOSoft </p>
        </div>
      </div>
    </div>
    <!-- end container -->
  </footer>
  <!-- Footer end -->
</div>
