import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseResponse } from 'src/app/core/models/responses/base-response';
import { environment } from 'src/environments/environment';
import { AccountTransaction } from 'src/app/models/account-transaction/account-transaction';
import { BranchBalanceRequest } from 'src/app/models/branch/branch-balance-request';
import { RootBalanceResponse } from 'src/app/models/account/root-account-balance-response';
import { SummaryResponse } from 'src/app/models/receipts/exchange-summary';
import { BaseListResponse } from 'src/app/core/models/responses/base-list-response';
import { BasePagingResponse } from 'src/app/core/models/responses/base-paging-response';
import { BasePagingRequest } from 'src/app/core/models/requests/base-paging-request';
import { AccountTransactionFilter } from 'src/app/models/filters/account-transaction-filter';
import { AccountTransactionBatch } from 'src/app/models/account-transaction/account-transaction-batch';
import { BaseService } from '../core/services/base.service';
import { RootAccountBalanceDTO } from '../models/account/root-account-balance-dto';
import { AccountBalanceDTO } from '../models/account/account-balance-dto';
import { Customer } from '../models/customer/customer';
import { Parity } from '../models/exchange/parity';
import { FinancialStatementRequest } from '../models/account-transaction/financial-statement-request';
import { FinancialStatementResponse } from '../models/account-transaction/financial-statement-response';
import { Subject } from '@microsoft/signalr';
import { SelectedAccountTransactionListRequest } from '../models/account-transaction/selected-account-transaction-list-request';

@Injectable({
  providedIn: 'root'
})
export class AccountTransactionService extends BaseService<AccountTransaction, AccountTransactionFilter, number>{

  constructor(protected _http: HttpClient) {
    super(_http, environment.urlAddress + '/AccountTransaction');
  }

  createBatch(model: AccountTransactionBatch): Observable<BaseResponse<AccountTransactionBatch>>{
    return this._http.post<BaseResponse<AccountTransactionBatch>>(environment.urlAddress + '/AccountTransaction/create-batch', model)
  }

  getCaseBalances(request: BranchBalanceRequest): Observable<BaseResponse<RootBalanceResponse>>{
    return this._http.post<BaseResponse<RootBalanceResponse>>(environment.urlAddress + '/AccountTransaction/get-case-balances', request);
  }

  getAllCaseHasBalances(request: BranchBalanceRequest): Observable<BaseResponse<AccountBalanceDTO[]>>{
    return this._http.post<BaseResponse<RootAccountBalanceDTO[]>>(environment.urlAddress + '/AccountTransaction/get-all-case-has-balances', request);
  }

  getFinancialStatement(request: FinancialStatementRequest): Observable<BaseResponse<FinancialStatementResponse>>{
    return this._http.post<BaseResponse<FinancialStatementResponse>>(environment.urlAddress + '/AccountTransaction/financial-statement', request);
  }

  getByGroupId(groupId: string): Observable<BaseResponse<AccountTransaction>>{
    return this._http.get<BaseResponse<AccountTransaction>>(environment.urlAddress + '/AccountTransaction/get-by-group-id/' + groupId)
  }

  customerTransactionsSummary(request: AccountTransactionFilter): Observable<BasePagingResponse<AccountTransaction>> {
    let basePagingRequest = new BasePagingRequest<AccountTransactionFilter>();
    basePagingRequest.filter = request;
    basePagingRequest.itemPerPage = 100;
    basePagingRequest.pageIndex = 1;
    return this._http.post<BasePagingResponse<AccountTransaction>>(environment.urlAddress + '/AccountTransaction/customer-transactions-summary', basePagingRequest)
  }

  customerTransactionsSummaryWithIds(request: SelectedAccountTransactionListRequest): Observable<BasePagingResponse<AccountTransaction>> {
    request.pageIndex = 1;
    request.itemPerPage = 100;
    return this._http.post<BasePagingResponse<AccountTransaction>>(environment.urlAddress + '/AccountTransaction/customer-transactions-summary-with-ids', request)
  }

  getCaseNameByGroupId(id: string): Observable<BaseResponse<string>>{
    return this._http.get<BaseResponse<string>>(this._base + '/get-casename-by-groupid/' + id);
  }

  getCustomerByGroupId(id: string): Observable<BaseResponse<Customer>>{
    return this._http.get<BaseResponse<Customer>>(this._base + '/get-customer-by-groupid/' + id);
  }

  getCaseNameById(id: number): Observable<BaseResponse<AccountTransaction>>{
    return this._http.get<BaseResponse<AccountTransaction>>(this._base + '/get-casename-by-id/' + id);
  }

}
