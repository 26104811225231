import { Component, OnInit } from '@angular/core';
import { extend } from 'chartist';
import { Role } from 'src/app/core/models/role.enum';

@Component({
  selector: 'app-base-list',
  templateUrl: './base-list.component.html',
  styleUrls: ['./base-list.component.scss']
})
export class BaseListComponent implements OnInit {
  Role = Role;
  Loading: boolean = true;
  constructor() { }

  public itemPerPage = 10
  public pageIndex = 1

  ngOnInit(): void {
  }

}
