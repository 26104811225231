import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private toasterService: ToastrService) { }
  private successHeader = "Başarılı";
  private errorHeader = "Hata";
  private warningHeader = "Uyarı";
  private infoHeader = "Bilgi";
  private successMessage = "İşlem gerçekleştirildi.";

  showSuccess(message?: string){
    if(message)
      this.toasterService.success(message, this.successHeader, {timeOut: 2500, extendedTimeOut: 2500});
    else
      this.toasterService.success(this.successMessage, this.successHeader, { timeOut: 2500, extendedTimeOut: 2500});
  }
  showError(message: string){
    this.toasterService.error(message, this.errorHeader, { timeOut: 4000, extendedTimeOut: 4000});
  }
  showWarning(message:string){
    this.toasterService.warning(message, this.warningHeader, { timeOut: 4000, extendedTimeOut: 4000 });
  }
  showInfo(message: string){
    this.toasterService.info(message, this.infoHeader, { timeOut: 4000 , extendedTimeOut: 4000});
  }
  
}


