import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerAccountCreateEditModalModule } from './customer-account-create-edit-modal/customer-account-create-edit-modal.module';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CustomerAccountCreateEditModalModule
  ]
})
export class CustomerAccountsModule { }
