import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgApexchartsModule } from 'ng-apexcharts';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { BranchBalanceChartComponent } from './branch-balance-chart/branch-balance-chart.component';
import { BasicColumnChartComponent } from './basic-column-chart/basic-column-chart.component';
import { FormsModule } from '@angular/forms';
import { BranchBalancePieChartComponent } from './branch-balance-pie-chart/branch-balance-pie-chart.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { NgxEchartsModule } from 'ngx-echarts';
import { CoreModule } from 'src/app/core/core.module';
import { ComponentsModule } from 'src/app/shared/components/components.module';
import { ExpenseIncomeChartComponent } from './expense-income-chart/expense-income-chart.component';
import { NegativeBarChartComponent } from './negative-bar-chart/negative-bar-chart.component';



@NgModule({
  declarations: [BranchBalanceChartComponent, ExpenseIncomeChartComponent, BasicColumnChartComponent, BranchBalancePieChartComponent, NegativeBarChartComponent],
  imports: [
    CommonModule,
    NgApexchartsModule,
    PipesModule,
    FormsModule,
    CoreModule,
    ComponentsModule,
    HighchartsChartModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    })
  ],
  exports: [
    BranchBalanceChartComponent,
    ExpenseIncomeChartComponent,
    BranchBalancePieChartComponent,
    NegativeBarChartComponent,
  ]
})
export class ChartComponentsModule { }
