import { Component,  OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AccountBalanceDTO } from "src/app/models/account/account-balance-dto";
import { Branch } from "src/app/models/branch/branch";
import { Currency } from "src/app/models/enums/currency";
import { CurrencySubTypes } from "src/app/models/enums/currency-sub-types";
import { AccountTransactionCreateModalComponent } from "../../account-transactions/account-transaction-create-modal/account-transaction-create-modal.component";
import { BranchService } from "src/app/services/branch.service";
import { WidgetService } from "src/app/services/widget.service";
import { AccountTransactionService } from "src/app/services/account-transaction.service";

@Component({
  selector: "app-branch-balance",
  templateUrl: "./branch-balance.component.html",
  styleUrls: ["./branch-balance.component.scss"],
})
export class BranchBalanceComponent implements OnInit {
  branch: Branch = JSON.parse(localStorage.getItem("currentBranch"));
  Currency = Currency;
  tlBalance: AccountBalanceDTO= {}; 
  usdBalance: AccountBalanceDTO = {}; 
  euroBalance: AccountBalanceDTO = {};
  goldBalance: AccountBalanceDTO = {};
  goldNewBalance: AccountBalanceDTO = {};
  goldIgnotBalance: AccountBalanceDTO = {};
  accessoriesBalance: AccountBalanceDTO = {};
  otherAccessoriesBalance: AccountBalanceDTO = {};
  branchBalanceList: AccountBalanceDTO[] = [
    {endDate: new Date(), currency: Currency.Tl, subType: null, balance: 0, customersBalance: 0, finalBalance:0}, 
    {endDate: new Date(), currency: Currency.Gold, subType: CurrencySubTypes.Has, balance: 0, customersBalance: 0, finalBalance:0}, 
    {endDate: new Date(), currency: Currency.Usd, subType: null, balance: 0, customersBalance: 0, finalBalance:0}, 
    {endDate: new Date(), currency: Currency.Euro, subType: null, balance: 0, customersBalance: 0, finalBalance:0}, 
    {endDate: new Date(), currency: Currency.GoldIgnot, subType: CurrencySubTypes.Has, balance: 0, customersBalance: 0, finalBalance:0}, 
    {endDate: new Date(), currency: Currency.GoldNew, subType: CurrencySubTypes.Has, balance: 0, customersBalance: 0, finalBalance:0}, 
    {endDate: new Date(), currency: Currency.Accessories, subType: CurrencySubTypes.Ceyrek, balance: 0, customersBalance: 0, finalBalance:0}, 
  ];

  // cardHeaderClass;
  // headerClass;
  // iconClass;

  constructor(
    private modalService: NgbModal,
    private branchService: BranchService,
    private widgetService: WidgetService,
    private accountTransactionService: AccountTransactionService
  ) {}

  ngOnInit(): void {
    // this.setClasses();
    this.loadBranchBalanceList();
    this.accountTransactionService.refreshIndex.subscribe((res) => {
      this.loadBranchBalanceList();
    });
  }
  openAccountTransactionAddModal(currency: Currency) {
    const modalRef = this.modalService.open(
      AccountTransactionCreateModalComponent
    );
    modalRef.componentInstance.branchPreloaded = this.branch;
    modalRef.componentInstance.currencyPreloaded = currency;
    const subType = currency == Currency.Gold || currency == Currency.GoldNew || currency == Currency.GoldIgnot ? null : CurrencySubTypes.Has;
    modalRef.componentInstance.subTypePreloaded = subType;
    modalRef.componentInstance.isCustomerTransaction = false;
  }

  loadBranchBalanceList() {
    this.widgetService.getBranchBalancesWithMerge(this.branch.id)
      .toPromise()
      .then((res) => {
        if (res.isSuccess) {
          if (res.data.length > 0) {
            this.branchBalanceList = res.data;
            this.setBalanceList();
          }          
        }
      });
  }

  setCustomerBalanceColor(number: number): string{
    return number >= 0 ? 'text-success' : 'text-danger';
  }

  setBalanceList(){
    this.tlBalance = this.branchBalanceList.find(p=>p.currency == Currency.Tl)
    this.usdBalance = this.branchBalanceList.find(p=>p.currency == Currency.Usd) 
    this.euroBalance = this.branchBalanceList.find(p=>p.currency == Currency.Euro)
    this.goldBalance = this.branchBalanceList.find(p=>p.currency == Currency.Gold)
    this.goldNewBalance = this.branchBalanceList.find(p=>p.currency == Currency.GoldNew)
    this.goldIgnotBalance = this.branchBalanceList.find(p=>p.currency == Currency.GoldIgnot)
    this.accessoriesBalance = this.branchBalanceList.find(p=>p.currency == Currency.Accessories)
    this.otherAccessoriesBalance = this.branchBalanceList.find(p=>p.currency == Currency.OtherAccessories)
  }

  
  // setBalanceList() {
  //   this.model = this.branchBalanceList.find(
  //     (p) => p.currency === this.currency
  //   );
  // }

  // setClasses() {
  //   if (this.currency === Currency.Tl) {
  //     this.cardHeaderClass = "card-header bg-white border-bottom border-danger";
  //     this.headerClass = "text-danger mt-2";
  //     this.iconClass = "fas fa-lira-sign float-end me-2";
  //   }
  //   if (this.currency === Currency.Gold) {
  //     this.cardHeaderClass =
  //       "card-header bg-white border-bottom border-warning";
  //     this.headerClass = "text-warning mt-2";
  //     this.iconClass = "fas fa-coins float-end me-2";
  //   }
  //   if (this.currency === Currency.Usd) {
  //     this.cardHeaderClass =
  //       "card-header bg-white border-bottom border-success";
  //     this.headerClass = "text-success mt-2";
  //     this.iconClass = "fas fa-dollar-sign mb-0 float-end me-2";
  //   }
  //   if (this.currency === Currency.Euro) {
  //     this.cardHeaderClass = "card-header bg-white border-bottom border-info";
  //     this.headerClass = "text-info mt-2";
  //     this.iconClass = "fas fa-euro-sign float-end me-2";
  //   }
  // }
}
