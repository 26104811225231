<app-role-based [roles]="[Role.User]" [content]="content" (initState)="this.reloadData()"></app-role-based>
<ng-template #content>
<div class="card" *ngIf="chartOptions && chartOptions.series.length > 0">
    <div class="card-body">
        <div class="d-sm-flex flex-wrap">
            <h4 class="card-title mb-5" *ngIf="!isIncome"> Gider Grafiği </h4>
            <h4 class="card-title mb-5" *ngIf="isIncome"> Gelir Grafiği </h4>
            <div class="ms-auto">
                <ul class="nav nav-pills mb-3">
                    <li class="nav-item">
                        <a (click)="changePeriod(ChartPeriodType.Yearly)"
                            [class.active]="periodType == ChartPeriodType.Yearly" class="nav-link">Yıllık</a>
                    </li>
                    <li class="nav-item">
                        <a (click)="changePeriod(ChartPeriodType.Monthly)"
                            [class.active]="periodType == ChartPeriodType.Monthly" class="nav-link">Aylık</a>
                    </li>
                    <li class="nav-item">
                        <a (click)="changePeriod(ChartPeriodType.Weekly)"
                            [class.active]="periodType == ChartPeriodType.Weekly" class="nav-link">Haftalık</a>
                    </li>
                </ul>
            </div>
            <div class="ms-auto">
                <div class="form-group d-flex mb-3">
                    <select id="currency" [(ngModel)]="currency" (change)="currencyChanged()" class="form-control">
                    <option *ngFor="let item of currencyList" [ngValue]="item">{{item | customCurrency }} </option>
                    </select>
                    <select id="subType" [(ngModel)]="subType" (change)="reloadData()" class="form-control ms-2" *ngIf="subTypeList.length > 0">
                        <option *ngFor="let item of subTypeList" [ngValue]="item">{{item | subTypeDisplay }} </option>
                        </select>
                </div>
            </div>
        </div>
        <div id="chart">
            <apx-chart class="apex-charts" dir="ltr" [series]="chartOptions.series" [chart]="chartOptions.chart"
                [dataLabels]="chartOptions.dataLabels" [plotOptions]="chartOptions.plotOptions"
                [responsive]="chartOptions.responsive" [xaxis]="chartOptions.xaxis" [legend]="chartOptions.legend"
                [fill]="chartOptions.fill" [accessKey]="itemKey"></apx-chart>
        </div>
    </div>
</div>
</ng-template>

