import { Component , OnInit} from '@angular/core';
import AOS from 'aos';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit  {

  ngOnInit() {
    AOS.init();
    // document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
  }
}
