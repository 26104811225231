import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaseTransferModalComponent } from './case-transfer-modal.component';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    CaseTransferModalComponent
  ],
  imports: [
    CommonModule,
    NgbModalModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  exports: [
    CaseTransferModalComponent
  ]
})
export class CaseTransferModalModule { }