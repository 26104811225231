import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerBalanceModule } from './customer-balance/customer-balance.module';
import { VerticalBalanceListComponent } from './vertical-balance-list/vertical-balance-list.component';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CustomerBalanceModule
  ]
})
export class BalancesModule { }
