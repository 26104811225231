import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaseCreateModalComponent } from './case-create-modal.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { ComponentsModule } from 'src/app/shared/components/components.module';
import { CoreModule } from 'src/app/core/core.module';



@NgModule({
  declarations: [
    CaseCreateModalComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgbModalModule,
    ComponentsModule,
    CoreModule
  ],
  exports: [
    CaseCreateModalComponent
  ]
})
export class CaseCreateModalModule { }
