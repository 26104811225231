import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BranchAddComponent } from './branch-add/branch-add.component';
import { FormsModule } from '@angular/forms';
import { CaseAddComponent } from './case-add/case-add.component';
import { RoleAddComponent } from './role-add/role-add.component';
import { NgbTooltip, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
  declarations: [
    BranchAddComponent,
    CaseAddComponent,
    RoleAddComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbTooltipModule
  ],
  exports: [
    BranchAddComponent,
    CaseAddComponent,
    RoleAddComponent
  ]
})
export class AccessRightsModule { }
