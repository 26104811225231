import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';
import { Page404Component } from './extrapages/page404/page404.component';
import LandingComponent from './landing/landing.component';
import { ModalViewComponent } from './shared/components/modal-view/modal-view.component';
import { LayoutComponent } from './ui/layouts/layout.component';
import { BarcodePrintComponent } from './ui/layouts/printing/barcode-print/barcode-print.component';
import { LaserPrintComponent } from './ui/layouts/printing/laser-print/laser-print.component';

const routes: Routes = [
  { path: '', component: LandingComponent },
  { path: 'login', loadChildren: () => import('./ui/pages/login/login.module').then(m => m.LoginModule) },
  { path: '', component: LayoutComponent, loadChildren: () => import('./ui/pages/pages.module').then(m => m.PagesModule ), canActivate: [AuthGuard] },
  { path: 'barcodes', component: BarcodePrintComponent, loadChildren: () => import('./ui/pages/barcodes/barcodes.module').then(m => m.BarcodesModule), canActivate: [AuthGuard]},
  { path: 'receipts', component: LaserPrintComponent, loadChildren: () => import('./ui/pages/receipts/receipts.module').then(m => m.ReceiptsModule), canActivate: [AuthGuard]},
  { path: 'extrapages', loadChildren: () => import('./extrapages/extrapages.module').then(m => m.ExtrapagesModule), canActivate: [AuthGuard] },
  { path: '**', component: Page404Component },

  // {
  //   path: "modal",
  //   outlet: "modal",
  //   component: ModalViewComponent,
  //   children: [
  //     // {path: "customer/create",component: CustomerCreateComponent}, 
  //     // {path: "customer/edit/:id", component: CustomerEditComponent},
  //     {path: "access-right/create",component: AccessRightCreateComponent}, 
  //     {path: "access-right/edit/:id",component: AccessRightEditComponent},
  // ]
  // },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation:'reload', scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy'})],
  exports: [RouterModule]
})

export class AppRoutingModule { }
