import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: 'branch-transfer', loadChildren: () => import('./branch-transfer/branch-transfer.module').then(m => m.BranchTransferModule) },
  { path: 'branch-money-entry', loadChildren: () => import('./branch-money-entry/branch-money-entry.module').then(m=>m.BranchMoneyEntryModule)},
  { path: 'customer-virman', loadChildren: () => import('./customer-virman/customer-virman.module').then(m=>m.CustomerVirmanModule)}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OperationsRoutingModule { }
