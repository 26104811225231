import { Pipe, PipeTransform } from '@angular/core';
import { AccountTransactionType } from 'src/app/models/enums/account-transaction-type';

@Pipe({
  name: 'collectionType'
})
export class CollectionTypePipe implements PipeTransform {

  transform(type: AccountTransactionType): string {
    switch (type) {
      case AccountTransactionType.Debt:
        return 'Ödeme';
      case AccountTransactionType.Receivable:
        return 'Tahsilat';
      default:
        break;
    }
  }

}
