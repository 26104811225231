export enum Currency {
  Tl = 0,
  Usd = 1,
  Euro = 2,
  Gold = 3,
  Silver = 4,
  Accessories = 5,
  GoldIgnot = 6,
  GoldNew = 7,
  OtherAccessories = 98,
  Other = 99,
}

// for account transactions in all modules
export const AllCurrencyList: Currency[] = [
  Currency.Tl,
  Currency.Usd,
  Currency.Euro,
  Currency.Gold,
  Currency.Accessories,
  Currency.Silver,
  Currency.GoldIgnot,
  Currency.GoldNew,
  Currency.OtherAccessories,
  Currency.Other
];

// for exchange create in exchange module
export const ExchangeCurrencyList: Currency[] = [
  Currency.Tl,
  Currency.Usd,
  Currency.Euro,
  Currency.Gold,
  Currency.Silver,
  Currency.Accessories,
  Currency.OtherAccessories,
  Currency.Other,
];
// for branch transfer, customer transfer, deposit - withdraw to branch in transfer module
export const TransferCurrencyList: Currency[] = [
  Currency.Tl,
  Currency.Usd,
  Currency.Euro,
  Currency.Gold,
  Currency.Accessories,
  Currency.Silver,
  Currency.OtherAccessories,
  Currency.Other,
];

// for product create, product update in retail module
export const ProductCurrencyList: Currency[] = [
  Currency.Tl,
  Currency.Usd,
  Currency.Euro,
  Currency.GoldNew,
  Currency.Silver,
];


// for selling in retail module
export const SellingCurrencyList: Currency[] = [
  Currency.Tl,
  Currency.Usd,
  Currency.Euro,
  Currency.GoldNew,
];

// for product create, product update in retail module
export const ProductComponentCurrencyList: Currency[] = [
  Currency.Tl,
  Currency.Usd,
  Currency.Euro,
  Currency.GoldNew,
];

// for raw material create, raw material update  in manufacture module
export const RawMaterialCurrencyList: Currency[] = [
  Currency.Tl,
  Currency.Usd,
  Currency.Euro,
  Currency.GoldNew,
  Currency.Silver,
];

// for preproduct create, preproduct update in manufacture module
export const PreProductTransactionCurrencyList: Currency[] = [
  Currency.Tl,
  Currency.Usd,
  Currency.Euro,
  Currency.GoldNew,
];

export const CurrencyListForCasting: string[] = [
  "Tl",
  "Usd",
  "Euro",
  "Gold",
  "Accessories",
  "Silver",
  "GoldIgnot",
  "GoldNew",
  "OtherAccessories",
  "Other",
];
