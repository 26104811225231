<header id="page-topbar" *ngIf="user">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a routerLink="/dashboard" class="logo logo-sm">{{subSystemName}}</a>
        <a routerLink="/dashboard" class="logo logo-lg">{{subSystemName}}</a>
      </div>

      <button
        type="button"
        class="btn btn-sm px-3 font-size-16 header-item"
        id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)"
      >
        <i class="fa fa-fw fa-bars"></i>
      </button>

      <!-- App Search-->
      <form
        class="app-search d-none d-xl-flex gap-2"
        style="flex-flow: row; align-items: center"
      >
        <div class="position-relative">
          <input
            id="typeahead-focus"
            type="text"
            class="form-control"
            [(ngModel)]="model"
            name="model"
            [ngbTypeahead]="search"
            placeholder="Müşteriler..."
            [resultFormatter]="resultFormatBandListValue"
            [inputFormatter]="inputFormatBandListValue"
            (selectItem)="selectedCustomer($event)"
          />
          <span class="bx bx-search-alt"></span>
          <a class="createCustomer" (click)="openCustomerModal()"
            ><i class="fa fa-plus"></i
          ></a>
        </div>
      </form>
    </div>

    <div class="d-flex">
      <div class="dropdown d-inline-block d-lg-none ms-2" ngbDropdown>
        <button
          id="page-header-search-dropdown"
          type="button"
          class="btn header-item noti-icon"
          data-toggle="dropdown"
          aria-haspopup="true"
          ngbDropdownToggle
          aria-expanded="false"
        >
          <i class="mdi mdi-magnify"></i>
        </button>
        <div
          class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
          ngbDropdownMenu
          aria-labelledby="page-header-search-dropdown"
        >
          <form class="p-3">
            <div class="m-0">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="model"
                  name="model"
                  [ngbTypeahead]="search"
                  placeholder="Müşteriler..."
                  [resultFormatter]="resultFormatBandListValue"
                  [inputFormatter]="inputFormatBandListValue"
                  (selectItem)="selectedCustomer($event)"
                />
                <div class="input-group-append">
                  <button class="btn btn-secondary"> <i class="mdi mdi-magnify"></i> </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <!-- Branches-->

      <div class="dropdown d-inline-block" ngbDropdown *ngIf="currentBranch">
        <button
          type="button"
          class="btn header-item"
          ngbDropdownToggle
          id="page-header-branch-dropdown"
        >
          <span class="d-md-inline-block ms-1"
            >Şube: {{ currentBranch.name }}</span
          >
          <i class="mdi mdi-chevron-down d-xl-inline-block"></i>
        </button>
        <ng-container *ngIf="user.branchList.length > 0">
          <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
            <a
              href="/dashboard"
              class="dropdown-item"
              (click)="changeBranch(branch)"
              *ngFor="let branch of user.branchList"
              >{{ branch.name }}</a
            >
          </div>
        </ng-container>
        <ng-container *ngIf="user.branchList.length == 0">
          <a class="dropdown-item" *ngFor="let branch of user.branchList">{{
            branch.name
          }}</a>
        </ng-container>
      </div>

      <!-- Notifications -->

      <div class="dropdown d-inline-block" ngbDropdown>
        <button
          type="button"
          class="btn header-item"
          ngbDropdownToggle
          id="page-header-user-dropdown"
        >
          <img
            class="rounded-circle header-profile-user"
            src="assets/images/user.png"
            alt="Header Avatar"
          />
          <span class="d-none d-xl-inline-block ms-1">
            {{user.firstName | titlecase}}
            <span *ngIf="user.lastName">{{user.lastName | titlecase}}</span>
          </span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <a class="dropdown-item" routerLink="/user/profile/{{user.id}}"
            ><i class="bx bx-user font-size-16 align-middle me-1"></i> Profil
          </a>
          <a class="dropdown-item" href="javascript: void(0);"
            ><i class="bx bx-lock-open font-size-16 align-middle me-1"></i>
            Kilit Ekranı
          </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" (click)="logout()"
            ><i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"
            ></i>
            Çıkış Yap
          </a>
        </div>
      </div>
    </div>
  </div>
</header>
