import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HasMenuGuard } from 'src/app/core/guards/has-menu.guard';
import { DashboardComponent } from './dashboards/dashboard/dashboard.component';

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch:'full'},
  { path: 'dashboard', component: DashboardComponent },
  { path: 'dashboards', loadChildren: () => import('./dashboards/dashboards.module').then(m => m.DashboardsModule)},
  { path: 'case', loadChildren: () => import('./case/case.module').then(m => m.CaseModule)},
  { path: 'operations', loadChildren: () => import ('./operations/operations.module').then(m=>m.OperationsModule)},
  { path: 'exchange', loadChildren: () => import('./exchange/exchange.module').then(m => m.ExchangeModule)},
  { path: 'retail', loadChildren: () => import('./retail/retail.module').then(m => m.RetailModule), },
  { path: 'manufacture', loadChildren: () => import('./manufacture/manufacture.module').then(m => m.ManufactureModule)},
  { path: 'monitoring', loadChildren: () => import('./monitoring/monitoring.module').then(m => m.MonitoringModule)},
  { path: 'deleted-data', loadChildren: () => import('./deleted-data/deleted-data.module').then(m => m.DeletedDataModule)},
  { path: 'data-transfer', loadChildren: () => import('./data-transfer/data-transfer.module').then(m => m.DataTransferModule)},
  { path: 'specifications', loadChildren: () => import('./specifications/specifications.module').then(m => m.SpecificationsModule)},

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
