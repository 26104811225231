import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';

import { ScrollspyDirective } from './scrollspy.directive'
import LandingComponent from './landing.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxCaptchaModule } from 'ngx-captcha';
import { RouterModule } from '@angular/router';
import { NgbAccordionModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';

@NgModule({
    declarations: [ScrollspyDirective, LandingComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        NgxCaptchaModule,
        RouterModule.forChild([{path:"", component:LandingComponent} ]),
        ScrollToModule,
        NgbNavModule,
        NgbAccordionModule,
        CarouselModule
    ],
    exports: [ScrollspyDirective, LandingComponent]
})
export class LandingModule { }
